import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NotificationService } from '../shared/notification.service';
import { fadeIn } from '../shared/animations';
import { ResetUser } from '../models/resetUser';
import { ThemeService } from '../theme.service';
import { BreakpointObserver,Breakpoints, BreakpointState } from '@angular/cdk/layout';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  animations: [ fadeIn ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required)
  });
  forgotPasswordForm: FormGroup = new FormGroup({
    username: new FormControl("", [Validators.required,Validators.email])
  })

  passwordVisible = false;
  isLoading = false;
  forgotPasswordModalVisible = false;
  SelectUserResetModalVisible = false;
  ContactusModalVisible = false;
  returnUrl = "/search";
  duplicateUsers : ResetUser[] = [];
  message: string ="";
  email:string = ";"


  constructor(
    private authService: AuthService,
    private notification: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private themeService: ThemeService, 
    public responsive: BreakpointObserver)
  { }

  ngOnInit(): void
  {
    this.responsive.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      // if (state.matches) {
      //   console.log(state.breakpoints)
      //   console.log(state.matches)
      //   console.log('This is the Handset Portrait point at max-width: 699.98 px and portrait orientation.');
      // }
    });
    
    sessionStorage.clear();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/search'

  }

  public onLoginClick() {
    if (this.loginForm.invalid) {
      Object.values(this.loginForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    
    // this.authService.checkApiConnections().subscribe({
    //   next: e => {
    //     this.login();
    //   },
    //   error: _ => {}
    // })
    this.login();
  }

  login() {
    

    this.isLoading = true;
    (this.authService.raceLogin(this.loginForm.value.username, this.loginForm.value.password)).subscribe(
          _ =>
          {
            //this.searchService.refreshSearchData(); //old implementation
            // this.searchService.refreshCachedSearchTerms(SearchType.All); //new implementation //being moved to press F5
            this.isLoading = false;
            this.themeService.updateTheme(); //this is called whenever we need to check to change the theme
            this.router.navigateByUrl(this.returnUrl);
          },
          int_error =>
          {
            this.notification.handleError(int_error);
            this.isLoading = false;
          })
  }

  isSendingPasswordEmail = false;
  onForgotPasswordOkClick() {
   Object.values(this.forgotPasswordForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    this.isSendingPasswordEmail= true;
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.username).subscribe(
        res => {
          this.email = this.forgotPasswordForm.value.username;
          this.message = res.message;
          this.duplicateUsers = res.Users;
          this.forgotPasswordModalVisible=false;
          this.isSendingPasswordEmail= false;
          if(res.message.includes("Duplicates Found"))
          {
            this.SelectUserResetModalVisible = true;
          }
          this.notification.showSuccess(res.message);

        },
        error => {
          this.notification.handleError(error);
          this.isSendingPasswordEmail= false;
        }
      )
    }

  }

  onUserSelected(email:string, userId : number)
  {
    this.authService.forgotPassword(email,userId).subscribe(
      res => {
        this.notification.showSuccess(res.message);
          this.SelectUserResetModalVisible = false;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }


}
