import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { SocketMessage, SocketMessageType } from '../models/websockets';
import { NotificationService } from './notification.service';
import { MessagingService } from '../messages/messaging.service';
import { WhatsappService } from '../whatsapp/whatsapp.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {

  public salesCount = 0;
  public leadsCount = 0;
  public purchasesCount = 0;

  public messages: Subject<SocketMessage>;

  constructor(private notification: NotificationService, private messageService: MessagingService, private waService: WhatsappService) { }

  public connect(url): Subject<SocketMessage> {
    this.messages = webSocket(url);
    console.log("[WS] Connected to ", url); //Leave this log statement to see if we connect to WS Hub, so we know if we have connection or not
    return this.messages;
  }

  //TO SEND A MESSAGE, ANYWHERE IN CODE:
  // let retmsg = new SocketMessage();
  // retmsg.type = 1;
  // retmsg.value = '3';
  // retmsg.message = "Hello World"
  // this.websockets.SendMessage(retmsg);
  public SendMessage(msg: SocketMessage) {
    this.messages.next(msg);
  }

  public HandleMessage(msg: SocketMessage) {
    if (msg.type == SocketMessageType.BlockingPopup) {
      this.notification.ShowBlockingPopup(msg.message);
    }
    else if (msg.type == SocketMessageType.PopUp) {
      this.notification.ShowNonBlockingMessage(msg.message);
    }
    else if (msg.type == SocketMessageType.SilentNotification || msg.type == SocketMessageType.SilentMessage)
    {
      // this.messageService.getUreadMessages(15).subscribe(res => {
      // })
      this.messageService.addMessage(msg);
    }
    else if (msg.type == SocketMessageType.SilentRefreshInbox) {
      this.messageService.getUnreadMessages(15).subscribe(
        _ => {}
      )
    }
    else if (msg.type == SocketMessageType.ReadInboxMessage) {
      this.messageService.markGroupIdAsRead(msg.value);
    }
    else if (msg.type == SocketMessageType.ForceLogout) {
      //from app.component - move to shared location
      localStorage.removeItem("clientData");
      localStorage.removeItem("access_token");
      sessionStorage.clear();
      location.reload();
    }
    else if (msg.type == SocketMessageType.LeadsCount) {
      this.leadsCount = +msg.value;
    }
    else if (msg.type == SocketMessageType.SalesCount) {
      this.salesCount = +msg.value;
    }
    else if (msg.type == SocketMessageType.SilentRefreshWhatsApp) {
      this.waService.RefreshAll()
    }
    else if (msg.type == SocketMessageType.LoadPopUpMessage) {      
      if (!isNaN(Number(msg.value))) {
        this.messageService.getMessageById(Number(msg.value)).subscribe({
          next: val => {
            this.notification.ShowBlockingPopup(val.messageLong, val.message, '90%');
            this.messageService.markMessageAsRead(val).subscribe({
              next: _ => {},
              error: err => this.notification.handleErrorSilent(err)
            })
          },
          error: err => {this.notification.handleErrorSilent(err)}
        })
      }
      
    }
    else if (msg.type == SocketMessageType.UpdateLoadBalancing) {
      if (msg.message === "api_url_main") {
        environment.api_url_main = msg.value;
      }
      else if (msg.message === "api_url_alt") {
        environment.api_url_alt = msg.value;
      }
      else if (msg.message === "api_alt_weight") {
        environment.api_alt_weight = +msg.value;
      }
      else if (msg.message === "api_endpoints_setup") {
        
        environment.apiEndpoints = JSON.parse(msg.value);
        
        
      }
      
      
    }
  }
}
