import { Component, ViewChild, OnInit, NgZone } from '@angular/core';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { AdminMappingService } from 'src/app/admin-stock-mappings/admin-mappings.service';
import { UnMappedStock, Stock, CustomStock, StockType, NewCustomStockMapping, TyreStockToBeMapped, CustomStockToBeMapped } from 'src/app/models/admin-mappings';
import { filteredStockItem } from 'src/app/models/stock';
import { NotificationService } from 'src/app/shared/notification.service';
import {AdminStockMappingsComponent} from 'src/app/admin-stock-mappings/admin-stock-mappings.component';

@Component({
  selector: 'app-modal-mapping',
  templateUrl: './modal-mapping.component.html',
  styleUrls: ['./modal-mapping.component.less']
})
export class ModalMappingComponent implements OnInit {
  @ViewChild('customStockSelect') customStockSelect: NzSelectComponent;

  //stockItem: UnMappedStock;
  selectedMap: Stock | null = null;
  selectedCustomMap: CustomStock | null;

  isMapping = false;
  isLoadingActiveStock = false;

  activeStock: Stock[] = [];
  activeStockTemp: Stock[] = [];
  activeStockVeryTemp: Stock[] = [];
  filterTerm: string = '';

  activeCustomStock: CustomStock[] = [];
  activeCustomStockTemp: CustomStock[] = [];
  filteredStock: filteredStockItem[] = [];
  unMappedStock: UnMappedStock[] = [];

  currentClientName: string = "";

  isCustomMappingTab = false;
  isAddCustomStockOpen: boolean = false;

  customStockTypes: StockType[] =[]

  customStockCode: string = '';
  customStockType: StockType = { id: 0, name: 'null', msfId: 0 };
  customDescription: string = '';

  constructor(
    public adminMappingService: AdminMappingService,
    private notification: NotificationService,
    private ngZone: NgZone,
    public adminStockMappingsComponent: AdminStockMappingsComponent
  ) { }

  ngOnInit(): void {
    this.currentClientName = this.adminMappingService.currentClientName;
    this.isLoadingActiveStock = true;
    this.adminMappingService.getActiveStock().subscribe( (response : Stock[]) => {
      this.isLoadingActiveStock = false;
      this.activeStockTemp = response;
      this.activeStock = this.activeStockTemp;
      this.filterActiveStockAdvanced();
    }, (error) => {
      this.isLoadingActiveStock = false;
      this.notification.ShowAlert(error);
    });
    this.adminMappingService.getCustomStockTypes().subscribe(
      (stockTypes: StockType[]) => {
        this.customStockTypes = stockTypes;
      },
      (error) => {
        this.notification.showError('Error fetching custom stock types', error);
      }
    );
  }


  handleClose(): void {
    this.adminMappingService.currentVisibility = false;
  }


  setSelectedMap(stock: Stock) {
    this.ngZone.run(() => {
      this.selectedMap = stock;
    });
  }


  mapTyreStock(unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number, close: boolean, next: boolean) {
    this.isMapping = true;
    let tyreStockItem = new TyreStockToBeMapped();

    tyreStockItem.clientId = unmappedStockItem.clientId;
    tyreStockItem.description = unmappedStockItem.description;
    tyreStockItem.msfid = msfIdOfExistingStock;
    tyreStockItem.stockCode = unmappedStockItem.stockCode;

    this.adminMappingService.mapTyreStock(tyreStockItem).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      this.isMapping = false;
      this.selectedMap = null;
      if (close) {
        this.adminMappingService.currentVisibility = false;
      }

      if (next) {
        const currentIndex = this.adminStockMappingsComponent.unMappedStock.findIndex(item => item === unmappedStockItem);
        if (currentIndex !== -1 && currentIndex < this.adminStockMappingsComponent.unMappedStock.length - 1) {
          const nextItem = this.adminStockMappingsComponent.unMappedStock.filter(val => val.mapped === 6 || val.mapped === 0)[currentIndex+1];
          this.adminMappingService.unmappedStockItem = nextItem;       
        }
      }
      this.adminStockMappingsComponent.refreshNotMappedStockTable(unmappedStockItem, unmappedStockItem.clientId);      
      }, error => {
          this.notification.handleError(error);
          this.isMapping = false;

        })
  }

  mapCustomStock(unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number, close: boolean, next: boolean) {
    this.isMapping = true;
    const customStockItem: CustomStockToBeMapped = {
      clientId: unmappedStockItem.clientId,
      description: unmappedStockItem.description,
      msfid: msfIdOfExistingStock,
      stockCode: unmappedStockItem.stockCode,
      stockTypeId: stockTypeId
    };
    
    this.adminMappingService.mapCustomStock(customStockItem).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      this.isMapping = false;
      this.selectedCustomMap = null;

      if (close) {
        this.adminMappingService.currentVisibility = false;
      }
      if (next) {
        const currentIndex = this.adminStockMappingsComponent.unMappedStock.findIndex(item => item === unmappedStockItem);
        if (currentIndex !== -1 && currentIndex < this.adminStockMappingsComponent.unMappedStock.length - 1) {
          const nextItem = this.adminStockMappingsComponent.unMappedStock.filter(val => val.mapped === 6 || val.mapped === 0)[currentIndex+1];
          this.adminMappingService.unmappedStockItem = nextItem;
          
        }
      }
      this.adminStockMappingsComponent.refreshNotMappedStockTable(unmappedStockItem, unmappedStockItem.clientId);
    },
      error => {
        this.notification.handleError(error);
        this.isMapping = false;
      })
  }

  addCustomStockMapping(stockCode: string, description: string, stockType: StockType, unmappedStock : UnMappedStock) {
    this.isMapping = true;
    let nmsfId = stockType.msfId;
    let stockTypeId = stockType.id;
    let newCustomStockMapping = new NewCustomStockMapping();

    newCustomStockMapping.clientId = this.adminMappingService.currentClientId;
    newCustomStockMapping.stockCode = stockCode;
    newCustomStockMapping.description = description;
    newCustomStockMapping.nmsfId = nmsfId;
    newCustomStockMapping.mappingTypeId = 1;
    newCustomStockMapping.userId = 997;
    newCustomStockMapping.officialStock = '';
    newCustomStockMapping.stockTypeId = stockTypeId;

    this.adminMappingService.createCustomStockMapping(newCustomStockMapping).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      this.mapCustomStock(unmappedStock, nmsfId, stockTypeId, 1, true, false);
      this.isMapping = false;
      this.isAddCustomStockOpen = false;
      this.selectedCustomMap= null;
      this.clearForm();

    },
      error => {
        this.notification.handleError(error);
        this.isMapping = false;
      })
  }

  clearForm(): void {
    this.customStockCode = '';
    this.customStockType = { id: 0, name: 'null', msfId: 0 };
    this.customDescription = '';
  }

  searchedTerm: string = "";

  searchActiveStock(value: string) {
    if (!value) {
      this.searchedTerm = "";
      return;
    }
    this.searchedTerm = value;
    if (value && value.length > 2) {

      this.filteredStock = this.activeStock.filter(e =>
        (e.stockCode && e.stockCode.toLowerCase().includes(value.toLowerCase())) ||
        (e.description && e.description.toLowerCase().includes(value.toLowerCase())) ||
        (e.brand && e.brand.toLowerCase().includes(value.toLowerCase())) ||
        (e.genCode && e.genCode.toLowerCase().includes(value.toLowerCase())) ||
        (e.msfid && (e.msfid == Number(value) || (typeof e.msfid === 'string' && e.msfid === value))) ||
        (e.genCode + ' ' + e.brand + ' ' + e.description + ' (' + e.stockCode + ')').toLowerCase().includes(value.toLowerCase())).map(e => {
          return { key: + e.msfid, name: e.genCode + ' ' + e.brand + ' ' + e.description + ' (' + e.stockCode + ')' }
        });
    }

  }

  searchActiveCustomStock(value: string) {
    if (!value) {
      this.searchedTerm = "";
      return;
    }
    this.searchedTerm = value;
    if (value && value.length > 2) {

      this.filteredStock = this.activeCustomStock.filter(e =>
        (e.stockCode && e.stockCode.toLowerCase().includes(value.toLowerCase())) ||
        (e.description && e.description.toLowerCase().includes(value.toLowerCase())) ||
        (e.msfid && (e.msfid == Number(value) || (typeof e.msfid === 'string' && e.msfid === value))) ||
        (e.description + ' (' + e.stockCode + ')').toLowerCase().includes(value.toLowerCase())).map(e => {
          return { key: + e.msfid, name: e.description + ' (' + e.stockCode + ')' }
        });
    }
  }
  setTab(tab: string) {
    if (tab === 'tyre') {
      this.isCustomMappingTab = false;
    } else if (tab === 'custom') {
      this.isCustomMappingTab = true;
    }
  }

  filterActiveStockAdvanced(){
    if (this.filterTerm === "") {
      this.activeStock = this.activeStockTemp;
  }else{
      this.activeStockVeryTemp = this.activeStockTemp;
      this.activeStockVeryTemp = this.activeStockVeryTemp.filter(stock => stock.description.toLowerCase().includes(this.filterTerm.toLowerCase()));
      this.activeStock = this.activeStockVeryTemp;
  }
  }
  
}

