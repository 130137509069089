<div class="container-fluid">
    <div class="row ">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-4 text-center">
            <h2><b>Price Matrix</b></h2>
        </div>
        <div class="col-lg-4">
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 text-center" style="padding-left: 5%;padding-right: 4%;">
            <p><b>{{ this.priceObj.pmtext }}</b></p>
            <div nz-row>
                <div nz-col nzSpan="8">
                    <nz-select [(ngModel)]="this.priceObj.pm_select" (ngModelChange)="changemx()" style="width: 50%">
                        <nz-option *ngFor="let item of matrixObject" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
                    </nz-select>
                </div>
                <div nz-col nzSpan="8">
                    <nz-select [(ngModel)]="this.priceObj.pm_applied_id" style="width: 80%">
                        <nz-option *ngFor="let item of matrixOptions" [nzLabel]="item.text" [nzValue]="item.id"></nz-option>
                    </nz-select>
                </div>
                <div nz-col nzSpan="8">
                    <button nz-button nzType="primary" type="submit" (click)="updatematrix()">Update</button>
                </div>
            </div>
            <p></p>
            <nz-table #Matrix [nzData]="this.priceMatrix" [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th></th>
                        <th>Brand</th>
                        <th>Method</th>
                        <th>Percentage
                            <button nz-button nzType="primary" style="margin-left: 10px;" nz-popover nzPopoverTitle="Set All %" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right" [nzPopoverTrigger]="null" (click)="popOverVisibility=true" [nzPopoverVisible]="popOverVisibility">Set All</button>
                                <ng-template #contentTemplate>
                                    <div>
                                        <nz-input-group nzAddOnBeforeIcon="percentage">
                                            <input nz-input [(ngModel)]="setAllValue" type="number" placeholder="100" style="width: 100px;">
                                          </nz-input-group>
                                          <div nz-row [nzJustify]="'end'">
                                            <button nz-button nzType="primary" style="margin-top: 10px;"  (click)="setAllPercentages(); popOverVisibility= false">Set</button>
                                        </div>
                                    </div>
                                </ng-template>

                            <app-video-widget [videoId]="6" style="float: inline-end;"></app-video-widget>
                        </th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr *ngFor="let item of Matrix.data ; let i = index">
                        <td><img class="brandImage" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.image}}'></td>
                        <td class="brand">{{ item.name }}</td>
                        <td>
                            <nz-select [ngModel]="selectedValue[i]" (ngModelChange)="updateobj(item,$event,1)" style="width: 50%">
                                <nz-option nzLabel="Fixed GP Percent" nzValue="FGPP"></nz-option>
                                <nz-option nzLabel="Retail Less" nzValue="RL"></nz-option>
                                <nz-option nzLabel="Dont Sell" nzValue="D"></nz-option>
                            </nz-select>
                        </td>
                        <td>
                            <div class="input-group maindiv">
                                <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline" ></span>
                            </div>
                            </span> -->
                                <!-- <nz-input-group [nzSuffix]="suffixTemplateInfo" [nzPrefix]="prefixTemplateUser"> -->
                                <nz-input-group nzAddOnAfterIcon="percentage">
                                    <input nz-input value="{{ item.value}}" (change)="updateobj(item,$event,2)" type="number" placeholder="100">
                                </nz-input-group>
                            </div>
                        </td>
                        <!-- <ng-template #prefixTemplateUser><span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline" ></span></ng-template> -->
                        <!-- <ng-template #suffixTemplateInfo></ng-template> -->
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>