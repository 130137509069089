import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PackageType, customStockItem, stockType } from 'src/app/models/pos';
import { PosService } from 'src/app/pos/pos.service';
import { CustomStock, CustomStockPackage } from '../../models/pricelist';
import { PricelistsService } from '../../pricelists/pricelists.service';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-customstock',
  templateUrl: './customstock.component.html',
  styleUrls: ['./customstock.component.less']
})
export class CustomstockComponent implements OnInit, AfterViewInit, OnDestroy {

  customStock: CustomStock[]=[];
  stockTypes: stockType[]=[];
  addType: stockType = new stockType();
  editcustom: CustomStock = new CustomStock();
  selectedTab = 0
  customStockPackages: CustomStockPackage[]=[];
  packageType: PackageType[]=[];
  editCustomPackage: PackageType = new PackageType();

  customStockFilter: string = ''

  isLoading = false;
  isLoadingStock = false;
  isLoadingPackages = false;
  isModalVisible = false;
  isAddCustomStockModalVisible = false;
  isPackages = false;
  title = "";

  reactivate = false;
  successCheck = false;
  isActive = false;
  isDisabled = true;

  spanInput = 12;
  spanButton = 8;

  spanStock = 24;
  spanPackages = 0;
  offsetPackages = 0;
  isCreatePackageModal = false;
  isEditPackageModal = false;

  private timer: any;

  editId: number | null = null;
  currentPackage: PackageType = new PackageType();
  editCache: { [key: string]: { edit: boolean; data: PackageType } } = {};

  addCustomItemForm: FormGroup;

  parserNumber = (value: string): string => value.replace("", '1');

  @ViewChild('customStockTable', { static: false }) nzTableComponent?: NzTableComponent<CustomStock>;
  private destroy$ = new Subject<boolean>();

  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  constructor(private pricelistsService: PricelistsService, private notification: NotificationService, private posService:PosService, private fb: FormBuilder) {
    this.addCustomItemForm = this.fb.group({
      description: [{value: '', disabled: true}, [Validators.required]],
      stockcode: ['', [Validators.minLength(2), Validators.required], [this.stockCodeAsyncValidator]],
      price: [{value: '', disabled: true}, [Validators.minLength(2),Validators.required]],
    });
   }
   trackByIndex(_: number, data: CustomStock): number {
    return data.stockId;
  }

  ngOnInit(): void {
    this.loadCustomStock();  
  }

  ngAfterViewInit(): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrolledIndexChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number) => {
        // console.log('scroll index to', data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // Used to exclude special characters from inputs on packages
  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  private loadCustomStock() {
    this.isLoading = true;
    this.isLoadingStock = true;

    this.pricelistsService.getCustomStock().subscribe(
      res => 
      {   
        this.customStock=[];
        this.stockTypes=[];

        this.customStock = res.customStock
        .sort(function(a, b) {
          var keyA = a.description.toLowerCase(),
              keyB = b.description.toLowerCase();
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        // this.sortCustomStock();

        this.stockTypes  = res.stockTypes;
        this.stockTypes.forEach(type => {
          type.showTab = true;
        });
        this.loadTab(this.selectedTab);

        this.isLoadingStock = false;

        this.customStockPackages=[];
        this.packageType=[];
        
        this.customStockPackages = res.customStockPackage;
        this.customStockPackages = this.customStockPackages.filter(val => val.customStockGroupItemId != null);

        this.packageType  = res.packageTypes;

        this.packageType.forEach(type => {
          type.showPackageTab = true;
        });

        this.loadPackageTab(this.selectedTab); 
        this.isLoadingPackages = false;

        this.updateEditCache(); 
        // this.loadCustomStockPackages();
        // this.isLoadingStock = false;
        // if(!this.isLoadingPackages)
          // this.isLoading = false;
      },
      err => {
        this.isLoadingStock = false;
        this.isLoadingPackages = false;
        // if(!this.isLoadingPackages)
          // this.isLoading = false;
        this.notification.handleError(err);
      });
  }

  // private loadCustomStockPackages() {
  //   this.isLoading = true;
  //   this.isLoadingPackages = true;
  //   this.pricelistsService.getCustomStockPackages().subscribe(
  //     res => 
  //     {   
  //       this.customStockPackages=[];
  //       this.packageType=[];
        
  //       this.customStockPackages = res.customStockPackage;
  //       this.customStockPackages = this.customStockPackages.filter(val => val.customStockGroupItemId != null);

  //       this.packageType  = res.packageTypes;

  //       this.packageType.forEach(type => {
  //         type.showPackageTab = true;
  //       });

  //       this.loadPackageTab(this.selectedTab); 

  //       this.updateEditCache();

  //       this.isLoadingPackages = false;
  //       if(!this.isLoadingStock)
  //         this.isLoading = false;
  //     },
  //     err => {
  //       this.isLoadingPackages = false;
  //       if(!this.isLoadingStock)
  //         this.isLoading = false;
  //       this.notification.handleError(err);
  //     });
  // }

  loadTab(tab:number)
  {
    this.selectedTab = tab;
    let stockType = this.stockTypes[tab].stockDescription;
    for(var i = 0; i < this.customStock.length; i++)
    {
      if(this.customStock[i].type == stockType)
      {
        this.customStock[i].showTable = true;
      }
      else
      {
        this.customStock[i].showTable = false;
      }
    }
  }

  loadPackageTab(tab:number)
  {
    this.selectedTab = tab;
    let packageType = this.packageType[tab].packageAcronym;
    this.currentPackage = this.packageType[tab];

    for(var i = 0; i < this.customStockPackages.length; i++)
    {
      if(this.customStockPackages[i].customStockGroupAcronym === packageType)
      {
        this.customStockPackages[i].showPackageTable = true;
      }
      else
      {
        this.customStockPackages[i].showPackageTable = false;
      }
    }
  }

  updatecustom()  {
    // Update the packages with the custom stock
    this.customStockPackages.filter((val) =>  {
      if(val.customStockId === Number(this.editcustom.stockId)){
        val.price = this.editcustom.price;
        val.description = this.editcustom.description;
        val.stockCode = this.editcustom.code;
        
      }
    });
    
    this.pricelistsService.updatecustom(this.editcustom).subscribe(res => 
      { 
        this.notification.showSuccessMinimal("Updated Successfully");
        this.isModalVisible = false;
      },
      error => 
      {
        this.notification.handleError(error);       
      });
  }

  loadstockinfo(item: CustomStock)  {
    this.isModalVisible = true;
    this.editcustom = item;
  }

  openAdd(type:string)
  {
    this.isAddCustomStockModalVisible = true;
    this.title = "Add new " + type +" item";
    this.addType = this.stockTypes.find(x=> x.stockDescription == type)!;
  }

  isAddingBuyOut: boolean;
  addCustomStockItem() 
  {
    if(!this.addCustomItemForm.valid)
    {
      Object.values(this.addCustomItemForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    else
    {
      this.isActive = false;
  
      let custom = new customStockItem();
      custom.description = this.addCustomItemForm.value.description ;
      custom.code = this.addCustomItemForm.value.stockcode.toUpperCase();
      custom.retail_price = this.addCustomItemForm.value.price;
      custom.type = this.addType.stockDescription.toUpperCase();
      custom.stockTypeId = this.addType.stockTypeId
      custom.isBuyOut = this.isAddingBuyOut;
      
      this.isLoading = true;

      this.posService.addCustomStock(custom).subscribe(
        val => {
          if(val.customStock){
          let customStock = new CustomStock();

          customStock = val.customStock;
          customStock.showTable = true;
          customStock.isSearchable = false;

          this.isAddCustomStockModalVisible = false;
            

          this.customStock.push(customStock);
          this.sortCustomStock();

          this.customStock = [...this.customStock];

          this.isLoading = false;
          this.addCustomItemForm.reset();
          }
          
          this.notification.showSuccess(val.Message);  
        },
        error => {
          this.isAddCustomStockModalVisible = false;
          this.isLoading = false;
          this.notification.handleError(error);
        }
      )   
    }
  }

  removeItem(stockItem:CustomStock)
  {
    this.customStock = this.customStock.filter(val => val !== stockItem);
    this.customStockPackages = this.customStockPackages.filter(val => val.customStockId != stockItem.stockId);
    this.pricelistsService.removeCustomStock(stockItem).subscribe(
      val => {
        this.isLoading = false;
        this.notification.showSuccessMinimal("Item succesfully removed.");    
      },
      error => {
        this.notification.handleError(error);
      }
    );
  }

  openPackages(){
    this.isPackages = !this.isPackages;
    if(!this.isPackages){
      this.spanStock = 24;
      this.spanPackages = 0;
      this.offsetPackages = 0;
    }
    else if(this.isPackages){
      this.spanStock = 12;
      this.spanPackages = 12;
      // this.offsetPackages = 1;
    }
  }

  addPackageItem(item: CustomStock): void {
    let itemInsert = new CustomStockPackage();
    itemInsert.clientId = item.clientId;
    itemInsert.customStockGroupId = this.currentPackage.packageTypeId;
    itemInsert.customStockGroupName = this.currentPackage.packageDescription;
    itemInsert.customStockGroupAcronym = this.currentPackage.packageAcronym;
    itemInsert.customStockId = item.stockId;
    itemInsert.description = item.description;
    itemInsert.stockCode = item.code;
    itemInsert.price = item.price;
    itemInsert.fixedQty = null;
    

    this.pricelistsService.setCustomStockPackageItem(itemInsert).subscribe(res => 
      {   
        itemInsert = res as CustomStockPackage;
        itemInsert.showPackageTable = true;
        itemInsert.fixedQty = null;
        this.customStockPackages.push(itemInsert);
        this.customStockPackages = [...this.customStockPackages];
        this.notification.showSuccessMinimal("Created Successfully");
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      });
  }

  deletePackageItem(id: CustomStockPackage): void {
    this.customStockPackages = this.customStockPackages.filter(d => d !== id);

    this.pricelistsService.removeCustomStockPackageItem(id.customStockGroupItemId).subscribe(() => 
      {   
        this.notification.showSuccessMinimal("Deleted Successfully");
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      });
  }

  createPackage() {
    if(this.packageType.some(val => {return (val.packageDescription === this.editCustomPackage.packageDescription && val.packageAcronym === this.editCustomPackage.packageAcronym)}))
      this.notification.showWarning("Package already exists");

    else{

      this.isLoading = true;
      this.pricelistsService.setCustomStockPackageGroup(this.editCustomPackage).subscribe(res => 
        {   
          this.editCustomPackage = res as PackageType;
          this.editCustomPackage.showPackageTab = true;

          this.packageType.push(this.editCustomPackage);
          this.packageType = [...this.packageType];

          // this.loadCustomStockPackages();
          this.updateEditCache();
          this.isCreatePackageModal = false;
          this.editCustomPackage = new PackageType();

          this.notification.showSuccessMinimal("Created Successfully");
          this.isLoading = false;
        },
        err => {
          this.notification.handleError(err);
          this.isLoading = false;
        });

      this.isCreatePackageModal = false;
      this.editCustomPackage = new PackageType();
    }
  }

  deletePackage(id: PackageType): void {
    this.packageType = this.packageType.filter(d => d !== id);

    this.pricelistsService.removeCustomStockPackageGroup(id.packageTypeId).subscribe(() => 
      {   
        this.notification.showSuccessMinimal("Deleted Successfully");
        // this.loadCustomStockPackages();
      },
      err => {
        this.notification.handleError(err);
      });

  }

  startEdit(id: number): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: number): void {
    const index = this.packageType.findIndex(item => item.packageTypeId === id);
    this.editCache[id] = {
      data: { ...this.packageType[index] },
      edit: false
    };
  }

  saveEdit(id: number): void {
    const index = this.packageType.findIndex(item => item.packageTypeId === id);
    Object.assign(this.packageType[index], this.editCache[id].data);

    this.customStockPackages.forEach(val => {
      if(val.customStockGroupId == this.editCache[id].data.packageTypeId){
        val.customStockGroupName = this.editCache[id].data.packageDescription;
        val.customStockGroupAcronym = this.editCache[id].data.packageAcronym;
      }
    });

    this.pricelistsService.updateCustomStockPackageGroup(this.editCache[id].data).subscribe(res => 
      {   
        this.notification.showSuccessMinimal("Updated Successfully");
        this.editCache[id].edit = false;
        this.isLoading = false;
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      }
    );
  }

  updateEditCache(): void {
    this.packageType.forEach(item => {
      this.editCache[item.packageTypeId] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  updateTyreQty(item: CustomStock){
    this.pricelistsService.updateCustomStockTyreQty(item).subscribe(() => 
      {   

      },
      err => {
        this.notification.handleError(err);
      }
    );
  }

  updateFixedTyreQty(item: CustomStockPackage){
    // Used in case no quantity needs to be added or if the input field is cleared
    if(!item.fixedQty?.toLocaleString())
      item.fixedQty = null;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.pricelistsService.updateCustomStockPackageFixedTyreQty(item).subscribe(() => 
        {   
        },
        err => {
          this.notification.handleError(err);
        }
      );
    }, 350);
  }


  sortCustomStock(): CustomStock []{
    return this.customStock.sort((a, b) => {
      if (Number(a.stockId) < Number(b.stockId)) return -1;
      if (Number(a.stockId) > Number(b.stockId)) return 1;
      return 0;
    });
  }

  stockCodeAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
    this.reactivate = false;
    this.addCustomItemForm.get("description")?.setValue("");
    this.addCustomItemForm.get("price")?.setValue("");
    this.isDisabled = false;
    this.addCustomItemForm.controls['description'].disable();
    this.addCustomItemForm.controls['price'].disable();

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if(control.value.length > 1){
        this.addCustomItemForm.controls['description'].enable();
        this.addCustomItemForm.controls['price'].enable();
        this.isDisabled = false;
        this.pricelistsService.getCustomStockCode(this.addCustomItemForm.value.stockcode.toUpperCase()).subscribe(
          val => {          
            if (Object.keys(val).length){
              if(val.isActive){
                observer.next({ error: true, duplicated: true });
                this.addCustomItemForm.controls['description'].disable();
                this.addCustomItemForm.controls['price'].disable();
              }
              else{
                this.addCustomItemForm.get("description")?.setValue(val.description.toUpperCase());
                this.addCustomItemForm.get("price")?.setValue(val.price);
                this.addCustomItemForm.updateValueAndValidity({ onlySelf: true });

                this.reactivate = true;
                this.successCheck = true;
                observer.next(null);
              }
            }
            else {
              observer.next(null);
              this.successCheck = false;

            }
            observer.complete();
          },
          error => {     
            this.notification.handleError(error);          
          }) 
        }     
      }, 750);
    });

  reactivateCustomStock(){
    if(!this.addCustomItemForm.valid)
    {
      Object.values(this.addCustomItemForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    else
    {
      let custom = new customStockItem();
      custom.description = this.addCustomItemForm.value.description.toUpperCase();
      custom.code = this.addCustomItemForm.value.stockcode.toUpperCase();
      custom.retail_price = this.addCustomItemForm.value.price;
      custom.type = this.addType.stockDescription.toUpperCase();
      custom.stockTypeId = this.addType.stockTypeId
      this.isLoading = true;

      this.addCustomItemForm.controls['description'].disable();
      this.addCustomItemForm.controls['price'].disable();

      this.posService.reactivateCustomStock(custom).subscribe(
        val => {
          if(val.customStock){
          let customStock = new CustomStock();

          customStock = val.customStock;
          customStock.showTable = true;
          customStock.isSearchable = false;

          this.isAddCustomStockModalVisible = false;
            

          this.customStock.push(customStock);
          this.sortCustomStock();

          this.customStock = [...this.customStock];

          this.isLoading = false;
          this.reactivate = false;
  
          this.addCustomItemForm.reset();
          }
          
          this.notification.showSuccess(val.Message);  
        },
        error => {
          this.isAddCustomStockModalVisible = false;
          this.isLoading = false;
          this.notification.handleError(error);
        }
      )   
    }
  }

  closeModal(form: FormGroup){
    this.addCustomItemForm.controls['description'].disable();
    this.addCustomItemForm.controls['price'].disable();
    form.reset();
  }
  filterCustomStock(stockType: string): CustomStock[]{

    if (this.customStockFilter && this.customStockFilter.length > 2){
      let res = this.customStock.filter(e => e.code !== null || e.description !== null).filter(val => (val.description.toUpperCase().includes( this.customStockFilter.toUpperCase()) || val.code.toUpperCase().includes( this.customStockFilter.toUpperCase())))
      return res
    }
    else {
      let res = this.customStock.filter(val => val.type.toLocaleUpperCase() === stockType.toLocaleUpperCase())
      return res
    }
  }

  filterCustomStockPackages(id : number): CustomStockPackage[]{
    return this.customStockPackages.filter(val => val.customStockGroupId === id);
  }
}
