import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: readonly any[], searchText: string, searchList: string [] = [], minChar: number = 0, pageSize: number = 0): readonly any[] {
        let newPageSize: number = 30;
        if (!items) return [];
        if (!searchText  || searchText.length < minChar) {
            if (pageSize > 0) {
                newPageSize = pageSize;
                return items.slice(0, newPageSize);
            }
            return items;
        } 
        let searchArray = searchText.split(" ");

        if (pageSize > 0)
            newPageSize = pageSize;

        searchArray.forEach(val => {
            items = items.filter(item => {
                if(searchList.length === 0){
                return Object.keys(item).some(key => {
                    return String(item[key]).toUpperCase().includes(val.toUpperCase());
                });} 
                else{
                return Object.keys(item).filter(item => String(searchList).toUpperCase().includes(item.toUpperCase())).some(key => {
                    return String(item[key]).toUpperCase().includes(val.toUpperCase());
                });}
            })
        })
        return items.slice(0, newPageSize);
    }
}