import { Plupdate,PriceMatrix,PriceMatrixOptions,PriceMatrixApplied,PriceMatrixDesc ,PriceMatrixObject } from './../../models/pricelist';
import { PricelistsService } from '../../pricelists/pricelists.service';
import { NotificationService } from '../../shared/notification.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Globals } from 'src/app/shared/globals';
import { LogService } from 'src/app/shared/logs.services';
import { LogAudit, LogAuditType } from 'src/app/models/logs';


@Component({
  selector: 'app-pricingmatrix',
  templateUrl: './pricingmatrix.component.html',
  styleUrls: ['./pricingmatrix.component.less']
})
export class PricingmatrixComponent implements OnInit {

  priceMatrixOptions: PriceMatrixOptions;
  priceDescriptions: PriceMatrixDesc;
  priceApplied: PriceMatrixApplied;
  priceMatrix : PriceMatrix[];
  priceObj:PriceMatrixObject = new PriceMatrixObject;
  matrixObject: any[] = Globals.consts.matrixObject;
  matrixOptions: any[] = Globals.consts.matrixOptions;
  selectedValue: string[] = [];

  setAllValue : number = 0;
  popOverVisibility: boolean = false;

  constructor(private pricelistsService: PricelistsService, private notification: NotificationService,public authService: AuthService, private logService: LogService) { }

  ngOnInit(): void {
    this.pricelistsService.getPricelists().subscribe(
      (res:any) => 
      {   
        this.priceMatrixOptions= res.price_matrix;
        this.priceApplied= res.price_matrix_applied;
        this.priceDescriptions = res.price_matrix_descriptions;
        this.showmatrix('external');     
      });
  }

  showmatrix(selectedmatrix)
  { 

    this.priceObj.pmtext = this.priceDescriptions[selectedmatrix]
    this.priceObj.pm_applied_id = this.priceApplied[selectedmatrix]
    this.priceObj.pm_active = selectedmatrix;
    this.priceObj.pm_select = selectedmatrix;
    this.priceMatrix = this.priceMatrixOptions[selectedmatrix];
    this.priceMatrixOptions.active = selectedmatrix;

    this.priceMatrix.forEach((obj, index) => {
      this.selectedValue[index] = obj.method
    });

    //Limit to External FOR Ws
    if(this.authService.hasClaims(['WSP'],false) && !this.authService.hasClaims(['PRL'],false))
    {
      this.matrixObject = [];
      this.matrixOptions = [];
      this.matrixObject.push(Globals.consts.matrixObject[0]);
      this.matrixOptions.push(Globals.consts.matrixOptions[1]);
    }
  }

  changemx()
  {
    this.showmatrix(this.priceObj.pm_select)
  }

  updateobj(obj:any ,value: any,type : number)
  {
    var active_pm = this.priceMatrixOptions.active

    //change setting
    this.priceObj.pm_applied_id = 1

    //set settings from pm object 
    let index = this.priceMatrixOptions[active_pm].findIndex(x => x.name == obj.name);
    this.priceMatrixOptions[active_pm][index].name = obj.name;
    if(type == 1) // If method changed = 1
    {
    this.priceMatrixOptions[active_pm][index].method = value;
    this.priceMatrixOptions[active_pm][index].value= Number(obj.value);
    }
    else if(type == 2) // If percentage value changed = 2
    {
      this.priceMatrixOptions[active_pm][index].method = obj.method;
      this.priceMatrixOptions[active_pm][index].value = Number(value.target.value);
    }
  }

  updatematrix()
  {
    var rows = new Array();
    var active_pm=this.priceMatrixOptions.active;
    var pm_applied=this.priceObj.pm_applied_id;
    var pm_id =0;
    for (let i of this.priceMatrixOptions[active_pm])
      {
         var obj = {"brand": i.name,"brandId": i.sf_brand_id, "method": i.method, "value": i.value};
    	   rows.push(obj);
         pm_id=i.price_list_id;
      };  
    // console.log(this.priceMatrixOptions[active_pm])
    var SendData = new Plupdate();
    SendData.key = "ext_price",
    SendData.price_matrix = rows,
    SendData.pm_id = pm_id,
    SendData.pm_applied= pm_applied 
    this.pricelistsService.updatepricelist(SendData).subscribe(res => 
      {
          this.notification.showSuccess("Matrix Updated Successfully");
          this.ngOnInit();   
          this.logService.addAction(new LogAudit(LogAuditType.PricingMatrix, this.authService.user.user_id));
      },
      error => 
      {
        this.notification.handleError(error);       
      });
  }

  setAllPercentages() {
    this.popOverVisibility = true;
    this.priceMatrix.forEach((obj, index) => {
      this.priceMatrix[index].value = this.setAllValue;
    });
    this.popOverVisibility = false;
  };

}


