import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzTableFilterList } from 'ng-zorro-antd/table';
import { AuthService } from '../auth.service';
import { LeadsService } from '../leads/leads.service';
import { Lead, WIPLead } from '../models/leads';
import { NotificationService } from '../shared/notification.service';
import { ReportsService } from '../shared/reports.service';

@Component({
  selector: 'app-wip',
  templateUrl: './wip.component.html',
  styleUrls: ['./wip.component.less']
})
export class WipComponent implements OnInit {

  constructor(
    private leadsService: LeadsService,
    public auth: AuthService,
    private notification: NotificationService,
    private router: Router,
    private reportsService: ReportsService) { }

  jobs: WIPLead[] = [];
  isLoading = false;

  //sort functions
  salesManSortFn = (a: Lead, b: Lead): number => a.assignedTo.toLowerCase() < b.assignedTo.toLowerCase() ? -1 : 1;
  customerSortFn = (a: Lead, b: Lead): number => a.customerName.toLowerCase() < b.customerName.toLowerCase() ? -1 : 1;
  createdSortFn = (a: Lead, b: Lead): number => a.dtAction < b.dtAction ? -1 : 1;

  //filter functions
  salesManFilterFn = (salesman: string[], item: Lead): boolean => salesman.some(e => e === item.assignedTo);
  salesManFilterValues: NzTableFilterList = [];
  actionTimeFilterFn = (times: number[], item: Lead): boolean => times.some(e => e === this.getRowWarning(item.actionInMinutes));
  actionTimeFilterValues: NzTableFilterList = [
    {text: "Current", value: 0},
    {text: "1 - 7 Days", value: 1},
    {text: "Over 7 Days", value: 2}
    
    
  ]

  private populateSalesManFilterValues() {
    for (let item of this.jobs) {
      if (!this.salesManFilterValues.some(e => e.text === item.assignedTo)) {
        this.salesManFilterValues.push({ text: item.assignedTo, value: item.assignedTo })
      }

    }
    this.salesManFilterValues = this.salesManFilterValues.slice(); //to force angular change detection
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.leadsService.getWorkInProgress().subscribe(
      val => {
        this.jobs = val;
        this.isLoading = false;
        this.populateSalesManFilterValues();

      },
      err => {
        this.notification.handleError(err);
      }
    )
  }

  generateTimeString(minutes: number): string {
    if (minutes < 0) minutes = minutes * -1;
    if (minutes < 60) return minutes + " minutes";
    if (minutes < 60 * 24) return Math.trunc(minutes / 60).toString() + " hours"
    return Math.trunc(minutes / 60 / 24) + " days";
  }

  getRowWarning(minutes: number): number {
    if (minutes < 0) minutes = minutes * -1;
    if (minutes > 1440 && minutes < 10080) {
      return 1;
    }
    else if (minutes >= 10080) {
      return 2;
    }
    else {
      return 0;
    }
  }

  viewquote(quote: WIPLead) {
    //check if the buyer is SB Fleet, meaning redirect to fleet page and not pos page
    if (quote.buyerId == 674) {
      this.router.navigate(["/pos/fleet", quote.quoteId]);
    }
    else {
      this.router.navigate(["/pos/v2", quote.quoteId]);
    }

  }

  printJobCard(quoteId: number) {
    this.reportsService.openJobCard(quoteId);
  }

}
