import { NotificationService } from '../../shared/notification.service';
import { SalesService } from '../../sales/sales.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Sale, Saleline } from '../../models/sale';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../../search/search.service';
import { aautoquote  } from '../../models/search-obj';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { PurchasesService } from '../../purchases/purchases.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportsService } from 'src/app/shared/reports.service';
import { InvoiceCommunication } from 'src/app/models/communication';
import { SendReportService } from 'src/app/widgets/send-report/send-report.service';
import { DatePipe } from '@angular/common';
import { AppComponent } from '../../app.component';
import { Globals } from 'src/app/shared/globals';


@Component({
  selector: 'app-saleview',
  templateUrl: './saleview.component.html',
  styleUrls: ['./saleview.component.less']
})
export class SaleviewComponent implements OnInit, OnDestroy {

  Sale: Sale= new Sale();
  Salelines : Saleline[]=[]
  paramsSub: Subscription = new Subscription();
  order: number
  hasquote: boolean = false;
  isEmail: boolean = false;
  datePipe: DatePipe = new DatePipe('en-US');

  //Visual Effects
  isLoading = false;

  //Form For Ref
  addReferanceForm: FormGroup = new FormGroup({
    purchaseRef: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });

  constructor(
    private purchaseService: PurchasesService,
    public authService: AuthService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private salesService: SalesService,
    private router: Router, 
    private notification: NotificationService,
    private reportService: ReportsService,
    private sendReportService: SendReportService, 
    public appComponent: AppComponent) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.paramsSub = this.route.params.subscribe(params =>
    {
      this.order = +params['orderid'];
      this.salesService.getSale(this.order).subscribe(res =>
        {
          this.Sale = res[0] as Sale;
          if (this.Sale.quoteId != null)
          {
            this.hasquote = true;
          }
          if(this.Sale.sell_state == "email" && this.Sale.PoRef == null && this.Sale.ExtRef == null)
          {
            this.isEmail = true;
          }
          this.salesService.getSalelines(this.order).subscribe(res =>
          {
            this.Salelines = res as Saleline[];
            this.isLoading = false;
          },
          error =>
          {
            this.notification.handleError(error);
          })
        },
        error =>
        {
          this.notification.handleError(error);
        });
    });

  }

  viewquote()
  {
    this.router.navigate(["/pos/v2",this.Sale.quoteId])
  }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  AutoQuote()
  {
    // this.isAutoQuoting = true;
    let search = "NOTYRE";
    let vreg = "";
    for (var item of this.Salelines) {
      if (item.stock_type == "TYRE") {
        search = item.size;
        break;
      }
    }
    if (this.Sale.vreg != null)
    {
       vreg = this.Sale.vreg;
    }
    let cust = Globals.funcs.getCustomer();
    let StockMsfids = new Array();
    let RecommendedStock = true;

    let quote : aautoquote = {
      sellerId : this.authService.user.client_id,
      ext_Ref : "",
      comment :"",
      name: this.Sale.customerName,
      cell:this.Sale.customercell,
      vreg: vreg,
      customerId: cust.customerId,
      assignedToUserId: this.authService.user.user_id,
      lastcall: parseInt(localStorage.getItem('lastcall')!),
      quote_logic : {
                      search_requirements :
                                            {
                                              raw_search: search,
                                              stock_msfids:StockMsfids,
                                              none_stock_msfids: [],
                                              buyerId: this.authService.user.client_id,
                                              specialPriceSetId: this.searchService.lastSelectedSpecialPriceSetId
                                            },
                      add_lead: true,
                      qty: 4,
                      allow_partial_full:true,
                      base_level_limit: "Remote",
                      add_recommended_stock:RecommendedStock,
                      max_recommended_options: 5,
                      recommended_level_limit: "Remote",
                      add_default_services: true,
                      isContractPricing: false
                    }
    }
    this.QuoteSubscribeCall(quote);
  }

  quoteagain()
  {
    this.isLoading = true;
    let search = "NOTYRE";
    for (var item of this.Salelines) {
      if (item.stock_type == "TYRE") {
        search = item.size;
        break;
      }
    }
    
    var StockMsfids = new Array();
    var customStockIds = new Array();
    var vreg = ""
    let addRecommended = false;
    if (this.Sale.vreg != null)
    {
       vreg = this.Sale.vreg;
    }

    StockMsfids = this.Salelines.filter(val => val.stock_type === "TYRE").map(e => e.msfid);
    customStockIds = this.Salelines.filter(val => val.stock_type !== "TYRE").map(e => e.customStockId);

    let quote : aautoquote = {
      sellerId :this.Sale.sellerId,
      ext_Ref : "",
      comment :"",
      name: this.Sale.customername,
      cell: this.Sale.customercell,
      vreg: vreg,
      assignedToUserId: this.authService.user.user_id,
      lastcall: parseInt(localStorage.getItem('lastcall')!),
      quote_logic : {
                      search_requirements :
                                            {
                                              raw_search: search,
                                              stock_msfids: StockMsfids,
                                              none_stock_msfids: [],
                                              custom_stock_ids: customStockIds,
                                              buyerId: this.Sale.sellerId,
                                              specialPriceSetId: this.searchService.lastSelectedSpecialPriceSetId
                                            },
                      add_lead: true,
                      qty: 4,
                      allow_partial_full:true,
                      base_level_limit: "Remote",
                      add_recommended_stock:addRecommended,
                      max_recommended_options: 5,
                      recommended_level_limit: "Remote",
                      add_default_services: false,
                      isContractPricing: false
                    }
    }
    this.QuoteSubscribeCall(quote);
  }

  QuoteSubscribeCall(quote: aautoquote){
    this.searchService.Autoquote(quote, {customerId : this.Sale.customerId, vehicleId : this.Sale.vehicleId}).subscribe({
      next: res => {
         var quoteId = res["quoteId"];
         if(quoteId != 0)
         {
            this.isLoading = false;
            this.notification.showSuccess('Quote Created');
            this.router.navigate(["/pos/v2",quoteId])
         }
      },
      error: error => {
        this.isLoading = false;
        this.notification.handleError(error);
      }
    });
  }


  saveRef()
  {
    if(this.addReferanceForm.value.purchaseRef == "")
    {
      Object.values(this.addReferanceForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    if(this.addReferanceForm.valid)
    {
      this.purchaseService.saveRef(this.order,this.addReferanceForm.value.purchaseRef).subscribe(res => {
        this.notification.showSuccess("Saved Reference");
        this.ngOnInit();
        this.isEmail = false;
      },
      error =>
      {
        this.notification.handleError(error);
      });
    }
  }

  printInvoice() {
    this.reportService.openInvoice(this.order);
  }

  communicateInvoice() {
    
    let rpt = new InvoiceCommunication(this.Sale.orderRef, this.order);
    
    this.sendReportService.assignReport(rpt);
  }

  goBack()
  {
    window.history.back(); 
  }
}
