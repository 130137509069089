<div class="container-fluid">
    <div nz-row class="justify-content-md-center">
        <div nz-col nzSpan="24">
            <nz-table #open [nzData]="jobs" [nzFrontPagination]="false" [nzLoading]="isLoading" style="overflow-x: auto;">
                <thead>
                    <tr>
                        <th style="width: 5%"></th>
                        <th  [nzSortFn]="customerSortFn">Customer</th>
                        <!-- <th>Quote State</th> -->
                        <th [nzSortFn]="salesManSortFn" [nzFilterFn]="salesManFilterFn" [nzFilters]="salesManFilterValues" nzShowFilter>Salesman</th>
                        <th>Channel</th>
                        <th style="text-align:center;">Vehicle</th>
                        <th style="text-align: center;">
                            Stock
                        </th>
                        <th>
                            Total
                        </th>
                        <th [nzSortFn]="createdSortFn" nzShowFilter [nzFilterFn]="actionTimeFilterFn" [nzFilters]="actionTimeFilterValues">
                            Created
                        </th>
                        <th style="align-content: center;"></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of open.data">
                        <td style="text-align: center;">
                            <span nz-icon nzType="warning" nzTheme="fill" *ngIf="getRowWarning(item.actionInMinutes) > 0" [ngClass]="{'iconRed' : getRowWarning(item.actionInMinutes) === 2, 'iconYellow' : getRowWarning(item.actionInMinutes) === 1}"></span>
                        </td>
                        <td>{{item.customerName}}<br />{{item.customerCell}} </td>

                        <td>{{item.assignedTo}}</td>
                        <td> {{ item.buyerName }}</td>
                        <td style="text-align:center;">
                            {{item.vehicle_Reg}}<br> {{item.vehicle_Make}} <br> {{item.vehicle_Model}}
                        </td>
                        <td style="text-align: center;">
                            <i nz-icon nzType="minus-circle" nzTheme="fill" class="iconNeutral" [nz-tooltip]="'Stock Not Reserved'" *ngIf="item.statusId === 26"></i>
                            <i nz-icon nzType="check-circle" nzTheme="fill" class="iconGreen" [nz-tooltip]="'Stock Reserved'" *ngIf="item.statusId === 27"></i>
                        </td>
                        <td>
                            R{{item.total | number : '1.2-2'}} <small class="text-info-style">Ex</small><br />
                            R{{item.totalIncl | number : '1.2-2'}} <small class="text-info-style">Incl</small>
                        </td>
                        <td>
                            {{ item.dtAction + "Z" | date:'dd-MMM HH:mm' }}<br />
                            <small [ngClass]="{'spanRed' : getRowWarning(item.actionInMinutes) === 2, 'spanYellow' : getRowWarning(item.actionInMinutes) === 1}"><i>{{generateTimeString(item.actionInMinutes)}}</i></small>
                        </td>
                        <td style="text-align: center;">
                            <button nz-button nzType="primary" id="btnview" (click)="viewquote(item)" nz-tooltip nzTooltipColor='var(--textcolor)' nzTooltipPlacement='top' nzTooltipTitle='View/Edit Job Card'>
                            Open<span nz-icon nzType="import" nzTheme="outline"></span>
                            </button>
                            <!-- <button style="border:none;" nz-tooltip nzTooltipColor='var(--textcolor)' nzTooltipPlacement='top' nzTooltipTitle='Print Job Card' (click)="printJobCard(item.quoteId)">
                                <span nz-icon nzType="credit-card" nzTheme="outline" style="color:var(--textcolor); margin-right: 5px;"></span> Job Card
                            </button> -->
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>
