import { MapCodesLinked } from "./salesCodes"

export class Sale {
  name:string
  sellerId:number
  email:string
  cell:string
  vreg:string
  orderRef:string
  SFRef:string     // sf sales order ref
  SysRef:string    // client pos ref so SS/IQ
  PoRef:string     // po ref for email clients
  ExtRef:string    // stock order ref External so SB Custom etc
  comment:string
  orderid:number
  statusId:number
  dateformatted : string
  qty:number
  price:number
  dt : string
  dt_email:string
  dt_so: string
  dtCreatedGMT: Date
  dtEmailedGMT: Date
  dtSoGMT: Date
  errorId:number
  description:string
  quoteId:number
  customername:string
  customercell:string
  username:string
  usercell:string
  useremail:string
  error:string
  errorFix: number
  customerName: string
  sell_state: string
  sellerPos: string
  buyerPos: string
  buyerId?:number
  unmappedCodes: MapCodesLinked[] = [];
  seen: boolean
  showERef: boolean = false 
  showDelivery: boolean = false 
  showVreg: boolean = false 
  showMilage: boolean = false 
  showVmake:boolean = false 
  delivery: string = "" 
  milage: string = "" 
  v_make: string = "" 
  v_reg:string = ""
  pos:string = ""
  customerId: number
  vehicleId: number
}

export class Saleline {
  msfid: any
  description_Long:string
  code: string
  size:string
  stock_type:string
  orderId: number
  qty: number
  price: number
  cost: number
  gp: number
  brandLogo_url: string
  customStockId: number
}

export class AddSale {
  sf_ref: string
  sellerId: number
  buyerId: number
  email: string
  cell: string
  comment: string
  ext_Ref: string
  customerId: number
  delay_send: number
  line_items: AddSaleLine[]
  orderId: number
  customerCode: number
  odo: number
  make: string
  model:string
  reg:string
  driver_name:string
  driver_cell:string
  userId:number
  manualInsert:number
  fleetAuthType:number;
  specialPriceSetId:number;
}

export class AddSaleLine {
  msfid: number
  qty: number
  price: number
  sellerId: number
  levelId: number
  cost: number
  customStockId: number
  altStockCode: string
  altStockDescription: string
}

export class AutoMAPSearch {
  code:string = ""
}
