import { NotificationService } from '../shared/notification.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { SalesService } from '../sales/sales.service';
import { FleetService } from '../fleet/fleet.service';
import { Statusdata } from './../models/statusdata';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { Sale, } from './../models/sale';
import { MapCodesLinked } from '../models/salesCodes';
import { TableOrder} from './../models/orders';
import { PosQuote} from 'src/app/models/pos';
import { Router } from '@angular/router';
import { ReportsService } from '../shared/reports.service';
import { AuthService } from '../auth.service';
import { SendReportService } from '../widgets/send-report/send-report.service';
import { InvoiceCommunication } from '../models/communication';
import { salesAutoMAP } from '../models/stock';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.less']
})
export class SalesComponent implements OnInit {

  //Constants
  readonly ITEMS_PER_PAGE = 50;

  //Global Variables
  SalesList: Sale[]=[];
  TableOrders: TableOrder[]=[];
  
  todayList: Sale[]=[];
  weekList: Sale[]=[];
  pastList: Sale[]=[];
  StatusData: Statusdata;

  obj:any;
  loaded:boolean = false;
  posQuote: PosQuote = new PosQuote()
  isLoadingMoreItems: boolean = false;

  //Visual Effects
  isLoading = false;
  isSubmitModalVisible = false;
  isshowError = false;
  isshowItem:Sale = new Sale();
  solutions: string[] = [];
  showMapOptions: boolean = false;
  showMapCodes: MapCodesLinked[] = [];
  linkingCode = "";
  inputValue: string = "";
  

  constructor(
    private salesService: SalesService,
    private router: Router,
    private fleetService: FleetService, 
    private notification: NotificationService,
    private modalService: NzModalService,
    private reportService: ReportsService,
    public authService: AuthService,
    private sendReportService: SendReportService, 
    public appComponent: AppComponent) { }

   ngOnInit(): void
  {
   this.isLoading = true; 
   this.salesService.getSales().subscribe(async (res:any) =>
    {
      this.SalesList = [];
      this.SalesList = res.sales as Sale[];
      this.filterSales();
      this.isLoading = false;
    },
    error => {
      this.notification.handleError(error);
    });
  }

  filterSales()
  {
    var tod = new Date();tod.setHours(0,0,0,0);
    var Today = Date.parse(tod.toString());
    var Week = tod.setDate(tod.getDate() - 7);

    this.TableOrders = [];
    this.todayList = [];
    this.weekList = [];
    this.pastList = [];

    for(var i = 0 ; i < this.SalesList.length; i++)
    {
      var order = this.SalesList[i]; var orderdate = Date.parse(order.dt);

      if(order.sell_state == 'email' && order.statusId ! in [5,11])
      {
        if(order.PoRef == "None")
        {
          order.error = "Order Received - Add Reference Number to Complete Order";
          order.statusId = 12;
          order.errorFix = 12;
        }
        else if (order.PoRef != "None")
        {
          order.statusId = 5;
        }
      }
      else
      {
        //Check Orders still processing day after
        if(order.statusId == 0)
        {
          if(orderdate <= Today && orderdate > Week)
          {
            order.statusId = 4;
            order.error = "Order timed out , Please Contact SF (066 453 2826)"
            order.errorFix = 2
          }
        }

        if(order.statusId == 4 || order.statusId == 7  )
        {
              var dt = new Date;
              var formatdt = dt.getDay() + " " + dt.getMonth() + " " + dt.getFullYear();
              var dtObj = new Date(orderdate)
              var formatdtObj = dtObj .getDay() + " " +dtObj .getMonth() + " " + dtObj.getFullYear();
              if(formatdt != formatdtObj &&  order.errorFix == 1)
              {

                order.errorFix = 2;
              }
              else if (formatdt == formatdtObj &&  order.errorFix == 1)
              {
                const hourInMilliseconds = 60 * 60 * 1000;
                const hourAgo = Date.now() - hourInMilliseconds;
                if(orderdate < hourAgo)
                {
                  order.errorFix = 2;
                }
              }
        }
      }

      if(orderdate >= Today)
      {
        this.todayList.push(order)

      }
      if(orderdate < Today && orderdate >= Week)
      {
        this.weekList.push(order)

      }
      if(orderdate < Week)
      {
        this.pastList.push(order)

      }
    }

   
    let TodayTable = new TableOrder(); 
    TodayTable.title = "Today"; 
    TodayTable.period = "today"; 
    TodayTable.sales = this.todayList.slice(0,this.ITEMS_PER_PAGE);
    this.TableOrders.push(TodayTable);
  
    let WeekTable  = new TableOrder(); 
    WeekTable.title = "This Week"; 
    WeekTable.period = "week"; 
    WeekTable.sales = this.weekList.slice(0,this.ITEMS_PER_PAGE);
    this.TableOrders.push(WeekTable);
  
    let PastTable  = new TableOrder(); 
    PastTable.title = "Past Orders"; 
    PastTable.period = "past"; 
    PastTable.sales =  this.pastList.slice(0,this.ITEMS_PER_PAGE);
    this.TableOrders.push(PastTable);
  
 }

 public checkDisplayLoadMore(tblIndex: number): boolean {
   switch (tblIndex) {
    case 0: {
      return this.todayList.length > this.TableOrders[tblIndex].sales.length;
      break;
    }
    case 1: { 
      return this.weekList.length > this.TableOrders[tblIndex].sales.length;
      break;
    }
    case 2: {
      return this.pastList.length > this.TableOrders[tblIndex].sales.length;
      break;
    }
   }
   return true; 
 }

 public loadMore(tblIndex: number) { 
  this.isLoadingMoreItems = true;
  switch (tblIndex) {
    case 0: { 
      this.TableOrders[tblIndex].sales = this.todayList.slice(0, this.TableOrders[tblIndex].sales.length + this.ITEMS_PER_PAGE);
      break;
    }
    case 1: {
      this.TableOrders[tblIndex].sales = this.weekList.slice(0, this.TableOrders[tblIndex].sales.length + this.ITEMS_PER_PAGE);
      break;
    }
    case 2: {
      this.TableOrders[tblIndex].sales = this.pastList.slice(0, this.TableOrders[tblIndex].sales.length + this.ITEMS_PER_PAGE);
      break;
    }
  }
  this.isLoadingMoreItems = false;
 }

  resubmitOrder(orderid)
  {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Resubmitting Order", 
      htmlMessage: '' ,
      maskClosable: false});
    this.salesService.resubmit_order(orderid).subscribe(
      res =>
          {
            this.notification.removeAlert();
            this.ngOnInit();
          },
      error =>
          {
            this.notification.handleError(error);
          });

  }

  resubmitAuth(orderid)
  {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Resubmitting Auth", 
      htmlMessage: '' ,
      maskClosable: false});
    this.fleetService.resubmit_sb_auth(orderid).subscribe(
      res =>
          {
              this.notification.removeAlert();
              if(res['status_code'] == 202)
              {
                  this.modalService.create({
                    nzTitle: 'Success',
                    nzContent: 'Auth Was Successfully Submitted. In a few seconds you will get the result.'
                  });
                  this.router.navigate["/sales"];
               }
              else
              {
                this.modalService.create({
                  nzTitle: 'Error' + res['status_code'],
                  nzContent: res['status_reason']  +' . please process this request via the call centre.'
                });
                this.router.navigate["/search"]
              }
            },
      error =>
          {
            this.notification.handleError(error);
          });
  }

  check_sb_auth(extref)
  {
    if(extref != '')
    {
      this.notification.ShowAlert({type: resultType.Information, 
        title: "Checking Auth State", 
        htmlMessage: '' ,
        maskClosable: false});
      this.fleetService.check_sb_auth(extref).subscribe(res =>
          {
            if(res['authorisationNumber'] != "999")
            {
              if (res['authorisationNumber'] =='-1' || res['authorisationNumber'] =='-8')
              {
                this.notification.ShowAlert({type: resultType.Error, 
                  title: "Authorisation Failed", 
                  htmlMessage: JSON.stringify(res) ,
                  maskClosable: false, 
                  autoCloseInMilliseconds: 20000});

                  setTimeout(() => {
                    this.ngOnInit();
                  }, 20000);
              }
              else if (res['authorisationNumber']  =='-2')
              {
                this.notification.ShowAlert({type: resultType.Warning, 
                  title: "Authorisation in Progress", 
                  htmlMessage: '' ,
                  maskClosable: false, 
                  autoCloseInMilliseconds: 20000});

                  setTimeout(() => {
                    this.ngOnInit();
                  }, 20000);
              }
              else
              {
                this.notification.ShowAlert({type: resultType.Success,
                  title: 'Authorisation Successful!',
                  htmlMessage: 'You can start working on the vehicle.', 
                  autoCloseInMilliseconds: 4500});

                  setTimeout(() => {
                    this.ngOnInit();
                  }, 4500);
              }
            }
            else
            {
              this.notification.ShowAlert({type: resultType.Error,
                title: 'Authorisation Failed, Server Unresponsive , Contact SF',
                htmlMessage: JSON.stringify(res), 
                autoCloseInMilliseconds: 20000});

                setTimeout(() => {
                  this.ngOnInit();
                }, 20000);
            }
          },
          error => {
            this.notification.handleError(error);
          });
    }
    else
    {
        this.ngOnInit();
    }
  }

  download_sb_auth(extref)
  {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Checking For Auth PDF", 
      htmlMessage: 'It can take a few minutes for the PDF to be created' ,
      maskClosable: false});

    this.fleetService.getletter_sb_auth(extref).subscribe(res =>
      {
        if (res == null || res.length === 0)
        {
          this.notification.removeAlert();
          this.notification.ShowAlert({type: resultType.Error, 
            title: "The Auth Letter is not yet created", 
            htmlMessage: 'Please try again in two minutes. The Auth is approved so you can start working on the vehicle.' ,
            maskClosable: false, 
            autoCloseInMilliseconds: 20000});

            setTimeout(() => {
              this.ngOnInit();
            }, 20000);
        }
        else
        {
          this.notification.removeAlert();
          var c64 = res[0].fileData;

          this.notification.ShowAlert({type: resultType.Warning, 
            title: "", 
            htmlMessage: '' ,
            maskClosable: false, 
            autoCloseInMilliseconds: 3000, 
            });

            this.notification.ShowAlert({type: resultType.Success,
              title: "The Auth Letter is Available for download",
              htmlMessage: 'Would you like to download it ?',
              maskClosable: true,
              autoCloseInMilliseconds: 10000,
              closable:true,
              btnText: 'Download'}, undefined, () => {
                // console.log('Working Fine');
                const linkSource = "data:" + res[0].fileContentType + ";base64,"+ c64;
                const downloadLink = document.createElement("a");
                const fileName = res[0].filename;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.title = fileName;
                downloadLink.click();
            });

        }
      }, err => {
        this.notification.handleError(err, "Error while downloading Auth Letter.","Auth Letter");
      })
  }

  viewsale(orderid)
  {
    this.router.navigate(["/sales/view",orderid])
  }

  printInvoice(orderId: number) {
    this.reportService.openInvoice(orderId);
  }

  communicateInvoice(item: Sale) {
    let rpt = new InvoiceCommunication(item.orderRef, item.orderid);
    
    this.sendReportService.assignReport(rpt);
  }

  isShowError(item:Sale)
  {
    this.isshowItem = item; 
    this.checkError(item);
    this.isshowError = true;
  }

  checkError(item: Sale)
  {

    this.solutions = [];
    this.showMapCodes = [];

    if(item.error.includes("Order Failed - Unable to map Stock Code(s)"))
    {

      this.solutions.push("* Check that the Code(s) are in your POS system, If not Add Them");

      for (var i = 0; i < item.unmappedCodes.length;i++)
      {
         if(item.unmappedCodes[i].AutoMapMsfid != null && item.unmappedCodes[i].AutoMapCode != "")
         {
            if(!item.unmappedCodes[i].ismapped)
            {
              this.showMapCodes.push(item.unmappedCodes[i]);
            }
         }
      }

      if(this.showMapCodes.length > 0)
      {
        this.solutions.push("* Manually map the codes below by entering the correct code and confirming");
        this.showMapOptions = true;
      }
    }
    if(item.error.includes("Existing API Request already in progress IQ API is experiencing high volumes , please resubmit order"))
    {
      this.solutions.push("Wait about 2 mins");
    }
    if(item.error.includes("Company [") || item.error.includes("for Company [") )
    {
      this.solutions.push("Please Contact Dewalt - 0829041585 ");
    }
    if(item.error.includes("Order Failed - Unable to connect to API"))
    {
      this.solutions.push("Restart Your API ");
    }
  }

  autoMap(mapObject:MapCodesLinked)
  {
    let newMap = new salesAutoMAP();
    newMap.clientId = this.authService.user.client_id;
    newMap.mappingTypeId = 5;
    newMap.msfid = mapObject.AutoMapMsfid;
    newMap.stockcode = mapObject.AutoMapCode;
    newMap.userId = this.authService.user.user_id;
    newMap.orderId = mapObject.orderId;
    let FoundCode = this.showMapCodes.find(x=> x.AutoMapMsfid == mapObject.AutoMapMsfid)!;
    FoundCode.ismapped = true;
    this.salesService.autoMapCode(newMap).subscribe(res => {
      this.notification.showSuccess("Mappings Completed Successfully");
    },
    error => {
      this.notification.handleError(error);
    });
  }

  markAllSalesAsSeen() {
    this.isLoading = true;
    this.salesService.markAllAsSeen().subscribe(
      val => {
        //mark all slaes as seen on the current screen
        this.SalesList.map(e => {
          e.seen = true;
        });
        this.isLoading = false;
        this.notification.showSuccess("All sales marked as seen");
      },  
      err => {
        this.isLoading = false;
        this.notification.handleError(err);
      }
    )
  }
}

