<ng-template #sellingTemplate><small style="vertical-align: top;">SELLING</small></ng-template>
<ng-template #buyingTemplate><small style="vertical-align: top;">BUYING</small></ng-template>

<nz-card nzBorderless class="sticky-component" style="border-bottom: 2px solid var(--textcolor); border-radius: 0px; background-color: var(--bodybackgroundcolor);">
    <div nz-row nzJustify="center">
        <div nz-col>
            <nz-switch class="mobile-view" [(ngModel)]="isInSellingMode" (ngModelChange)="switchStockAction()" nz-tooltip nzTooltipPlacement="bottom" [nzTooltipTitle]="switchTo" [nzCheckedChildren]="sellingTemplate" [nzUnCheckedChildren]="buyingTemplate" style="transform: scale(2, 2);"></nz-switch>
        </div>
    </div>
    <div nz-row nzAlign="middle" nzJustify="center">
        <div nz-col nzXs="0" nzSm="8" nzMd="8" nzLg="8" class="text-center">
            <button nz-button nzType="primary" *ngIf="isShop" (click)="AutoQuote()" [nzLoading]="isAutoQuoting">
                <span nz-icon nzType="import" nzTheme="outline" *ngIf="!isAutoQuoting"></span>
                <span style="margin-left:5px ;"> Quick Quote </span>
            </button>
        </div>
        <div nz-col nzXs="12" nzSm="6" nzMd="8" nzLg="8" class="text-center">
            <!--nzXs is set to 12 because the switch scale expands the whole column and needs to be smaller-->
            <nz-switch [(ngModel)]="isInSellingMode" (ngModelChange)="switchStockAction()" class="web-view" nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="switchTo" [nzCheckedChildren]="sellingTemplate" [nzUnCheckedChildren]="buyingTemplate" style="transform: scale(1.5, 1.5);"></nz-switch>
            <br>
            <nz-input-group [nzSuffix]="suffixIconSearch" class="mobile-view">
                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="input.inputValue" (ngModelChange)="resetTable(3)" style="text-transform:uppercase;"/>
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>
        </div>
        <div nz-col nzXs="0" nzSm="10" nzMd="8" nzLg="8" class="text-center">
            <button nz-button nzType="default" id="infobtn" (click)="selectSupplier()" *ngIf="isShop" style="margin-right: 16px;">
                <nz-badge [nzCount]="this.cart.length" [nzOffset]="[15, 0]">
                    <span nz-icon nzType="shopping-cart" nzTheme="outline"></span>
                    <span class="mobile">Cart</span>
                </nz-badge>
            </button>
            <button nz-button nzType="default" (click)="ManualQuote()" [nzLoading]="isAutoQuoting">
                <nz-badge [nzCount]="this.quote.length" [nzOffset]="[10, 0]">  
                    <span nz-icon nzType="diff" nzTheme="outline"></span>
                    <span class="mobile"> Quote</span>
                </nz-badge>  
            </button>
        </div>
    </div>


<div nz-row class="web-view" nzAlign="middle">
    <div nz-col nzXs="12" nzSm="0" nzMd="0" nzLg="0" class="text-center">
        <button nz-button nzType="primary" *ngIf="isShop" (click)="AutoQuote()" [nzLoading]="isAutoQuoting">
            <span nz-icon nzType="import" nzTheme="outline" *ngIf="!isAutoQuoting"></span>
            <span style="margin-left:5px ;"> Quick Quote </span>
        </button>
    </div>
    <div nz-col nzXs="12" nzSm="0" nzMd="0" nzLg="0" class="text-center">
        <button nz-button nzType="default" id="infobtn" (click)="selectSupplier()" *ngIf="isShop && authService.checkMenu(['PUR'])" style="margin-right: 16px;">
            <nz-badge [nzCount]="this.cart.length" [nzOffset]="[15, 0]">
                <span nz-icon nzType="shopping-cart" nzTheme="outline"></span>
                <span class="mobile">Cart</span>
            </nz-badge>
        </button>
        <button nz-button nzType="default" (click)="ManualQuote()" [nzLoading]="isAutoQuoting">
            <nz-badge [nzCount]="this.quote.length" [nzOffset]="[10, 0]">  
                <span nz-icon nzType="diff" nzTheme="outline"></span>
                <span class="mobile"> Quote</span>
            </nz-badge>  
        </button>
    </div>
</div>
</nz-card>
<div class="row" *ngIf="minenabled">
    <div class="col-lg-12 text-center">
        <b style="font-size: 15px;">You have ordered less than the minimum required mass/value of stock , as a result a minimum order fee has been added to your order </b>
    </div>
</div>
<br>
<nz-table class="mobile-table" *ngIf="isShop" #stockTable [nzData]="Tyres" [nzFrontPagination]="false" [nzShowPagination]="false" nzSize="small" [nzLoading]="isLoading" [nzNoResult]="noData" >
    <thead >
        <tr class="sticky-header">
            <th nzWidth="90px" [nzSortFn]="sortBrandFn" >Brand</th>
            <th [nzSortFn]="sortTyreFn" class="text-left">Tyre</th>
            <th nzWidth="120px" [nzSortFn]="sortCodeFn" class="text-left">Code</th>
            <th nzWidth="110px" [nzSortFn]="sortSlaFn">SLA</th>
            <th [nzSortFn]="sortSupplierFn">Supplier</th>
            <th nzWidth="100px" [nzSortFn]="sortCostFn">
                {{stockAction}} <small class="text-style">{{vatShow}}</small>
            </th>
            <th nzWidth="80px" [nzSortFn]="sortSohFn">SOH</th>
            <th nzWidth="120px" class="text-left"> Cart/Quote </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of stockTable.data | filter: input.inputValue: input.inputList: 0: pageSize;" [class.active]="data.highlight" (click)="setTarget(data)" class="color-change">

            <td class="text-center">
                <img nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="data.brand" class="brandImage" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.image}}'>
            </td>
            <td class="text-left">
                <span *ngIf="data.show_meta" nz-icon nzType="info-circle" nzTheme="outline" style="float: inline-start;color: var(--textcolor);cursor: pointer;padding-right: 5px;" (click)="fetchMeta(data)"></span> <span [nz-tooltip]="data.description">{{data.description}}</span>

            </td>
            <td class="text-left">{{data.stock_code}}</td>
            <td class="text-center">
                <ng-template *ngIf="!data.showSlots then singleSla; else showSla"></ng-template>
                <ng-template #singleSla>
                    <!-- <span [ngClass]="{'color-warning' : data.sla_warning === true}">{{data.sla}} </span> -->
                    <span *ngIf="data.sla_warning === false">{{data.sla}} </span>
                    <span class="color-warning" *ngIf="data.sla_warning === true" nz-tooltip [nzTooltipTitle]="slaTooltip">{{data.sla}}</span>
                    <!-- <span *ngIf="data.sla_warning === true" nz-icon nzType="warning" nzTheme="fill" nz-tooltip [nzTooltipTitle]="slaTooltip"></span> -->
                    <ng-template #slaTooltip let-thing>
                        <span *ngIf="data.sla_warning">Original sla was set at <span class="color-primary">{{data.sla_original}}</span>, but this stock is only available in <span class="color-warning">{{data.sla}}</span>.</span>
                    </ng-template>
                </ng-template>
                <ng-template #showSla>
                    <ng-template #slaTemplate>
                        <nz-table #slots [nzData]="data.Slaslots">
                            <tr *ngFor="let slot of slots.data" style="border-bottom: 1px solid;">
                                <td>
                                    <span style="font-size: 0.7rem;color: var(--textcolor);font-style: italic;">
                                            Cut Off
                                            </span><br>
                                    <span>
                                                {{ slot.cutOffTime }}
                                            </span>
                                </td>
                                <td>
                                    <span style="font-size: 0.7rem;color: var(--textcolor);font-style: italic;">Delivery</span><br>
                                    <span>{{ slot.expectedDelivery }}</span>
                                </td>
                            </tr>
                        </nz-table>
                    </ng-template>
                    <div nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="slaTemplate">
                        {{data.sla}}
                    </div>
                </ng-template>
            </td>
            <td>
                <div nz-row nzAlign="middle">
                    <div nz-col nzSpan="20" class="text-left">
                        <span *ngIf="data.sellerId == authService.user.client_id" nz-icon nzType="home" nzTheme="fill"></span><span>{{data.seller_name}}</span>
                    </div>
                    <div nz-col nzSpan="4" class="text-right" *ngIf="authService.hasClaims(['SPO'])">

                        <div nz-row nzAlign="middle">
                            <div nz-col nzSpan="6" class="text-right">
                                <i *ngIf="data.isTargetted" nz-icon nzType="caret-right" nzTheme="outline" style="color:var(--textcolor)"></i>
                                <i *ngIf="data.highlight" nz-icon nzType="caret-right" nzTheme="outline"></i>
                            </div>
                            <div nz-col nzSpan="18" class="text-right">
                                <small *ngIf="data.isTargetted" style="float: inline-end;"> 
                                            <span style="color:var(--textcolor)">R </span> {{data.gp_rands}}
                                            </small>

                                <small *ngIf="data.highlight" style="float: inline-end;"> 
                                            {{data.gpExtra}}
                                        </small>
                            </div>
                        </div>

                    </div>
                </div>
            </td>
            <td [ngClass]="isInSellingMode ? 'bg-highlight' : ''">
                <ng-template *ngIf="data.seller_sell_state =='soh'; else alter ">
                    -
                </ng-template>
                <ng-template #alter>
                    <ng-template #toolTipTemplate>
                        <table>
                            <tr>
                                <td>
                                    <small>RETAIL<br/></small> R {{data.retail_Vat }}
                                    <small style="color: var(--textcolor);">less</small>
                                </td>
                                <td>
                                    <nz-input-number style="width: 60px !important;" [nzMin]="1" [nzMax]="99" [(ngModel)]="data.retailValuePercent" (ngModelChange)="CalculateLessRetail(data,$event)" [nzFormatter]="percentFormatter">
                                    </nz-input-number>
                                </td>
                                <td>
                                    <button nz-button nzType="default">
                                            <span> R {{data.retailValueLess  }}</span>
                                            </button>
                                </td>
                            </tr>

                            <tr style="border-top: lightgray 1px solid;">
                                <td>R {{ data.Gp_RandsHigh }} </td>
                                <td>
                                    <div style="padding-left: 10px;">{{ data.Gp_Percenthigh}}%</div>
                                </td>
                                <td><button nz-button nzType="default">R {{ data.Five_High }}</button></td>
                            </tr>
                            <tr style="border-top: lightgray 1px solid;">
                                <td>R {{ data.Gp_Rands }} </td>
                                <td>
                                    <div style="padding-left: 10px;">{{ data.Gp_Percent }}%</div>
                                </td>
                                <td><button nz-button nzType="default">R {{  data.market_price_Vat }}</button></td>
                            </tr>
                            <tr style="border-top: lightgray 1px solid;">
                                <td>R {{ data.Gp_Randslow}} </td>
                                <td>
                                    <div style="padding-left: 10px;">{{ data.Gp_Percentlow }}%</div>
                                </td>
                                <td><button nz-button nzType="default">R {{ data.Five_low }}</button></td>
                            </tr>
                        </table>
                    </ng-template>
                    <div nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="toolTipTemplate" (nzPopoverVisibleChange)="popupLog()">
                        <ng-container *ngIf="!isInSellingMode">
                            <span class="text-info-style">R </span>{{data.buy_price|number }}
                        </ng-container>
                        <ng-container *ngIf="isInSellingMode">
                            <span class="text-info-style">R </span>{{data.market_price_Vat|number }}
                        </ng-container>
                    </div>
                </ng-template>
            </td>
            <td>{{data.soh}}  
                <a *ngIf="data.stock_load_status == 'red';" style="color: var(--warningcolor); position:absolute; right: 2px;"><i  nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip ="{{data.seller_name}}s' stock has not loaded in the last 24 hours"></i></a>
                <a *ngIf="data.stock_load_status == 'orange';" style="color: var(--warningcolor); position:absolute; right: 2px;"><i  nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip ="{{data.seller_name}}s' stock has not loaded in over one hour"></i></a>
            </td>
            <!--Cart/Quote-->
            <td>
                <span *ngIf="(data.seller_sell_state!= 'no' || data.seller_sell_state== 'no') && data.seller_sell_state!= 'soh'">
                            <button nz-button nzType="default" nzShape="round" *ngIf="data.inQuote == false" (click)="addToItem(data)">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>
                </button>
                </span>
                <span *ngIf="(data.seller_sell_state!= 'no' || data.seller_sell_state== 'no') && data.seller_sell_state!= 'soh'" style="margin-right: 1rem;">
                            <button nz-button nzType="primary" nzShape="round" *ngIf="data.inQuote== true" (click)="removeTableItem(data)">
                                <span nz-icon nzType="minus" nzTheme="outline"></span>
                </button>
                </span>
                <span *ngIf="data.seller_sell_state === 'no'" nz-icon nzType="warning" nzTheme="fill" class="color-warning" nz-tooltip [nzTooltipTitle]="titleTemplate"></span>
                <ng-template #titleTemplate let-thing>
                    <span>Cannot order from {{data.seller_name}} directly through StockFinder.</span>
                </ng-template>
            </td>
        </tr>
    </tbody>
    <ng-template  #noData>
        <nz-empty
        nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
                <span>
                No Data For 
                    <b><a routerLink="/search">"{{search}}"</a></b>
                </span>
            </ng-template>
        </nz-empty>
    </ng-template>
</nz-table>

<!-- Cart -->
<div *ngIf=" isShownCart">
    <div nz-row nzJustify="center">
        <div nz-col nzSpan="20">
            <nz-card nzHoverable>
                <div nz-row [nzGutter]="[16, 16]">
                    <div nz-col nzMd="24" nzLg="24" nzXl="19">
                        <div class="title">
                            <div nz-row>
                                <div nz-col>
                                    <h4><b>Shopping Cart </b></h4>
                                </div>
                                <div class="col align-self-center text-right text-muted">{{ this.cartItems }} items</div>
                            </div>
                            <div nz-row [nzGutter]="[16, 16]">
                                <div nz-col>
                                    <h6>Order <span class="text-style">{{ this.OrderFrom  }}</span> {{ this.OrderSla }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nz-col nzXs="0" nzSm="0" nzMd="0" nzLg="0" [nzXl]="{ span: 4, offset: 1 }">
                        <h4><b>Summary</b></h4>
                    </nz-col>
                </div>
                <div nz-row [nzGutter]="[16, 16]">
                    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="19" class="border-top border-bottom">
                        <div nz-row *ngFor="let data of this.cart" nzAlign="top" [nzGutter]="[8, 24]">
                            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="0" nzXXl="0" class="border-bottom"></div>
                            <div nz-col nzXs="24" nzSm="6" nzMd="5" nzLg="4" nzXl="4" nzXXl="3">
                                <div nz-row class="text-muted">Brand</div>
                                <img class="brandImage" *ngIf="data.id != 'idMOF'" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.image}}'>
                                <img class="brandImage" *ngIf="data.id == 'idMOF'" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.image}}'>
                            </div>
                            <div nz-col nzXs="24" nzSm="18" nzMd="8" nzLg="11" nzXl="11" nzXXl="15">
                                <div nz-row class="text-muted">Description</div>
                                <div nz-row class="text-muted"><span style="color: red;">{{data.error_message}}</span></div>
                                <div nz-row>
                                    <span><b>{{ data.description }}</b></span>
                                </div>
                            </div>
                            <div *ngIf="data.id != 'idMOF'" nz-col nzXs="4" nzSm="3" nzMd="2" nzLg="1.5" nzXl="1.5" nzXXl="1">
                                <div nz-row class="text-muted text-center">
                                    SOH
                                </div>
                                <div nz-row class="text-center">
                                    <span>{{ data.soh}}</span>
                                </div>
                            </div>
                            <div nz-col nzXs="5" nzSm="3" nzMd="2" nzLg="1.5" nzXl="1.5" nzXXl="1">
                                <div nz-row class="text-muted text-center">Price</div>
                                <div nz-row class="text-center">
                                    <span class="cartinput2">{{ data.price| number}}</span>
                                </div>
                            </div>
                            <div nz-col nzXs="10" nzSm="5" nzMd="3" nzLg="2" nzXl="2" nzXXl="2">
                                <div nz-row class="text-muted text-center">
                                    <span>Qty</span>
                                </div>
                                <!-- <div nz-row> -->
                                <nz-select *ngIf="data.id != 'idMOF'" (ngModelChange)="changeQuantity(data,$event)" [(ngModel)]="data.selectedIndex" style="width: 65px">
                                    <nz-option *ngFor='let qty of counter(data.soh);let i = index;' [nzLabel]="i+1" [nzValue]="i+1"></nz-option>
                                </nz-select>
                                <span *ngIf="data.id == 'idMOF'">
                                        1
                                    </span>
                                <!-- </div> -->
                            </div>
                            <div nz-col nzXs="5" nzSm="4" nzMd="4" nzLg="3" nzXl="3" nzXXl="2">
                                <div nz-row class="text-muted" nzJustify="center">Action</div>
                                <div nz-row class="text-muted" nzJustify="center">
                                    <button nz-button nzType="default" *ngIf="data.id != 'idMOF'" (click)="removeFromCart(data)">
                                        <span nz-icon nzType="delete" nzTheme="fill"></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <!-- <div nz-col nzXs="0" nzSm="0" nzMd="0" nzLg="0" nzXl="24" nzXXl="24" class="border-bottom"></div> -->
                    </div>
                    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" [nzXl]="{ span: 4, offset: 1 }" class="border-top border-bottom">
                        <div nz-row>
                            ORDER TOTAL
                            
                        </div>
                        <br>
                        <div nz-row nzJustify="space-between" nzAlign="middle">
                        <h6><b>R {{ this.cartTotal| number }}</b></h6>
                        <nz-radio-group *ngIf="showPickupOrDelivery" [(ngModel)]="this.settings.isDelivery" nzButtonStyle="solid" nzSize="large">
                            <label nz-radio-button nzValue={{0}}>Pick Up</label>
                            <label nz-radio-button nzValue={{1}}>Delivery</label>
                        </nz-radio-group>
                        </div>
                        <br>
                        <div nz-row nzJustify="center" nzAlign="middle">
                            <button nz-button nzType="primary" nzSize="large" (click)="checkOut()" *ngIf="checkout">
                                <span nz-icon nzType="shopping-cart"></span>
                                CHECKOUT
                            </button>
                            
                        </div>
                    </div>
                </div>
                <br>
                <div nz-row nzAlign="bottom" [nzGutter]="[16, 16]">
                    <div nz-col [nzLg]="{ span: 24, offset: 0 }">
                        <div class="form-group">
                            <div class="container">
                                <div nz-row>
                                    <div *ngIf="showExtref" nz-col nzXs="24" nzSm="24" nzMd="11" nzLg="7">
                                        <label class="float-left">Order Reference</label>
                                        <div class="input-group maindiv">
                                            <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="file" nzTheme="outline"></i></div>
                                            </span> -->
                                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                                <input nz-input [(ngModel)]="this.settings.Extref" placeholder="Required">
                                            </nz-input-group>
                                        </div>
                                    </div>
                                    <div *ngIf="showMilage" nz-col nzXs="24" nzSm="24" [nzMd]="{ span: 11, offset: 1 }" [nzLg]="{ span: 7, offset: 1 }">
                                        <label class="float-left">Milage</label>
                                        <div class="input-group maindiv">
                                            <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="file" nzTheme="outline"></i></div>
                                            </span> -->
                                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                                <input nz-input [(ngModel)]="this.settings.Milage" type="number" placeholder="Required">
                                            </nz-input-group>
                                        </div>
                                    </div>
                                    <div *ngIf="showMake" nz-col nzXs="24" nzSm="24" nzMd="11" [nzLg]="{ span: 7, offset: 1 }">
                                        <label class="float-left">Vehicle Make</label>
                                        <div class="input-group maindiv">
                                            <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="file" nzTheme="outline"></i></div>
                                            </span> -->
                                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                                <input nz-input [(ngModel)]="this.settings.Make" placeholder="Required">
                                            </nz-input-group>
                                        </div>
                                    </div>
                                    <div *ngIf="showVreg" nz-col nzXs="24" nzSm="24" [nzMd]="{ span: 11, offset: 1 }" [nzLg]="{ span: 7, offset: 0 }">
                                        <label class="float-left">Vehicle Registration</label>
                                        <div class="input-group maindiv">
                                            <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="file" nzTheme="outline"></i></div>
                                            </span> -->
                                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                                <input nz-input [(ngModel)]="this.settings.Vreg" placeholder="Required">
                                            </nz-input-group>
                                        </div>
                                    </div>
                                    <div *ngIf="showSalesman" nz-col nzXs="24" nzSm="24" nzMd="11" [nzLg]="{ span: 7, offset: 1 }">
                                        <label class="float-left">Salesman</label>
                                        <div class="input-group maindiv">
                                            <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="file" nzTheme="outline"></i></div>
                                            </span> -->
                                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                                <input nz-input [(ngModel)]="this.settings.Salesman" placeholder="Required">
                                            </nz-input-group>
                                        </div>
                                    </div>
                                    <div *ngIf="showDelivery" nz-col nzXs="24" nzSm="24" [nzMd]="{ span: 11, offset: 1 }" [nzLg]="{ span: 7, offset: 1 }">
                                        <label class="float-left">Delivery</label>
                                        <div class="input-group maindiv">
                                            <!-- <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="file" nzTheme="outline"></i></div>
                                            </span> -->
                                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                                <input nz-input [(ngModel)]="this.settings.Delivery" placeholder="Required">
                                            </nz-input-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showTxt">
                                <h6>Comment</h6>
                                <textarea nz-input [(ngModel)]="this.settings.Txt" placeholder="..." rows="1" style="max-width: 1050px;"></textarea><br>
                            </div>
                        </div>
                        <div>
                            <button nz-button nzType="default" nzSize="large" (click)="toggleShow()" style="margin: 5px;">
                                <!-- <span class="custom">&leftarrow;</span> -->
                                <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
                                <span>Shop</span>
                            </button>
                            <button nz-button nzType="default" nzSize="large" (click)="clearCart();clearQuote()" style="margin: 5px;">
                                <span nz-icon nzType="delete" nzTheme="fill"></span>
                                <span> Clear</span>
                             </button>
                        </div>
                    </div>
                </div>

            </nz-card>
        </div>
    </div>
</div>


<ng-template #addOnBeforeTemplate>
    <span nz-icon nzType="file-text" nzTheme="outline"></span>
</ng-template>



<!--Info Modals-->
<div id="info" class="modal fade" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body text-center" style="font-size:20px">
                <h3>{{ this.supplierInfo.name }}</h3>
                <div class="container">
                    <div nz-row>
                        <div class="col-lg-6">
                            <i nz-icon nzType="phone" nzTheme="outline" class="text"></i> {{ this.supplierInfo.cell }}
                        </div>
                        <div class="col-lg-6">
                            <span nz-icon nzType="sf:mailBulkSVG" nzTheme="outline" class="text"></span> {{ this.supplierInfo.email }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                </div>
            </div>
        </div>
    </div>
</div>

<nz-modal [nzWidth]="370" [(nzVisible)]="metaDisplay" nzClosable="true" [nzTitle]="modalTitle" (nzOnCancel)="metaDisplay = false;" [nzFooter]="null">
    <ng-template #modalTitle>
        <div nz-row>
            <div nz-col nzSpan="24">
                {{showingMeta.brand + ' ' + (showingMeta.meta.name | titlecase | slice:0:20) }}
            </div>
        </div>
        <div nz-row nzJustify="center">
            <div nz-col nzSpan="4">
                <small> 
                    RF : 
                    <span style="padding-left:5px">
                        <i *ngIf="!showingMeta.meta.runflat"  nz-icon nzType="close" nzTheme="outline" class="text-danger"></i>
                        <i *ngIf="showingMeta.meta.runflat" nz-icon nzType="check" nzTheme="outline"  style="color: var(--textcolor);"></i>
                    </span>
                </small>
            </div>
            <div nz-col nzSpan="5">
                <small> 
                    Speed : 
                    <span style="color: var(--textcolor);font-size: 14px;font-weight: 700;">{{showingMeta.meta.speed_rating}}</span>
                </small>
            </div>
            <div nz-col nzSpan="8">
                <small> 
                    Load : 
                    <span style="color: var(--textcolor);font-size: 14px;font-weight: 700;">{{showingMeta.meta.load_rating}}</span>
                </small>
            </div>
            <div nz-col nzSpan="7">
                <small> 
                    OE :  <span  style="color: var(--textcolor);font-size: 14px;font-weight: 700;">{{showingMeta.meta.oe}}</span>
                </small>
            </div>
        </div>
    </ng-template>
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="ismetaLoading">
            <nz-card style="width:320px" [nzCover]="coverTemplate">
                <p *ngIf="showingMeta.meta.description != '' && showingMeta.meta.description != null">
                    {{showingMeta.meta.description | slice:0:100}}
                </p>
                <p *ngIf="showingMeta.meta.description == ''">
                    <i>...</i>
                </p>
                <div nz-row>
                    <div nz-col *ngIf="showingMeta.meta.rating != 0 && showingMeta.meta.rating != null">
                        <b>Rating :  </b>
                        <nz-rate [ngModel]="showingMeta.meta.rating" [nzTooltips]="ratings" nzDisabled></nz-rate>
                    </div>
                    <div nz-col>
                        <div nz-row *ngIf="showingMeta.meta.dryPerformance > 0">
                            <div nz-col nzSpan="16">
                                <b>Dry Performance</b>
                            </div>
                            <div nz-col nzSpan="8">
                                <nz-progress [nzPercent]="showingMeta.meta.dryPerformance" [nzSteps]="5" nzStrokeColor="var(--textcolor)" [nzFormat]="formatOne"></nz-progress>
                            </div>
                        </div>
                        <div nz-row *ngIf="showingMeta.meta.handligPerformance > 0">
                            <div nz-col nzSpan="16">
                                <b>Handling</b>
                            </div>
                            <div nz-col nzSpan="8">
                                <nz-progress [nzPercent]="showingMeta.meta.handligPerformance" [nzSteps]="5" nzStrokeColor="var(--textcolor)" [nzFormat]="formatOne"></nz-progress>
                            </div>
                        </div>
                        <div nz-row *ngIf="showingMeta.meta.wetPerformance > 0">
                            <div nz-col nzSpan="16">
                                <b>Wet Performance </b>
                            </div>
                            <div nz-col nzSpan="8">
                                <nz-progress [nzPercent]="showingMeta.meta.wetPerformance" [nzSteps]="5" nzStrokeColor="var(--textcolor)" [nzFormat]="formatOne"></nz-progress>
                            </div>
                        </div>
                        <div nz-row *ngIf="showingMeta.meta.comfortPerformance > 0">
                            <div nz-col nzSpan="16">
                                <b> Comfort  </b>
                            </div>
                            <div nz-col nzSpan="8">
                                <nz-progress [nzPercent]="showingMeta.meta.comfortPerformance" [nzSteps]="5" nzStrokeColor="var(--textcolor)" [nzFormat]="formatOne"></nz-progress>
                            </div>
                        </div>
                        <div nz-row *ngIf="showingMeta.meta.treadPerformance > 0">
                            <div nz-col nzSpan="16">
                                <b>Treadwear</b>
                            </div>
                            <div nz-col nzSpan="8">
                                <nz-progress [nzPercent]="showingMeta.meta.treadPerformance" [nzSteps]="5" nzStrokeColor="var(--textcolor)" [nzFormat]="formatOne"></nz-progress>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-row [nzJustify]="'center'" *ngIf="showingMeta.meta.manufacturerLink != '' && showingMeta.meta.manufacturerLink != null">
                    <div nz-col>
                        <a [href]="showingMeta.meta.manufacturerLink" target="_blank">more</a>
                    </div>
                </div>
            </nz-card>
            <ng-template #coverTemplate>
                <img *ngIf="showingMeta.meta.imgBase64 != ''" height="350" style="text-align: center;" alt="Image Not Availabe" src="{{showingMeta.meta.imgBase64}}" (click)="onImageHover(showingMeta.meta.imgBase64)" />
            </ng-template>
        </nz-spin>
    </ng-container>
</nz-modal>

<!-- Confirm Cart Modal -->
<nz-modal>
    <nz-modal [(nzVisible)]="isConfirmModalVisible" [nzTitle]="modalCartTitle" nzWidth="1200px" [nzFooter]="modalFooter" (nzOnCancel)="isConfirmModalVisible = false">
        <ng-template #modalCartTitle>
            <div nz-row>
                <div nz-col nzSpan="2">
                    <span nz-icon nzType="warning" nzTheme="fill" class="color-warning" style="height: 100%; width: auto; font-size: 400% !important; aspect-ratio: 1;" nz></span>
                </div>
                <div nz-col nzSpan="22">
                    <h4>More than one supplier has been selected.</h4>
                    <h6>Please select the main supplier to add to your cart.</h6>
                </div>
            </div>
        </ng-template>
        <ng-container *nzModalContent>
            <nz-table #nestedTable [nzData]="filterTyres" [nzShowPagination]="false">
                <thead>
                    <tr>
                        <th>Supplier</th>
                        <th nzWidth="120px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-data [ngForOf]="nestedTable.data">
                        <tr>
                            <td [(nzExpand)]="data.expand">{{data.name}}</td>
                            <td>
                                <button nz-button nzType="primary" nzShape="circle" style="margin-right: 10px;" (click)="toggleShowCart(data.id)"><span nz-icon nzType="check" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></span></button>
                                <!-- <button nz-button nzType="primary" nzShape="circle" nzDanger><span nz-icon nzType="minus" nzTheme="outline" nz-tooltip nzTooltipTitle="Remove Item"></span></button> -->
                            </td>
                        </tr>
                        <tr [nzExpand]="data.expand">
                            <nz-table #innerTable [nzData]="filterFullCart(data.id)" nzSize="middle" [nzShowPagination]="false">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Tyre</th>
                                        <th>Code</th>
                                        <th>SLA</th>
                                        <th>Cost</th>
                                        <th>SOH</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of innerTable.data">
                                        <td><img class="brandImage" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.image}}'></td>
                                        <td>{{data.description}}</td>
                                        <td class="text-left">{{data.code}}</td>
                                        <td>{{data.sla}}</td>
                                        <td><span class="text-info-style">R </span>{{data.price|number }}</td>
                                        <td>{{data.soh}}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </tr>
                    </ng-template>
                </tbody>
            </nz-table>
        </ng-container>
        <ng-template #modalFooter>
            <button nz-button nzType="primary" (click)="isConfirmModalVisible = false">Close</button>
        </ng-template>
    </nz-modal>
</nz-modal>