import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Observable, Subscription, forkJoin, of, race } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthResult, LinkedAccount, User, UserClaim } from './models/user';
import { NotificationService } from './shared/notification.service';
import { ResetUser } from './models/resetUser';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User;
  token: string;
  loginSubscription = new EventEmitter<User>();
  private loggedIn = false;
  private apiCheckTimer;

  constructor(private http: HttpClient, private notification: NotificationService) { }

  public login(username: string, password: string) {
    /*************************
    This function is now deprecated. Please use the raceLogin function instead.
    **************************/
    /*OAuth2 requires the request to be application/x-www-form-urlencoded, not JSON*/
    let body = `username=${username}&password=${password}`;
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};

    return this.http.post<AuthResult>(environment.ApiUrl() + 'token', body, options)
      .pipe( /* tap to do something after a success */ 
        tap(res => {
          this.storeDetails(res);
          
          })
      );
  }

  public raceLogin(username: string, password: string) {
    /*OAuth2 requires the request to be application/x-www-form-urlencoded, not JSON*/
    let body = `username=${username}&password=${password}`;
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    const allCheckObservables = environment.apiEndpoints.filter(e => e.weight > 0).map(api => this.http.post<AuthResult>(environment.ApiUrl() + 'token', body, options));
    return race(...allCheckObservables) // Spread operator for race function
      .pipe(
        tap(result => {
          // Handle the result from the first observable to finish
          this.storeDetails(result);
          //we are not adding a catch here for all observables, because if you have an incorrect username/password, the catchError will execute.
        })
      )
  
  }

  public checkApiConnections() {
    const allCheckObservables = environment.apiEndpoints.filter(e => e.weight > 0).map(api => this.http.get<{isLbActive: boolean}>(api.url + 'lb_ping').pipe(
      tap(e => {
        api.isActive = e.isLbActive;        
      }),
      catchError(error => {
        api.isActive = false;
        if (environment.apiEndpoints.filter(e => e.isActive == true).length === 0) {
          this.notification.showWarning("It seems you are having trouble connecting to Stockfinder. Are you sure your internet connection is working?");
        }
        
        return of(error)}) // Catch errors to prevent early termination
    ));
    return forkJoin(allCheckObservables).pipe(
      take(1) // Take the first successful response
    );
  }

  public loadClaims() {
    return this.http.get<UserClaim[]>(environment.ApiUrl() + "v1/users/claims")
      .pipe(

        tap(res => {this.user.claims = res.map(e => e.code);
        })
      )
  }

  private storeDetails(authResult: AuthResult) 
  {
    localStorage.setItem("access_token", authResult.access_token);
    this.token = authResult.access_token;
    this.user = jwtDecode(authResult.access_token);
    this.loggedIn = true;
    this.loginSubscription.emit(this.user);
  }

  public getStoredLogin(): boolean {  
    let authToken = localStorage.getItem("access_token");
    if (authToken) {
      this.storeDetails({access_token: authToken, token_type: "bearer"})      
      this.loadClaims().subscribe(
        () => {          
        },
        error => {          
          this.notification.handleError(error, "Could not load your access. Please log out and back in.")
        }
      )
    }      
    return this.isLoggedIn();
  }

  public clearLogin() {
    this.loggedIn = false;
    this.user.claims = [];
  }

  isLoggedIn(): boolean {
    return this.user != undefined && this.loggedIn;
  }

  hasClaims(claims?: string[], showMessage: boolean = false): boolean | Observable<boolean> {    
    if (claims){
      if (!this.user.claims) {        
          return this.loadClaims().pipe(
            map(obj => {              
              this.user.claims = obj.map(e => e.code);
              const val: boolean = obj.some(r => claims.includes(r.code));
              if (!val && showMessage) this.notification.showWarning("Access denied.");
              return val;
            })
          )
      }
      else {
        const val = this.user.claims.some(r => claims.includes(r))

        if (!val && showMessage) this.notification.showWarning("Access denied.");
        return val;
      }
    }
    else {
      return true;
    } 
  }

  checkMenu(claims: string[]): boolean {    
    if (!this.user) return false;
    if (!this.user.claims) {
      return false;
    }
    else
    {
      return this.user.claims.some(r => claims.includes(r));
    }
  }

  forgotPassword(email: string, userId: number = 0) {
    return this.http.post<{message: string, Users : ResetUser[]}>(environment.ApiUrl() + `v1/utils/forgotpassword/${email}/${userId}`, null);
  }

  getLinkedAccounts() {
    return this.http.get<LinkedAccount[]>(environment.ApiUrl() + 'v1/users/linkedAccounts');
  }

  testApiWeights() {
    console.log("Hitting debug");
    console.clear();
    let subarr: {url: string, count: number}[] = []
    for (let index = 0; index < 100; index++) {
      const iter = environment.ApiUrl()
      let item = subarr.find(e => e.url === iter);
      if (item) {
        item.count += 1;
      } else {
        subarr.push({url: iter, count: 1});
      }
      
    }
    console.log("******** DONE **********");
    console.log(subarr);
      
      
  }
}

