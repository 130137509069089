<nz-modal [(nzVisible)]="adminMappingService.currentVisibility" nzTitle="Mapping" (nzOnOk)="handleClose()"
  [nzFooter]="footerTemplate" [nzWidth]="1200" nzCentered="true" nzCloseIcon="" nzClosable="true" nzMask="true" nzMaskClosable="true">
  
  <ng-container *nzModalContent>
    <div nz-row nzJustify="space-around">
        <div *ngIf="isMapping" class="spinner-container">
            <nz-spin nzSimple></nz-spin>
        </div>
        

        <div nz-col nzSpan="10" nz-flex nzJustify="start" nzAlign="center">
            <h3 class="text-center">Selected</h3>
            <div nz-col nzJustify="space-between"> <p>Description:</p> <h5>{{adminMappingService.unmappedStockItem.description}}</h5></div>
            <div nz-col nzJustify="space-between"> <p>Stock Code:</p> <h5>{{adminMappingService.unmappedStockItem.stockCode}}</h5></div>
        </div>
        
        <div nz-col nzSpan="10">
          <h3 class="text-center">Map</h3>
          <nz-tabset>
            <nz-tab nzTitle="Tyre" (nzSelect)="setTab('Tyre')">
              <nz-select 
                style="width: 100%;" 
                [(ngModel)]="selectedMap" 
                (ngModelChange)="setSelectedMap($event)" 
                nzPlaceHolder="Search for a stock item" 
                [nzShowArrow]="false"        
                nzShowSearch
                nzAllowClear="true"
                [nzNotFoundContent]="noStockResults"   
                [nzLoading]="isLoadingActiveStock"
              > 
                <nz-option *ngIf="isLoadingActiveStock" nzCustomContent>
                  <span nz-icon nzType="loading" class="loading-icon"></span>
                  Loading Data...
                </nz-option>
                <ng-container *ngIf="!isLoadingActiveStock">
                  <nz-option *ngFor="let item of activeStock"
                    [nzValue]="item" 
                    [nzLabel]="item.genCode+' '+item.brand+' '+item.description+' ('+item.stockCode+')'">
                  </nz-option>
                </ng-container>
              </nz-select>
        
              <ng-template #noStockResults style="text-align: center;">
                <div nz-row>
                  <div nz-col nzSpan="24" class="text-center">
                    <div style="text-align: center;" *ngIf="filteredStock.length === 0 && searchedTerm.length > 2; else continueTyping">
                      <span> No results found </span><br /><br />
                    </div>
                    <ng-template #continueTyping>
                      <div style="text-align: center;">
                        <span> Continue typing to search... </span><br /><br />
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            
        

                <nz-divider></nz-divider>
                <div nz-row nzJustify="space-around">
                  <button nz-button nzType="primary" (click)="selectedMap && mapTyreStock(adminMappingService.unmappedStockItem, selectedMap.msfid, 1, 5, false, true)">Map & Next</button>
                  <button nz-button nzType="primary" (click)="selectedMap && mapTyreStock(adminMappingService.unmappedStockItem, selectedMap.msfid, 1, 5, true, false)">Map & Close</button>
                </div> 
              </nz-tab>

              <nz-tab nzTitle="Custom Stock" (nzSelect)="setTab('custom')">
                <p>Custom Mappings for {{adminMappingService.currentClientName}}</p>
                <nz-select 
                  #customStockSelect
                  style="width: 100%;" 
                  [nzShowArrow]="false" 
                  nzPlaceHolder="Search for a custom stock item" 
                  nzShowSearch
                  [(ngModel)]="selectedCustomMap" 
                  (ngModelChange)="setSelectedMap($event)"
                  (nzOnSearch)="searchActiveCustomStock($event)"
                  [nzNotFoundContent]="noCustomStockResults"
                > 
                  <nz-option *ngIf="adminMappingService.isLoadingActiveCustomStock" nzCustomContent>
                    <span nz-icon nzType="loading" class="loading-icon"></span>
                    Loading Data...
                  </nz-option>
                  <ng-container *ngIf="!adminMappingService.isLoadingActiveCustomStock">
                  <nz-option *ngFor="let e of adminMappingService.activeCustomStock"
                    [nzValue]="e" 
                    [nzLabel]="e.stockType+' ' +e.description+' ('+e.stockCode+')'">
                  </nz-option>
                </ng-container>
                </nz-select>

                <ng-template #noCustomStockResults style="text-align: center;">
                  <div nz-row>
                    <div nz-col nzSpan="24" class="text-center">
                      <div style="text-align: center;" *ngIf="filteredStock.length === 0 && searchedTerm.length > 2; else continueTyping">
                        <span> No results found </span><br /><br />
                      </div>
                      <div style="margin: 10px; padding: 10px;">
                        <!-- Divider Above the Form -->
                        <nz-divider [nzText]="'Custom Stock Mapping'"></nz-divider>

                        
                          <!-- Stock Code Field -->
                          <div nz-row [nzGutter]="24" nzJustify="start" nzAlign="middle" class="formGutter">
                            <div nz-col nzSpan="24">
                              
                                  <nz-input-group nzAddOnBefore="Stock Code" >
                                    <input nz-input [(ngModel)]="customStockCode" placeholder="Stock Code" style="width: 100%; height: 40px;" />
                                  </nz-input-group>
                                
                            </div>
                          </div>

                          <!-- Stock Type Field -->
                          <div nz-row [nzGutter]="24" nzJustify="start" nzAlign="middle" class="formGutter">
                            <div nz-col nzSpan="24">
                              
                                  <nz-input-group nzAddOnBefore="Stock Type">
                                    <nz-select style="width: 100%;" nzPlaceHolder="Type" nzShowSearch [(ngModel)]="customStockType">
                                      <nz-option *ngFor="let type of this.customStockTypes" [nzValue]="type" [nzLabel]="type.name"></nz-option>
                                    </nz-select>
                                  </nz-input-group>
                                
                            </div>
                          </div>

                          <!-- Description Field -->
                          <div nz-row [nzGutter]="24" nzJustify="start" nzAlign="middle" class="formGutter">
                            <div nz-col nzSpan="24">
                              
                                  <nz-input-group nzAddOnBefore="Description">
                                    <input nz-input [(ngModel)]="customDescription" placeholder="Description" style="width: 100%; height: 40px;" />
                                  </nz-input-group>
                                
                            </div>
                          </div>

                          <!-- Add Item Button -->
                          <div nz-row [nzGutter]="24" nzJustify="start" nzAlign="middle" style="margin-bottom: 16px;">
                            <div nz-col nzSpan="24">
                              
                                <button nz-button nzType="primary"  style="width: 100%; height: 40px;" (click)="addCustomStockMapping(customStockCode, customDescription, customStockType, adminMappingService.unmappedStockItem)">
                                  Add New Custom Stock Item
                                </button>
                              
                            </div>
                          </div>
                        
                      </div>

                      <ng-template #continueTyping>
                        <div style="text-align: center;">
                          <span> Continue typing to search... </span><br /><br />
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>

                <nz-divider></nz-divider>
                <div nz-row nzJustify="space-around">
                  <button nz-button nzType="primary" (click)="selectedCustomMap && mapCustomStock(adminMappingService.unmappedStockItem, selectedCustomMap.msfid, 1, 5, false, true)">Map & Next</button>
                  <button nz-button nzType="primary" (click)="selectedCustomMap && mapCustomStock(adminMappingService.unmappedStockItem, selectedCustomMap.msfid, 1, 5, true, false)">Map & Close</button>
                </div> 
              </nz-tab>
            </nz-tabset>
        </div>
    </div>
  </ng-container>

  <ng-template #footerTemplate>
    <button nz-button nzType="primary" (click)="handleClose()">Close</button>
  </ng-template>
</nz-modal>
