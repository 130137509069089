import { Component,HostListener, OnInit} from '@angular/core';
import { ModelStock, ModelStockSizes, ModelStock_def, Model_Suggestions, stockCost, supplierInfo, supplierInvoices, supplier_summary, ModelStockTyres, wipLine,SixMonthSalesTrend,rfc_summary,surplusStock,MonthSalesTrendValue, ModelStockCollection, Order_summary } from '../models/stock';
import { StockModelsService_Replenishment } from './stock-models.service';
import { NotificationService } from '../shared/notification.service';
import { AuthService } from '../auth.service';
import { Router} from '@angular/router';
import * as XLSX from 'xlsx';
import { LeadsService } from '../leads/leads.service';
import { WIPLead } from '../models/leads';
import { StockModelsService } from '../model-stock-analysis/model-stock-analysis.service';
import { ThemeService } from '../theme.service';
import { formatDate } from '@angular/common';
import { delay } from 'rxjs/operators';


// import { ExcelDataType } from 'xlsx';
// import { link } from 'fs';



declare var require: any
const FileSaver = require('file-saver');



@Component({
  selector: 'app-stock-models',
  templateUrl: './stock-models.component.html',
  styleUrls: ['./stock-models.component.less']
})
export class StockModelsComponent implements OnInit {
  [x: string]: any;
  isLoading = false;
  rfcLoading = false;
  baseModel : ModelStock[] =[]
  baseBackup : ModelStock[] = []
  itemsOutOfStockAtSupplier : ModelStock[] = []
  itemsHolding: ModelStock[] = []
  itemsDisabled: ModelStock[] = []
  replenishableItems: ModelStock[] = []
  all_models: ModelStock[] = []
  attentionHeadOffice:ModelStock[] = [];


  all_models_backup: ModelStock[] = []
  replenishables_backup: ModelStock[] = []
  IOS_backup: ModelStock[] = []
  itemsHolding_backup: ModelStock[] = []
  itemsDisabled_backup: ModelStock[] = []
  attentionHeadOffice_backup:ModelStock[] = [];

  TableData: ModelStock[] = []

  stockList : ModelStock[] = []
  stockListBackup : ModelStock[] = []
  suppliers : supplierInfo[] = []
  suppliersTemp: supplierInfo[] = []
  searchModel: ModelStock[] = []
  queryStock: ModelStock[] = []
  Invoices: supplierInvoices[];
  chosenAddition: ModelStock
  stockBalance: stockCost[] = []
  stockBalance_consumables: stockCost[] = []
  stockBalance_partials: stockCost[] = []
  stockBalance_add: stockCost[] = []
  stockBalance_holding: stockCost[] = []
  stockBalance_disabled: stockCost[] = []
  Model_Stock: ModelStock_def
  Model_Size_Suggestions: Model_Suggestions[] = []
  Model_Size_Suggestions_Temp: Model_Suggestions[] = []
  stockItem: stockCost
  model_size_backup: ModelStockSizes[] = []

  ModelTyres: ModelStockTyres[] = [];
  ModelTyres_Backup: ModelStockTyres[] = [];

  daysofdata: number = 61;

  

  supplierNames: string[] = []

  currRow:number;
  addRow: number;
  ClickedRow: any;
  HighlightRow : Number;
  sort:number;
  search: string
  search_genCode: string
  searched: number;
  stockTotal: number = 0;
  currTable= 0;
  captionSet = 0;
  currP2_Table = 0;
  

 
  addModel = false;
  inputStock = false;
  filters = false;
  liveUpdate = false;
  updateTime: Date;
  altStock = false;

  tempSizes: ModelStockSizes[] = [];
  baseSizes: ModelStockSizes[] = [];
  actionView = false;

  


  summaryTitle: string ='';
  page: number = 1;
  workInProgress_Display:boolean;
  topCtrls: boolean = true;
  RFC_SUMMARY: rfc_summary[] = [];
  OVER_MSL: surplusStock[] = [];
  NON_MSL: surplusStock[] = [];

  lastStockLoad: string = '';

  ModelStockInformation: ModelStockCollection;
  BulkOrders: Order_summary[] = [];

  
  

  constructor(
    private StockModelsService: StockModelsService_Replenishment,
    private leadsService: LeadsService,
    private notification: NotificationService,private router: Router,
    private authService: AuthService,
    private ModelStockAnalysisService:StockModelsService,
    public themeService: ThemeService) {}

  
pageSize = 50;
@HostListener("window:scroll", [])
  onScroll(): void {
  if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
          // you're at the bottom of the page
          this.pageSize += 10;
          // this.input.inputValue = this.input.inputValue;
      }
  }
  resetTable(itemSize){
      this.pageSize = 50;
  }
  setTabletoContent(arrSize){
    this.pageSize = arrSize;
  }
  
  brandsGathered: string[] = []
  ngOnInit(): void {
      this.isLoading = true;
      // this.searched = 0;
      // this.workInProgress_Display = false;
      this.StockModelsService.getModelStockCollection().subscribe((res)=>{
        this.ModelStockInformation = res;
        this.BulkOrders = this.ModelStockInformation.BulkOrders.slice()
        this.itemsHolding = this.ModelStockInformation.holdingItems.slice()
        this.suppliers = this.ModelStockInformation.suppliers.slice()
        this.suppliersTemp = this.suppliers.slice();
        let holdingCnt = 0;
        for(let x = 0; x < this.itemsHolding.length;x++)
        {
          if(this.itemsHolding[x].holding_status ==1 && this.itemsHolding[x].sohSupplier >0){
            this.holdItem(this.itemsHolding[x].modelId);
            holdingCnt += 1;
          }
        }
        if(holdingCnt > 0){
          this.ngOnInit();
        }
        this.isLoading = false;
      },err=>{

      })
      
  }

replenishSet = false;
setReplenishables(){
  if(this.replenishSet == false){
    this.replenishableItems = this.ModelStockInformation.replenishableItems.slice();
    this.replenishables_backup = this.ModelStockInformation.replenishableItems.slice();
    this.replenishSet = true;
  }

  for(let n =0 ;n < this.replenishableItems.length; n++){
    let brandmatch = false;
    for(let m =0; m < this.brandsGathered.length;m++){
      if(this.brandsGathered[m] == this.replenishableItems[n].brand){brandmatch = true; break}
    }
    if(brandmatch== false){
      this.brandsGathered.push(this.replenishableItems[n].brand)
    }
  }
  let sortedSummary = this.brandsGathered.sort((a, b) => (a < b) ? -1 : 1);
  this.brandsGathered = sortedSummary;
}

iosSet = false;
setIOS(){
  if(this.iosSet == false){
    this.itemsOutOfStockAtSupplier = this.ModelStockInformation.itemsOutOfStockAtSupplier.slice();
    this.items_OSBackup = this.ModelStockInformation.itemsOutOfStockAtSupplier.slice();
    let sortedSummary = this.brandsGathered.sort((a, b) => (a < b) ? -1 : 1);
    this.brandsGathered = sortedSummary;
    this.iosSet = true;
  }

  for(let n =0 ;n < this.itemsOutOfStockAtSupplier.length; n++){
    let brandmatch = false;
    for(let m =0; m < this.brandsGathered.length;m++){
      if(this.brandsGathered[m] == this.itemsOutOfStockAtSupplier[n].brand){brandmatch = true; break}
    }
    if(brandmatch== false){
      this.brandsGathered.push(this.itemsOutOfStockAtSupplier[n].brand)
    }
  }
  let sortedSummary = this.brandsGathered.sort((a, b) => (a < b) ? -1 : 1);
  this.brandsGathered = sortedSummary;

}

holdingSet = false;
setHolding(){
  if(this.holdingSet == false){
    this.itemsHolding = this.ModelStockInformation.holdingItems.slice();
    this.itemsHolding_backup = this.ModelStockInformation.holdingItems.slice();
    this.holdingSet = true;
  }
  for(let n =0 ;n < this.itemsHolding.length; n++){
    let brandmatch = false;
    for(let m =0; m < this.brandsGathered.length;m++){
      if(this.brandsGathered[m] == this.itemsHolding[n].brand){brandmatch = true; break}
    }
    if(brandmatch== false){
      this.brandsGathered.push(this.itemsHolding[n].brand)
    }
  }
  let sortedSummary = this.brandsGathered.sort((a, b) => (a < b) ? -1 : 1);
  this.brandsGathered = sortedSummary;
}

disabledSet = false;
setDisabled(){
  if(this.disabledSet == false){
    this.itemsDisabled = this.ModelStockInformation.disabledItems.slice();
    this.itemsDisabled_backup = this.ModelStockInformation.disabledItems.slice();
    this.disabledSet = true;
  }
  for(let n =0 ;n < this.itemsDisabled.length; n++){
    let brandmatch = false;
    for(let m =0; m < this.brandsGathered.length;m++){
      if(this.brandsGathered[m] == this.itemsDisabled[n].brand){brandmatch = true; break}
    }
    if(brandmatch== false){
      this.brandsGathered.push(this.itemsDisabled[n].brand)
    }
  }
  let sortedSummary = this.brandsGathered.sort((a, b) => (a < b) ? -1 : 1);
  this.brandsGathered = sortedSummary;
}

escalationSet = false;
setEscalation(){
  if(this.escalationSet == false){
    this.attentionHeadOffice = this.ModelStockInformation.attentionHeadOffice.slice();
    this.attentionHeadOffice_backup = this.ModelStockInformation.attentionHeadOffice.slice();
    this.escalationSet = true;
  }
}

allSet = false;
setAll(){
  if(this.allSet == false){
    this.all_models = this.ModelStockInformation.all_items.slice();
    this.all_models_backup = this.ModelStockInformation.all_items.slice();
    this.brandsGathered = [];
    this.allSet = true;
  }

  for(let n =0 ;n < this.all_models.length; n++){
    let brandmatch = false;
    for(let m =0; m < this.brandsGathered.length;m++){
      if(this.brandsGathered[m] == this.all_models[n].brand){brandmatch = true; break}
    }
    if(brandmatch== false){
      this.brandsGathered.push(this.all_models[n].brand)
    }
  }
  let sortedSummary = this.brandsGathered.sort((a, b) => (a < b) ? -1 : 1);
  this.brandsGathered = sortedSummary;
}

  size_inquiry:ModelStockTyres[]= [];
  AltModelTyres:ModelStockTyres[]= [];
  AllModelTyres:ModelStockTyres[]= [];
  potentialHold: number[] = [];
  altItems:number = 0
  isLoading_ALT = false;
  
  queriedSupplierCode = ''
  inquire_on_size(item: ModelStock){
    this.queriedSupplierCode = item.code+item.supplier_name;
    this.isLoading = true;
    this.potentialHold = [];
    this.altItem = item.description;
    this.altItemId = item.modelId;
    if(item.gen_code != null){
      this.ModelStockAnalysisService.modelStockTyreSuggestions(item.gen_code,120,"None").subscribe((res:any) =>{
        
        this.size_inquiry = [];
        let code_supplierCombos:string[] = [];
        let inquiryResult = res.All_Suppliers as ModelStockTyres[];
        let noSales = true;
        for(let n = 0; n < inquiryResult.length; n++){
          let code_supplierCombo:string = (inquiryResult[n].stock_code+inquiryResult[n].best_supplier).toString();
          let match = false;
          if(inquiryResult[n].sales > 0){noSales = false;}
          for(let m = 0; m < code_supplierCombos.length;m++){
            if(code_supplierCombos[m] == code_supplierCombo){
              match = true;
              break
            }
          }
          if(match == false){
            this.size_inquiry.push(inquiryResult[n])
            this.size_inquiry = this.size_inquiry.slice();
            code_supplierCombos.push(code_supplierCombo);
            code_supplierCombo = code_supplierCombo.slice();
          }
          
        }

        if(noSales == false){
          let sortedCompany = this.size_inquiry.sort((a, b) => (a.sales > b.sales) ? -1 : 1);
          this.size_inquiry = sortedCompany;
          this.size_inquiry = this.size_inquiry.slice();
        }else{
          let sortedCompany = this.size_inquiry.sort((a, b) => (a.national_rank > b.national_rank) ? -1 : 1);
          this.size_inquiry = sortedCompany;
          this.size_inquiry = this.size_inquiry.slice();
        }

        this.altStock = true;
        this.isLoading = false;
      
        
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      }
      )
    }else{
      this.notification.showInfo("Currently unavailable for custom stock");
      this.isLoading = false;
    }
    
  }

  jobs: WIPLead[] = [];
  quoteIds: number[] = [];
  wipLines: wipLine[] = []
  wip_visible: boolean =  false;
  work_in_progress()
  {
    this.quoteIds = [];
    this.wipLines = [];
    if(this.workInProgress_Display == true){
      this.workInProgress_Display = false;
    }
    else if(this.workInProgress_Display == false){
      this.workInProgress_Display = true;
    }
    this.leadsService.getWorkInProgress().subscribe(
      val => {
        this.isLoading=  true;
        this.jobs = val;
        if(this.jobs.length > 0)
        {
          for(let n =0; n < this.jobs.length;n++){
            this.quoteIds.push(this.jobs[n].quoteId)
          }
          this.StockModelsService.apply_work_in_progress(this.quoteIds).subscribe((res) =>
          {
            this.wipLines =  res;
            for(let n =0; n < this.wipLines.length;n++){
              for(let m =0;m < this.baseModel.length;m++){
                if(this.baseModel[m].msfId == this.wipLines[n].msfid){
                  if(this.workInProgress_Display == true){
                    this.baseModel[m].work_in_progress += this.wipLines[n].qty;
                    this.baseModel[m].diff -=  this.wipLines[n].qty;
                  }
                  if(this.workInProgress_Display == false){
                    this.baseModel[m].work_in_progress = 0;
                    this.baseModel[m].diff += this.wipLines[n].qty;
                  }
                  for(let z =0; z < this.baseBackup.length;z++){
                    if(this.baseBackup[z].code == this.baseModel[m].code){
                      this.baseBackup[z].work_in_progress = this.baseModel[m].work_in_progress;
                      this.baseBackup[z].diff = this.baseModel[m].diff;
                    }
                  }
                }
              }
            }
            let sortedCompany = this.baseModel.sort((a, b) => (a.work_in_progress > b.work_in_progress) ? -1 : 1);
            this.baseModel = sortedCompany;
            this.baseModel = this.baseModel.slice();
           
          },err=>{
            this.notification.handleError(err);
            this.isLoading = false;  
          });
          this.isLoading = false;
        }else{
          this.notification.showInfo("There are no work in progress items");
          this.isLoading = false;
        }
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      }
    )
    
    

  }
  
  dodChange()
  {
    let dod = (document.getElementById("daysOfData") as HTMLInputElement).value;
    var dodInt: number = +dod;
    this.daysofdata = dodInt;

    this.StockModelsService.modelStock(this.daysofdata,"None","SOH").subscribe(res =>{
      this.isLoading = true;
      this.Model_Stock = res as ModelStock_def;
      this.isLoading = false;
      
    },err=>{
      this.notification.handleError(err);
      this.isLoading = false;
    });
    this.ngOnInit();
    this.page = 2;
  }

  len: number = 0;
  
  codeInputChange(e: string){
    this.searchModel = []
    this.len = e.length;
    
    
    if(this.currTable == 1)
    { 
      for(let x = 0; x < this.replenishables_backup.length; x++)
      {
        if(this.replenishables_backup[x].code.includes(e.toUpperCase()) || this.replenishables_backup[x].description.replace('/','').replace('R','').replace(' ','').includes(e.toUpperCase().replace('/','').replace('R','').replace(' ','')) || this.replenishables_backup[x].description.includes(e.toUpperCase()))
        {
          this.searchModel.push(this.replenishables_backup[x]);
        }
      }
      this.replenishableItems = this.searchModel;

    }

    if(this.currTable == 2)
    { 
      for(let x = 0; x < this.items_OSBackup.length; x++)
      {
        if(this.items_OSBackup[x].code.includes(e.toUpperCase()) || this.items_OSBackup[x].description.replace('/','').replace('R','').replace(' ','').includes(e.toUpperCase().replace('/','').replace('R','').replace(' ','')) || this.items_OSBackup[x].description.includes(e.toUpperCase()))
        {
          this.searchModel.push(this.items_OSBackup[x]);
        }
      }
      this.itemsOutOfStockAtSupplier = this.searchModel;

    }

    if(this.currTable == 3)
    { 
      for(let x = 0; x < this.itemsHolding_backup.length; x++)
      {
        if(this.itemsHolding_backup[x].code.includes(e.toUpperCase()) || this.itemsHolding_backup[x].description.replace('/','').replace('R','').replace(' ','').includes(e.toUpperCase().replace('/','').replace('R','').replace(' ','')) || this.itemsHolding_backup[x].description.includes(e.toUpperCase()))
        {
          this.searchModel.push(this.itemsHolding_backup[x]);
        }
      }
      this.itemsHolding = this.searchModel;

    }

    if(this.currTable == 4)
    { 
      for(let x = 0; x < this.itemsDisabled_backup.length; x++)
      {
        if(this.itemsDisabled_backup[x].code.includes(e.toUpperCase()) || this.itemsDisabled_backup[x].description.replace('/','').replace('R','').replace(' ','').includes(e.toUpperCase().replace('/','').replace('R','').replace(' ','')) || this.itemsDisabled_backup[x].description.includes(e.toUpperCase()))
        {
          this.searchModel.push(this.itemsDisabled_backup[x]);
        }
      }
      this.itemsDisabled = this.searchModel;

    }
    if(this.currTable == 5)
    { 
      for(let x = 0; x < this.all_models_backup.length; x++)
      {
        if(this.all_models_backup[x].code.includes(e.toUpperCase()) || this.all_models_backup[x].description.replace('/','').replace('R','').replace(' ','').includes(e.toUpperCase().replace('/','').replace('R','').replace(' ','')) || this.all_models_backup[x].description.includes(e.toUpperCase()))
        {
          this.searchModel.push(this.all_models_backup[x]);
        }
      }
      this.all_models = this.searchModel;
    }
  }

  genSearch: ModelStockSizes[] = []
  genSearch_2: Model_Suggestions[] =[]
 

  addStockModel()
  {
    this.addModel = true;

  }

  cancelModel()
  {
    this.addModel = false;
  }

  // It says delete but its's disable------------------
  deleteModel(modelId: number): void{
        this.StockModelsService.deleteModel(modelId).subscribe(
        res =>
        {
          this.isLoading = true;
          this.notification.showSuccess("item status changed");
          for(let x=0; x < this.baseModel.length;x++){
            if(this.baseModel[x].modelId == modelId){
              if(this.baseModel[x].holding_status ==2){this.baseModel[x].holding_status = 0; break;}
              if(this.baseModel[x].holding_status !=2){this.baseModel[x].holding_status = 2; break;}
              // this.baseModel.splice(x,1);
              this.baseModel = this.baseModel.slice();
              // this.baseBackup = this.baseModel;
            }
          }

          

      
          if(this.currTable == 1){
            for(let n =0; n < this.replenishableItems.length; n++)
            {
              if(this.replenishableItems[n].modelId == modelId){
                this.replenishableItems[n].holding_status = 2;
                if(this.disabledSet == false){this.setDisabled();}

                this.itemsDisabled.push(this.replenishableItems[n]);
                this.itemsDisabled = this.itemsDisabled.slice();
                this.itemsDisabled_backup = this.itemsDisabled.slice();

                this.replenishableItems.splice(n,1);
                this.replenishableItems = this.replenishableItems.slice();
                this.replenishables_backup = this.replenishableItems.slice();
              
              }
            }
          }
          
          if(this.currTable == 2){
            for(let n =0; n < this.itemsOutOfStockAtSupplier.length; n++)
            {
              if(this.itemsOutOfStockAtSupplier[n].modelId == modelId){
                this.itemsOutOfStockAtSupplier[n].holding_status = 2;
                if(this.disabledSet == false){this.setDisabled();}

                this.itemsDisabled.push(this.itemsOutOfStockAtSupplier[n]);
                this.itemsDisabled = this.itemsDisabled.slice();
                this.itemsDisabled_backup = this.itemsDisabled.slice();

                this.itemsOutOfStockAtSupplier.splice(n,1);
                this.itemsOutOfStockAtSupplier = this.itemsOutOfStockAtSupplier.slice();
                this.IOS_backup = this.itemsOutOfStockAtSupplier.slice();
              
              }
            }
          }

         
          this.actionView = false;
          this.altStock = false;
          this.isLoading = false;
        },
        error => {
          this.notification.handleError(error);
          this.isLoading = false;
        })

        
  }

  editMode = false;
  editId:number = 0;
  editModel(item:ModelStock): void{
    this.isLoading = true;
    this.editMode = true;
    this.editId = item.modelId;
    this.isLoading = false;
    

  }

  item_to_change: ModelStockTyres
  editType: string = ""
  editItem(item:ModelStockTyres,editT: string){
    this.item_to_change = item;
    this.editType = editT;
    for(let n = 0; n < this.ModelStockInformation.all_items.length;n++){
      if(item.msfid == this.ModelStockInformation.all_items[n].msfId){
        this.editType = 'E'; this.item_to_change.stockReplenishmentId = this.ModelStockInformation.all_items[n].modelId;
        break;
      }
    }
    if(this.potentialHold.includes(item.stockReplenishmentId)){
      this.editType = "H";
    }
    this.actionView = true;
  }

  cancel(): void{
    this.editMode = false;
    this.editId = 0;

  }

  chooseSupplier(supplier: supplierInfo, page:number): void{
    if(page ==0)
    {
      (document.getElementById("supllierInput") as HTMLInputElement).value = supplier.supplier_name;
    }

    else if(page ==1)
    {
      (document.getElementById("new_supplier") as HTMLInputElement).value = supplier.supplier_name;
      this.chosenAddition.supplierId = supplier.supplierId;
    }
    else if(page ==2)
    {
      (document.getElementById("edit_supplier") as HTMLInputElement).value = supplier.supplier_name;
      this.chosenAddition.supplierId = supplier.supplierId;
    }
    else{
      (document.getElementById("new_supplier") as HTMLInputElement).value = supplier.supplier_name;
      this.suggestedAddition.supplierId = supplier.supplierId;
    }

  }

  // saveAltStock(item:ModelStock)
  // {
  //   this.StockModelsService.updateModel(item).subscribe(
  //     res =>
  //     {
  //       item.replenish_active = true;
  //       this.notification.showSuccess("successfully updated "+item.description);
  //       let idx = this.altStockList.indexOf(item);
  //       this.altStockList.splice(idx,1);
  //       this.altStockList = this.altStockList.slice();
  //       this.baseModel.push(item);
  //       this.baseBackup = this.baseModel;
  //       this.baseModel = this.baseModel.slice();
  //       this.baseBackup = this.baseBackup.slice();
        
  //       // this.ngOnInit();
  //     },
  //     error => {
  //       this.notification.handleError(error);
  //     })
  // }


  saveMSLChange(item:ModelStock){
    let msl = (document.getElementById("editPOI") as HTMLInputElement).value;
    var mslInt: number = +msl;
    

    let mod = new ModelStockTyres()
    mod.stockReplenishmentId = item.modelId;
    mod.modelStock = mslInt;
    mod.best_supplier = item.supplier_name;
    mod.description = item.description;

    this.StockModelsService.updateModel(mod).subscribe(
      (res:any) =>
      {
        this.notification.showSuccess("successfully updated "+item.description);
        for(let x= 0; x < this.baseModel.length;x++){
          if(this.baseModel[x].modelId == item.modelId){
            this.baseModel[x].msl = mslInt;
          }
        }
        this.editId = 0;
        this.editMode = false;
         
      },
      error => {
        this.notification.showWarning(item.description+' could not be updated, please refresh the browser and try again');
      })
   
   

  }
  saveChanges(item: ModelStockTyres): void{
    let supplier = (document.getElementById("edit_supplier") as HTMLInputElement).value;
    let msl = (document.getElementById("edit_msl") as HTMLInputElement).value;
    var mslInt: number = +msl;
    
    item.modelStock = mslInt;
    item.best_supplier = supplier;
    // var suppString = item.supplier_soh.toString()
    // var supplier_soh: number = +item.supplier_soh;
    var supplier_soh: number = +item.supplier_soh.toString().replace(/[^\w\s]/gi, '')
    item.supplier_soh = supplier_soh
   
    this.StockModelsService.updateModel(item).subscribe(
      res =>
      {
         this.notification.showSuccess("successfully updated "+item.description);
         this.actionView = false;
        //  this.altStock = false;
        
         for(let x = 0; x < this.size_inquiry.length;x++){
          if(this.size_inquiry[x].stockReplenishmentId == item.stockReplenishmentId){
            this.size_inquiry[x].modelStock = mslInt;
            this.size_inquiry[x].listed_supplier = supplier;  
          }
         }
         this.size_inquiry = this.size_inquiry.slice();
         //  this.ngOnInit();
         
      },
      error => {
        this.notification.showWarning(item.description+' could not be updated, please refresh the page and try again');
      })
    
    this.editMode = false;
    this.editId = 0;

  
  }

  // genSort(model:any, col:string)
  // {
  //   if(this.sort == 0){
  //     let sortedCompany = this.baseModel.sort((a, b) => (a.col < b.col) ? -1 : 1);
  //     this.baseModel = sortedCompany;
  //     this.sort = 1;
  //     this.model = this.model.slice();
  //   }

  //   else{
  //     let sortedCompany = this.model.sort((a, b) => (a.col > b.col) ? -1 : 1);
  //     this.model = sortedCompany;
  //     this.sort = 0;
  //     this.model = this.model.slice();
  //   }
  // }

  sortT(sort:string)
  {
    if(this.sort == 0){
      let sortedCompany = this.baseModel.sort((a, b) => (sort) ? -1 : 1);
      this.baseModel = sortedCompany;
      this.sort = 1;
      this.baseModel = this.baseModel.slice();
    }

    else{
      let sortedCompany = this.baseModel.sort((a, b) => (sort) ? -1 : 1);
      this.baseModel = sortedCompany;
      this.sort = 0;
      this.baseModel = this.baseModel.slice();
    }
  }

  clearBrandFilter(){
    if(this.currTable == 1){
      this.replenishableItems = [];
      this.replenishableItems = this.replenishables_backup.slice();
      this.filteredBrand_Rep = 'Brands';
    }
    if(this.currTable == 2){
      this.itemsOutOfStockAtSupplier = [];
      this.itemsOutOfStockAtSupplier = this.items_OSBackup.slice();
      this.filteredBrand_IOS = 'Brands';
    }
    if(this.currTable == 3){
      this.itemsHolding = [];
      this.itemsHolding = this.itemsHolding_backup.slice();
      this.filteredBrand_H = 'Brands';
    }
    if(this.currTable == 4){
      this.itemsDisabled = [];
      this.itemsDisabled = this.itemsDisabled_backup.slice();
      this.filteredBrand_D = 'Brands';
    }
    if(this.currTable == 5){
      this.all_models = [];
      this.all_models = this.all_models_backup.slice();
      this.filteredBrand_all = 'Brands';
    }

  }
  filteredBrand_all:string = 'Brands';
  filteredBrand_IOS:string = 'Brands';
  filteredBrand_Rep:string = 'Brands';
  filteredBrand_H:string = 'Brands';
  filteredBrand_D:string = 'Brands';
  filterReason($event)
  {
    if(this.currTable == 1){
      this.filteredBrand_Rep = $event;
      this.replenishableItems = [];
      for(let n =0; n < this.replenishables_backup.length; n ++){
        if(this.replenishables_backup[n].brand == $event){
          this.replenishableItems.push(this.replenishables_backup[n]);
        }
      }
      this.replenishableItems = this.replenishableItems.slice();
    }
    if(this.currTable == 2){
      this.filteredBrand_IOS = $event;
      this.itemsOutOfStockAtSupplier = [];
      for(let n =0; n < this.items_OSBackup.length; n ++){
        if(this.items_OSBackup[n].brand == $event){
          this.itemsOutOfStockAtSupplier.push(this.items_OSBackup[n]);
        }
      }
      this.itemsOutOfStockAtSupplier = this.itemsOutOfStockAtSupplier.slice();
    }
    if(this.currTable == 3){
      this.filteredBrand_H = $event;
      this.itemsHolding = [];
      for(let n =0; n < this.itemsHolding_backup.length; n ++){
        if(this.itemsHolding_backup[n].brand == $event){
          this.itemsHolding.push(this.itemsHolding_backup[n]);
        }
      }
      this.itemsHolding = this.itemsHolding.slice();
    }
    if(this.currTable == 4){
      this.filteredBrand_D = $event;
      this.itemsDisabled = [];
      for(let n =0; n < this.itemsDisabled_backup.length; n ++){
        if(this.itemsDisabled_backup[n].brand == $event){
          this.itemsDisabled.push(this.itemsDisabled_backup[n]);
        }
      }
      this.itemsDisabled = this.itemsDisabled.slice();
    }
    if(this.currTable == 5){
      this.filteredBrand_all = $event;
      this.all_models = [];
      for(let n =0; n < this.all_models_backup.length; n ++){
        if(this.all_models_backup[n].brand == $event){
          this.all_models.push(this.all_models_backup[n]);
        }
      }
      this.all_models = this.all_models.slice();
    }
  }
  
  sortSupplier = (a: ModelStock, b: ModelStock) => a.supplier_name.localeCompare(b.supplier_name);
  sortBrand = (a: ModelStock, b: ModelStock) => a.brand.localeCompare(b.brand);
  sortCode = (a: ModelStock, b: ModelStock) => a.code.localeCompare(b.code);
  sortDescription = (a: ModelStock, b: ModelStock) => a.description.localeCompare(b.description);
  sortMSL = (a: ModelStock, b: ModelStock) => a.msl - b.msl;
  sortSOH = (a: ModelStock, b: ModelStock) => a.soh - b.soh;
  sortWIP = (a: ModelStock, b: ModelStock) => a.work_in_progress - b.work_in_progress;
  sortDiff = (a: ModelStock, b: ModelStock) => a.diff - b.diff;
  sortS_SOH = (a: ModelStock, b: ModelStock) => a.sohSupplier - b.sohSupplier;
  sortS_Price = (a: ModelStock, b: ModelStock) => a.supplierPrice - b.supplierPrice;
  sortS_Sales = (a: ModelStock, b: ModelStock) => a.sales - b.sales;


  sortBrandAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.brand.localeCompare(b.brand);
  sortDescriptionAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.brand.localeCompare(b.brand);
  sortCodeAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.stock_code.localeCompare(b.stock_code);
  sortSoldAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.sales -b.sales;
  sortMSLAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.modelStock -b.modelStock;
  sortAIMSLAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.suggested_modelStock -b.suggested_modelStock;
  sortNRAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.national_rank -b.national_rank;
  sortCostAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.best_cost -b.best_cost;
  sortSuppAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.best_supplier.localeCompare(b.best_supplier);
  sortS_SOHAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.supplier_soh -b.supplier_soh;
  sortSOHAlt = (a: ModelStockTyres, b: ModelStockTyres) => a.soh -b.soh;

  sortDescSC = (a: stockCost, b: stockCost) => a.description.localeCompare(b.description);
  sortBrandSC = (a: stockCost, b: stockCost) => a.brand.localeCompare(b.brand);
  sortCodeSC = (a: stockCost, b: stockCost) => a.code.localeCompare(b.code);
  sortMSLSC = (a: stockCost, b: stockCost) => a.msl -b.msl;
  sortSOHSC = (a: stockCost, b: stockCost) => a.soh -b.soh;
  sortQTYSC = (a: stockCost, b: stockCost) => a.qty -b.qty;
  sortS_SOHSC = (a: stockCost, b: stockCost) => a.sohSupplier -b.sohSupplier;
  sortS_PriceSC = (a: stockCost, b: stockCost) => a.price -b.price;
  sortTotalSC = (a: stockCost, b: stockCost) => (a.price*a.qty*-1) -(b.price*b.qty*-1);

  sortBrandRFC = (a: rfc_summary, b: rfc_summary) => a.logo.localeCompare(b.logo);
  sortDescRFC = (a: rfc_summary, b: rfc_summary) => a.description.localeCompare(b.description);
  sortCodeRFC = (a: rfc_summary, b: rfc_summary) => a.code.localeCompare(b.code);
  sortMSLRFC = (a: rfc_summary, b: rfc_summary) => a.msl - b.msl;
  sortSOHRFC = (a: rfc_summary, b: rfc_summary) => a.soh - b.soh;
  sortSupplierRFC = (a: rfc_summary, b: rfc_summary) => a.supplier_name.localeCompare(b.supplier_name);
  sortPriceRFC = (a: rfc_summary, b: rfc_summary) => a.price - b.price;
  sortlastsoldRFC = (a: rfc_summary, b: rfc_summary) => a.lastSold.localeCompare(b.lastSold);
  sortlastpurchaseRFC = (a: rfc_summary, b: rfc_summary) => a.lastPurchased.localeCompare(b.lastPurchased);
  sortM1RFC = (a: rfc_summary, b: rfc_summary) => a.sales[0].qty -b.sales[0].qty;
  sortM2RFC = (a: rfc_summary, b: rfc_summary) => a.sales[1].qty -b.sales[1].qty;
  sortM3RFC = (a: rfc_summary, b: rfc_summary) => a.sales[2].qty -b.sales[2].qty;
  sortM4RFC = (a: rfc_summary, b: rfc_summary) => a.sales[3].qty -b.sales[3].qty;
  sortM5RFC = (a: rfc_summary, b: rfc_summary) => a.sales[4].qty -b.sales[4].qty;
  sortM6RFC = (a: rfc_summary, b: rfc_summary) => a.sales[5].qty -b.sales[5].qty;


  sortBrandSP = (a: surplusStock, b: surplusStock) => a.logo.localeCompare(b.logo);
  sortDescSP = (a: surplusStock, b: surplusStock) => a.description.localeCompare(b.description);
  sortCodeSP = (a: surplusStock, b: surplusStock) => a.code.localeCompare(b.code);
  sortMSLSP = (a: surplusStock, b: surplusStock) => a.msl - b.msl;
  sortSOHSP = (a: surplusStock, b: surplusStock) => a.soh - b.soh;
  sortlastsoldSP = (a: surplusStock, b: surplusStock) => a.lastSold.localeCompare(b.lastSold);
  sortlastpurchaseSP = (a: surplusStock, b: surplusStock) => a.lastPurchased.localeCompare(b.lastPurchased);
  sortM1SP = (a: surplusStock, b: surplusStock) => a.sales[0].qty -b.sales[0].qty;
  sortM2SP = (a: surplusStock, b: surplusStock) => a.sales[1].qty -b.sales[1].qty;
  sortM3SP = (a: surplusStock, b: surplusStock) => a.sales[2].qty -b.sales[2].qty;
  sortM4SP = (a: surplusStock, b: surplusStock) => a.sales[3].qty -b.sales[3].qty;
  sortM5SP = (a: surplusStock, b: surplusStock) => a.sales[4].qty -b.sales[4].qty;
  sortM6SP = (a: surplusStock, b: surplusStock) => a.sales[5].qty -b.sales[5].qty;

  

  sortedKeys: number[] = []
  sortedData: any[]= []
  dict: { [key: string]: ModelStockSizes[]};

  // tempSizes: ModelStockSizes[] = []
  


  addItem(item: ModelStock)
  {
      this.inputStock = true;
      this.chosenAddition = item;
  }

  suggestedAddition: ModelStock


  submitModelStock(item: ModelStock)
  {
    let msl = (document.getElementById("new_msl") as HTMLInputElement).value;
    var mslInt: number = +msl;
    item.msl = mslInt;
    let supplier = (document.getElementById("new_supplier") as HTMLInputElement).value;
    item.supplier_name = supplier;
    if(this.chosenAddition)
      {item.supplierId = this.chosenAddition.supplierId;}
    else(item.supplierId = this.suggestedAddition.supplierId)
    

    this.StockModelsService.addModel(item).subscribe(
      res =>
      {
        this.isLoading = true;
        this.notification.showSuccess(res as string);
        this.baseBackup.push(item);
        this.baseModel = this.baseBackup;
        this.baseBackup = this.baseBackup.slice();
        this.baseModel = this.baseModel.slice();
        this.ModelTyres = this.ModelTyres.slice();
        // this.ModelStockAnalysisService.update_date(item.modelId);
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);
        this.isLoading = false;
      })
      
      this.addRow = this.stockList.indexOf(item);
      this.stockList.splice(this.addRow,1);
      this.stockList = this.stockList.slice();
      this.inputStock = false;
      this.currTable = 1;

      
  }

  itemsLoading = false;
  statColumns: boolean = false;
  toggleSales_and_searches()
  {
    if(this.statColumns == false)
    {this.statColumns = true;}
    else
    {this.statColumns = false}
  }

  generateOrderGroups(order: Order_summary){
    
    this.order_possible = true;
    this.partialFill_count = 0;
    this.queryStock = [];
    this.stockBalance = order.orderMain.slice();
    this.stockBalance_add = order.orderAdditionalItems.slice();
    this.stockBalance_disabled = order.orderDisabledItems.slice();
    this.stockBalance_holding = order.orderHoldingItems.slice();
    this.stockBalance_partials = order.orderPartialFills.slice();
    this.stockTotal = order.total;

    
    let index = this.suppliers.findIndex(x => x.supplierId == order.supplierId);
    this.minOrder = this.suppliers[index].minOrderFee;
    this.repDiscount = this.suppliers[index].repDiscount;
    if(this.suppliers[index].sell_state == 'no' || this.suppliers[index].sell_state == 'soh' || this.suppliers[index].sell_state == '-1')
    {
      this.order_possible = false;
    }
    this.similarityCheck(this.stockBalance)
   
    
  }
  viewCost = false;
  minOrder: number = 0;
  repDiscount: number = 0;
  no_discount_total: number = 0;
  partialFill_count: number = 0;
  order_possible: boolean = false;
  generateSupplierInvoice(supp: supplier_summary): void
  {
    
    this.viewCost = true;
    this.order_possible = true;
    this.partialFill_count = 0;
    this.queryStock = [];
    this.stockBalance = [];
    this.stockBalance_add = [];
    this.stockBalance_disabled = [];
    this.stockBalance_holding = [];
    this.stockTotal = 0;
    let index = this.suppliers.findIndex(x => x.supplierId == supp.supplierId);
    this.minOrder = this.suppliers[index].minOrderFee;
    this.repDiscount = this.suppliers[index].repDiscount;
    if(this.suppliers[index].sell_state == 'no' || this.suppliers[index].sell_state == 'soh' || this.suppliers[index].sell_state == '-1')
    {
      this.order_possible = false;
    }
    for(let x = 0; x < this.baseBackup.length; x++)
    {
      
      for(let z= 0; z < this.baseModel.length;z++){
        if(this.baseModel[z].modelId==this.baseBackup[x].modelId){this.baseBackup[x].holding_status = this.baseModel[z].holding_status;}
        
      }
      if(this.baseBackup[x].supplierId == supp.supplierId && supp.supplier_name.includes('_CONSUMABLES') == false && this.baseBackup[x].stock_type == "TYRE") // && this.baseBackup[x].diff < 0
      {
        this.queryStock.push(this.baseBackup[x])
        let stockLine = new stockCost;
        stockLine.description = this.baseBackup[x].description;
        stockLine.code = this.baseBackup[x].code;
        
        stockLine.msl = this.baseBackup[x].msl;
        // stockLine.soh = this.baseBackup[x].soh - this.baseBackup[x].work_in_progress;
        stockLine.soh = this.baseBackup[x].soh;
        if(stockLine.qty > 0){stockLine.qty = 0;}
        stockLine.msfid = this.baseBackup[x].msfId;
        stockLine.brand = this.baseBackup[x].brand;
        stockLine.brandLogo = this.baseBackup[x].brandLogo;
        stockLine.price = this.baseBackup[x].supplierPrice;
        stockLine.sellerId = this.baseBackup[x].supplierId;
        stockLine.sohSupplier = this.baseBackup[x].sohSupplier;
        stockLine.holding_status = this.baseBackup[x].holding_status;
        stockLine.back_order_qty = this.baseBackup[x].back_order_qty;
        stockLine.qty = this.baseBackup[x].diff - this.baseBackup[x].back_order_qty;
     
        
        
        
        if(stockLine.soh<stockLine.msl && stockLine.price >0 && (stockLine.qty*-1 <= this.baseBackup[x].sohSupplier) && this.baseBackup[x].holding_status != 2){
          this.stockBalance.push(stockLine);
          this.stockTotal += -1*(stockLine.qty * stockLine.price);
          
        }else if(this.baseBackup[x].holding_status != 2 && this.baseBackup[x].holding_status != 1){
          this.stockBalance_add.push(stockLine);
        }else if(this.baseBackup[x].holding_status == 2){
          this.stockBalance_disabled.push(stockLine);
        }else if(this.baseBackup[x].holding_status == 1){
          this.stockBalance_holding.push(stockLine);
        }
        
      }else if(this.baseBackup[x].supplierId == supp.supplierId && supp.supplier_name.includes('_CONSUMABLES') == true /*this.baseBackup[x].supplier_name.includes('_CONSUMABLES') == true*/  && this.baseBackup[x].stock_type != "TYRE") // && this.baseBackup[x].diff < 0
      {
      
       
        this.queryStock.push(this.baseBackup[x])
        let stockLine = new stockCost;
        stockLine.description = this.baseBackup[x].description;
        stockLine.code = this.baseBackup[x].code;
        stockLine.msl = this.baseBackup[x].msl;
        // stockLine.soh = this.baseBackup[x].soh - this.baseBackup[x].work_in_progress;
        stockLine.soh = this.baseBackup[x].soh;
        
        if(stockLine.qty > 0){stockLine.qty = 0;}
        stockLine.msfid = this.baseBackup[x].msfId;
        stockLine.brand = this.baseBackup[x].brand;
        stockLine.brandLogo = this.baseBackup[x].brandLogo;
        stockLine.price = this.baseBackup[x].supplierPrice;
        stockLine.sellerId = this.baseBackup[x].supplierId;
        stockLine.sohSupplier = this.baseBackup[x].sohSupplier;
        stockLine.back_order_qty = this.baseBackup[x].back_order_qty;
        stockLine.qty = this.baseBackup[x].diff - this.baseBackup[x].back_order_qty;
        

        
        
        
        if(stockLine.soh<stockLine.msl && stockLine.price >0 && (stockLine.qty*-1 <= this.baseBackup[x].sohSupplier) && this.baseBackup[x].holding_status != 2){
          
          this.stockBalance.push(stockLine);
          this.stockTotal += -1*(stockLine.qty * stockLine.price);
          
        }else if(this.baseBackup[x].holding_status != 2 && this.baseBackup[x].holding_status != 1){
          this.stockBalance_add.push(stockLine)
        }else if(this.baseBackup[x].holding_status == 2){
          this.stockBalance_disabled.push(stockLine)
        }else if(this.baseBackup[x].holding_status == 1){
          this.stockBalance_holding.push(stockLine)
        }

        
        
      }
    }

    this.no_discount_total = this.stockTotal;
    if(this.repDiscount != 0){
      var sTotal: number = +(this.stockTotal - (this.stockTotal*this.repDiscount/100)).toFixed(2);
    }
    else{
      var sTotal: number = +this.stockTotal.toFixed(2);
    }

    for(let x =0;x < this.stockBalance_add.length;x++){
      if(this.stockBalance_add[x].price*this.stockBalance_add[x].qty * -1 >0){
        this.partialFill_count += 1;
      }
    }
    for(let x =0;x < this.stockBalance_holding.length;x++){
      if(this.stockBalance_holding[x].price*this.stockBalance_holding[x].qty * -1 >0){
        this.partialFill_count += 1;
      }
    }
    let sortedCompany = this.stockBalance_add.sort((a, b) => (a.price*a.qty*-1 > b.price*b.qty*-1) ? -1 : 1);
    this.stockBalance_add = sortedCompany;
    this.sort = 0;
    this.stockBalance_add = this.stockBalance_add.slice();

    this.stockTotal = sTotal;
    this.isLoadingOrder = false;
    
    
    this.similarityCheck(this.stockBalance)

   
  }

  addToOrder(line: stockCost)
  {
    this.currRow = this.stockBalance_add.indexOf(line);
    this.stockBalance_add.splice(this.currRow,1);
    this.stockBalance_add = this.stockBalance_add.slice();
    
    this.stockBalance.push(line);
    this.stockBalance =  this.stockBalance.slice()
    this.stockTotal = 0;
    let sTotal = 0;
    
    
    for(let x = 0; x< this.stockBalance.length; x++)
    {
      this.stockTotal += (this.stockBalance[x].qty * this.stockBalance[x].price);
      sTotal += (this.stockBalance[x].qty * this.stockBalance[x].price);
    }

    this.no_discount_total = this.stockTotal;
    if(this.repDiscount != 0){
      sTotal = +(this.stockTotal - (this.stockTotal*this.repDiscount/100)).toFixed(2);
    }
    else{
       sTotal = +this.stockTotal.toFixed(2);
    }
    
    this.stockTotal = sTotal;
  }

  delFromOrder(line: stockCost)
  {
      this.currRow = this.stockBalance.indexOf(line);
      this.stockBalance.splice(this.currRow,1);
      this.stockBalance = this.stockBalance.slice();

      this.stockBalance_add.push(line);
      this.stockBalance_add =  this.stockBalance_add.slice()
      this.stockTotal = 0;
      let sTotal = 0;
      for(let x = 0; x< this.stockBalance.length; x++)
      {
        this.stockTotal += (this.stockBalance[x].qty * this.stockBalance[x].price);
        sTotal += (this.stockBalance[x].qty * this.stockBalance[x].price);
      }

      this.no_discount_total = this.stockTotal;
      if(this.repDiscount != 0){
         sTotal = +(this.stockTotal - (this.stockTotal*this.repDiscount/100)).toFixed(2);
      }
      else{
         sTotal = +this.stockTotal.toFixed(2);
      }
      this.stockTotal = sTotal;
      
      this.stockBalance = this.stockBalance.slice();
      
  }

  editL = false;
  indexMSFID = 0;
  editLine(line: stockCost)
  {
      this.editL = true;
      this.indexMSFID = line.msfid;
  }

  editID = 0
  

  saveLine(line:stockCost)
  {
    let qty = (document.getElementById("editQty") as HTMLInputElement).value;
    var intQty: number = +qty;
    if(intQty <= line.sohSupplier && intQty > 0){
      this.currRow = this.stockBalance.indexOf(line);
      this.stockBalance[this.currRow].qty = intQty;
      this.stockTotal = 0;
      let sTotal = 0;
      for(let x = 0; x< this.stockBalance.length; x++)
      {
        this.stockTotal += (this.stockBalance[x].qty * this.stockBalance[x].price);
        sTotal += (this.stockBalance[x].qty * this.stockBalance[x].price);
      }

      this.no_discount_total = this.stockTotal;
      if(this.repDiscount != 0){
        sTotal = +(this.stockTotal - (this.stockTotal*this.repDiscount/100)).toFixed(2);
      }
      else{
        sTotal = +this.stockTotal.toFixed(2);
      }
      this.stockTotal = sTotal;
      
      this.stockBalance = this.stockBalance.slice();
      this.indexMSFID = 0;
      this.editL = false;
    }else if(intQty <= 0){
      this.notification.showWarning('You can not order stock with a qty of '+intQty);
    }else{
      this.notification.showWarning('supplier only has '+line.sohSupplier+' unit(s) available');
    }
      
  }

  liveChecksDone: number[] = [];
  liveStockCheck(supplierId_limit: number){
    this.isLoading = true;
    this.StockModelsService.getLiveLevelsV2(this.ModelStockInformation,supplierId_limit).subscribe((res:any)=>{
      this.ModelStockInformation = res.collection;
      this.notification.ShowBlockingPopup(res.message);
      this.BulkOrders = this.ModelStockInformation.BulkOrders.slice();
      this.replenishSet = false;
      this.iosSet = false;
      this.holdingSet = false;
      this.disabledSet = false;
      this.allSet = false;
      this.isLoading = false;
      if(res.message.includes('Live stock retrieved')){ 
        this.liveChecksDone.push(supplierId_limit);
      }
    },err=>{
      this.isLoading = false;
      this.notification.showError('Live Stock Check Failed: ',err)
    })
  }


  liveLevelResp : any
  getLiveLevels()
  {
    
    this.isLoading = true;
    this.baseBackup = this.all_models
    this.StockModelsService.getLiveLevels(this.all_models).subscribe((res:any) =>
      {
        
        this.baseModel =  [];
        
        for(let n =0; n < res.returnModel.length;n++){
          let tempModel = new ModelStock
          tempModel.modelId = res.returnModel[n].modelId;
          tempModel.supplierId = res.returnModel[n].supplierId;
          tempModel.supplier_name = res.returnModel[n].supplier_name;
          tempModel.replenish_active = res.returnModel[n].replenish_active;
          tempModel.description = res.returnModel[n].description;
          tempModel.code = res.returnModel[n].code;
          tempModel.msfId = res.returnModel[n].msfId;
          tempModel.msl = res.returnModel[n].msl;
          tempModel.soh = res.returnModel[n].soh;
          tempModel.sohSupplier = res.returnModel[n].sohSupplier;
          // if(tempModel.sohSupplier > 20){tempModel.sohSupplier = 20;}
          tempModel.supplierPrice = res.returnModel[n].supplierPrice;
          tempModel.diff = res.returnModel[n].diff;
          tempModel.live = res.returnModel[n].live;
          tempModel.supplier_live = res.returnModel[n].supplier_live;
          tempModel.gen_code = res.returnModel[n].gen_code;
          tempModel.searches = res.returnModel[n].searches;
          tempModel.sales = res.returnModel[n].sales;
          tempModel.brand =  res.returnModel[n].brand;
          tempModel.brandLogo = res.returnModel[n].brandLogo;
          tempModel.work_in_progress = res.returnModel[n].work_in_progress;
          tempModel.stock_type = res.returnModel[n].stock_type;
          
          this.baseModel.push(tempModel);

          for(let n =0; n < this.all_models.length; n ++){
            if(this.all_models[n].modelId == tempModel.modelId){ this.all_models[n].soh = tempModel.soh; this.all_models[n].sohSupplier = tempModel.sohSupplier;
              this.all_models[n].diff = tempModel.diff; this.all_models[n].live =  tempModel.live;
              break
            }
          }

          for(let n =0; n < this.replenishableItems.length; n ++){
            if(this.replenishableItems[n].modelId == tempModel.modelId){ this.replenishableItems[n].soh = tempModel.soh; this.replenishableItems[n].sohSupplier = tempModel.sohSupplier;
              this.replenishableItems[n].diff = tempModel.diff; this.replenishableItems[n].live =  tempModel.live;
              break
            }
          }

          for(let n =0; n < this.itemsOutOfStockAtSupplier.length; n ++){
            if(this.itemsOutOfStockAtSupplier[n].modelId == tempModel.modelId){ this.itemsOutOfStockAtSupplier[n].soh = tempModel.soh; this.itemsOutOfStockAtSupplier[n].sohSupplier = tempModel.sohSupplier;
              this.itemsOutOfStockAtSupplier[n].diff = tempModel.diff; this.itemsOutOfStockAtSupplier[n].live =  tempModel.live;
              break
            }
          }

          for(let n =0; n < this.itemsHolding.length; n ++){
            if(this.itemsHolding[n].modelId == tempModel.modelId){ this.itemsHolding[n].soh = tempModel.soh; this.itemsHolding[n].sohSupplier = tempModel.sohSupplier;
              this.itemsHolding[n].diff = tempModel.diff; this.itemsHolding[n].live =  tempModel.live;
              break
            }
          }

          for(let n =0; n < this.itemsDisabled.length; n ++){
            if(this.itemsDisabled[n].modelId == tempModel.modelId){ this.itemsDisabled[n].soh = tempModel.soh; this.itemsDisabled[n].sohSupplier = tempModel.sohSupplier;
              this.itemsDisabled[n].diff = tempModel.diff; this.itemsDisabled[n].live =  tempModel.live;
              break
            }
          }

          for(let n =0; n < this.attentionHeadOffice.length; n ++){
            if(this.attentionHeadOffice[n].modelId == tempModel.modelId){ this.attentionHeadOffice[n].soh = tempModel.soh; this.attentionHeadOffice[n].sohSupplier = tempModel.sohSupplier;
              this.attentionHeadOffice[n].diff = tempModel.diff; this.attentionHeadOffice[n].live =  tempModel.live;
              break
            }
          }
        }
        
        this.notification.ShowBlockingPopup(res.returnMessage);
        if(this.baseModel.length == 0)
        {
          this.baseModel = this.baseBackup;
        }else{
          this.baseBackup = this.baseModel;
        }
        this.active_suppliers();
        this.liveUpdate = true;
        this.isLoading = false;

      },err=>{
        this.notification.handleError(err);
        this.isLoading = false;
      });

    this.baseBackup = this.baseBackup.slice();
    this.baseModel = this.baseModel.slice();
    // this.updateTime = new Date();
    // this.updateTime =this.datepipe.transform((new Date), 'MM/dd/yyyy h:mm:ss');
    
    
    
    
  }

  suppIds: number[] = [];
  suppSummary: supplier_summary[] = []
  suppNew: supplier_summary
  active_suppliers()
  {
    //this.suppliers = []
    // this.baseBackup = this.all_models;
    // this.baseModel = this.all_models;
    
   
    this.baseBackup = this.baseBackup.slice()
    this.baseModel = this.baseModel.slice()

    this.suppSummary = []
    this.suppIds = []
    // this.isLoading = true;

    for(let x=0;x <this.baseBackup.length;x++)
    {
      let suppNext = new supplier_summary;
      suppNext.supplierId = this.baseBackup[x].supplierId;
      suppNext.supplier_name = this.baseBackup[x].supplier_name;
      suppNext.order_cost = 0;
      suppNext.total_items = 0;
      suppNext.replenish_active = this.baseBackup[x].replenish_active;

      let supp_consumables = new supplier_summary;
      supp_consumables.supplierId = -1;
      supp_consumables.order_cost = 0;
      supp_consumables.total_items = 0;
      for(let j = 0; j < this.baseModel.length;j++){
        if(this.baseBackup[x].modelId == this.baseModel[j].modelId){this.baseBackup[x].holding_status = this.baseModel[j].holding_status;}
      }
      
      

      if(this.suppIds.includes(this.baseBackup[x].supplierId) == false)
      {
        for(let y = 0;y<this.baseBackup.length;y++)
        {
          if(this.baseBackup[y].diff < 0 && this.baseBackup[y].stock_type == "TYRE"  && this.baseBackup[x].supplierId == this.baseBackup[y].supplierId){
            if(this.baseBackup[y].supplierId == suppNext.supplierId && this.baseBackup[y].diff*-1 <= this.baseBackup[y].sohSupplier && this.baseBackup[y].holding_status != 2)
            {
              suppNext.order_cost += this.baseBackup[y].supplierPrice * (this.baseBackup[y].diff*-1);
              suppNext.total_items += (this.baseBackup[y].diff * -1);
            }
          }else if(this.baseBackup[y].stock_type != "TYRE" && this.baseBackup[x].supplierId == this.baseBackup[y].supplierId && this.baseBackup[y].holding_status != 2){
            
            supp_consumables.supplierId = this.baseBackup[y].supplierId;
            supp_consumables.supplier_name = this.baseBackup[y].supplier_name+"_CONSUMABLES";
            // this.baseBackup[y].supplier_name=this.baseBackup[y].supplier_name+"_CONSUMABLES";
            
            supp_consumables.replenish_active = this.baseBackup[y].replenish_active;
            if(this.baseBackup[y].diff < 0 && this.baseBackup[y].supplierId == supp_consumables.supplierId && this.baseBackup[y].diff*-1 <= this.baseBackup[y].sohSupplier && this.baseBackup[y].holding_status != 2)
            {
              supp_consumables.order_cost += this.baseBackup[y].supplierPrice * (this.baseBackup[y].diff*-1);
              supp_consumables.total_items += (this.baseBackup[y].diff * -1);
            }
          }
        }
        this.suppIds.push(this.baseBackup[x].supplierId);
        this.suppSummary.push(suppNext);
        if(supp_consumables.supplierId != -1){
          this.suppSummary.push(supp_consumables);
        }
        
        
      }else{
        continue;
      }

    }
    let sortedSummary = this.suppSummary.sort((a, b) => (a.order_cost > b.order_cost) ? -1 : 1);
    this.suppSummary = sortedSummary;
    this.suppSummary = this.suppSummary.slice();
    // this.isLoading = false;
   
  }


  altStockList: ModelStock[] = []
  altSupplierList: ModelStock[] = []
  altItem: string = ''
  altItemId: number = 0;
  trackAltStock(item: ModelStock,step:string)
  {
    this.isLoading = true;
    this.altStockList = []
    this.altSupplierList = []
    this.altItem = item.description+' ['+item.code+']';
    this.altItemId = item.modelId
    this.StockModelsService.trackAltStock(item).subscribe((res:any) =>
      {
        
        this.altStockList =  res.Alt_Stock as ModelStock[];
        this.altSupplierList = res.Alt_Supplier as ModelStock[];
        this.isLoading = false;
      }, error => {
        this.notification.showWarning("Alternative stock items can not be tracked for this item");
        this.isLoading = false;
      });
  }

  moreSuppliers()
  {
    for(let x= 0; x < this.altSupplierList.length;x++)
    {
      let itemidx  = this.altStockList.indexOf(this.altSupplierList[x])
      if(itemidx == -1){this.altStockList.push(this.altSupplierList[x]);}
    }
    this.altStockList = this.altStockList.slice();
  }

  similarity = false
  similarCount = 0
  isLoadingOrder = false
  similarityCheck(items:stockCost[])
  {

    this.isLoadingOrder = true
    this.similarCount = 0;
    this.similarity = false;
    this.isLoading = true;
    this.StockModelsService.similarityCheck(items).subscribe(res =>
      {
      
        this.stockBalance = res as stockCost[];
        for(var j=0;j < this.stockBalance.length;j++){
          if(this.stockBalance[j].similarity == true){this.similarCount+=1;}
        }
        // this.stockBalance = this.stockBalance.slice();

        let stockSweep: stockCost[] = [];

        for(let j =0;j <this.stockBalance.length;j++){
          let remove: boolean = false;
          if(this.stockBalance[j].back_order_qty > 0){
            let formerQty = this.stockBalance[j].qty;
            this.stockBalance[j].qty = this.stockBalance[j].qty - this.stockBalance[j].back_order_qty;

            if(this.stockBalance[j].qty <= 0){
              this.stockTotal -= this.stockBalance[j].price * formerQty;
              // this.stockBalance.splice(j,1);
              remove = true;
        
            }else{
              this.stockTotal -= this.stockBalance[j].price * this.stockBalance[j].qty;
              remove = true;
            }
          }
          if(remove == false){
            stockSweep.push(this.stockBalance[j]);
          }
        }
        let sortedBalance = this.size_inquiry.sort((a, b) => (a.description < b.description) ? -1 : 1);
        this.size_inquiry = sortedBalance.slice();
        // this.size_inquiry = this.size_inquiry.slice();

        this.stockBalance = stockSweep.slice();
        this.similarity = true;
        this.isLoading = false;
        this.isLoadingOrder = false;
        this.viewCost = true;
      },err=>{
          this.notification.showError(err);
          this.similarity = false;
          this.isLoading = false;
          this.isLoadingOrder = false;
      });
  }
  confirmOrder(items:stockCost[])
  {
    
    let before_discount = (this.stockTotal * 100)/(100 - this.repDiscount);
    for(let x= 0;x<items.length;x++)
    {
      items[x].price = (items[x].price - (items[x].price * this.repDiscount/100));
    }
    if(before_discount < this.minOrder)
    {
      this.notification.showWarning('Total stock cost needs to be above R'+this.minOrder.toString()+' to place automated replenishment');
    }
    else{
      this.isLoading = true;
      let zeroQty = false;
      let zeroQty_list = '['
      let zeroPrice = false;
      let zeroPrices_list = '['
      for(let x = 0; x < this.stockBalance.length;x++){
        if(this.stockBalance[x].qty == 0){
          zeroQty = true;
          zeroQty_list += this.stockBalance[x].code+',';
        }

        if(this.stockBalance[x].price == 0){
          zeroPrice = true;
          zeroPrices_list += this.stockBalance[x].code+',';
        }
      }
      zeroPrices_list += ']';
      zeroPrices_list = zeroPrices_list.replace(',]',']')
      zeroQty_list += ']';
      zeroQty_list = zeroQty_list.replace(',]',']')
      if(zeroQty == true){this.notification.showWarning("Order Can not be placed with some items having 0 as a quantity, codes are as follows: "+zeroQty_list)}
      else if(zeroPrice == true){this.notification.showWarning("Order Can not be placed with some items having 0 as a price, codes are as follows: "+zeroPrices_list)}
      else{
        let sortedItems = items.sort((a, b) => (a.description > b.description) ? -1 : 1);
        // let sortedItems = this.itemns.sort((a, b) => (a < b) ? -1 : 1);
        items = sortedItems;
        items.slice();
        this.StockModelsService.placeOrder(items).subscribe(res =>
        {
          this.notification.ShowBlockingPopup(res as string);
          if(res.toString().toUpperCase().includes("SUCCESSFULLY CREATED")){
            this.router.navigate(['/purchases']);
          }
          this.isLoading = false;
          this.viewCost = false;
        },err=>{
          this.notification.handleError(err);
          this.isLoading = false;
        });
      }
    }
   
    
  }

  Model_Suggestions: Model_Suggestions[] = []
  size_suggestions()
  {
      this.StockModelsService.size_suggestions().subscribe(res =>
      {
        this.Model_Suggestions =  res as Model_Suggestions[];
      },err=>{
        this.notification.handleError(err);
      });
  }

  model_lines: Array<any>[] = []
  viewModel_Lines = false;
  viewLines(item:string)
  {
    
    this.isLoading = true;
    this.StockModelsService.modelStockTyreSuggestions(item).subscribe((res:any) =>{
      this.ModelTyres = res.tyres as ModelStockTyres[];
      this.ModelTyres_Backup = this.ModelTyres;
      this.viewModel_Lines = true;
      this.isLoading = false;
    },err=>{

      this.notification.handleError(err);
      this.isLoading= false;
    });
  }

  partial_fill(item:stockCost)
  {
    let fill_row = this.stockBalance_partials.indexOf(item);
    item.qty = item.sohSupplier;
    this.stockTotal += item.price * item.qty;
    this.stockBalance.push(item);
    this.stockBalance = this.stockBalance.slice();
    this.stockBalance_partials.splice(fill_row,1);
    this.stockBalance_partials = this.stockBalance_partials.slice();
    this.partialFill_count = 0;
    for(let n =0;n < this.stockBalance_partials.length;n++){
      if(this.stockBalance_partials[n].price*this.stockBalance_partials[n].qty >0){
        this.partialFill_count += 1;
      }
    }
    this.notification.showSuccess(item.description+" added to Replenishable Items");
    

  }
 
  docResp: Blob
  download_doc(type:string)
  {
    this.isLoading = true;
    if(type == "MAIN"){
      if(this.currTable == 1){
        this.setTabletoContent(this.replenishableItems.length);
        setTimeout(()=> {
          let element = document.getElementById('Replenishable_Set');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          let filename = this.authService.user.client_name;
          filename = filename+'(Replenishable) Model Stock.xlsx';
          XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
          this.showR = true;
          this.isLoading= false;
        },1000)
      }
      else if(this.currTable == 2){
        this.setTabletoContent(this.itemsOutOfStockAtSupplier.length);
        setTimeout(()=> {
          let element = document.getElementById('IOS_Supplier');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          let filename = this.authService.user.client_name;
          filename = filename+'(Out of Stock) Model Stock.xlsx';
          XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
          this.showR = true;
          this.isLoading= false;
        },1000)
      }
      else if(this.currTable == 3){
        this.setTabletoContent(this.itemsHolding.length);
        setTimeout(()=> {
          let element = document.getElementById('holdingItems');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          let filename = this.authService.user.client_name;
          filename = filename+'(holding) Model Stock.xlsx';
          XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
          this.showR = true;
          this.isLoading= false;
        },1000)
      }
      else if(this.currTable == 4){
        this.setTabletoContent(this.itemsDisabled.length);
        setTimeout(()=> {
          let element = document.getElementById('DisabledItems');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          let filename = this.authService.user.client_name;
          filename = filename+'(disabled) Model Stock.xlsx';
          XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
          this.showR = true;
          this.isLoading= false;
        },1000)
      }
      // else if(this.currTable == 5){
      //   this.setTabletoContent(this.attentionHeadOffice.length);
      //   setTimeout(()=> {
      //     let element = document.getElementById('Escalation_Items');
      //     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
      //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
      //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      //     let filename = this.authService.user.client_name;
      //     filename = filename+'(Escalation) Model Stock.xlsx';
      //     XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
      //     this.showR = true;
      //     this.isLoading= false;
      //   },1000)
      // }
      else if(this.currTable == 5){
        this.setTabletoContent(this.all_models.length)
        setTimeout(()=> {
          
          let element = document.getElementById('AllItems_Set');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          let filename = this.authService.user.client_name;
          filename = filename+'(All) Model Stock.xlsx';
          XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
          this.showR = true;
          this.isLoading= false;
        },1000)
      }
    }
    if(type == "ORDER"){
      setTimeout(()=> {
        let element = document.getElementById('Main_Order');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let filename = this.authService.user.client_name;
        filename = 'StockFinder '+filename+' Model Stock Order Summary.xlsx';
        XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
        this.showR = true;
        this.isLoading= false;
      },1000)
    }
    if(type == "RFC"){
      setTimeout(()=> {
        let element = document.getElementById('RFC_ITEMS');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let filename = this.authService.user.client_name;
        filename = 'StockFinder '+filename+'_RFC Items.xlsx';
        XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
        this.showR = true;
        this.isLoading= false;
      },1000)
    }
    if(type == "surplus"){
      setTimeout(()=> {
        let element = document.getElementById('Surplus_Items');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let filename = this.authService.user.client_name;
        filename = 'StockFinder '+filename+'_Surplus Items.xlsx';
        XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
        this.showR = true;
        this.isLoading= false;
      },1000)
    }
    if(type == "nonMSL"){
      setTimeout(()=> {
        let element = document.getElementById('NonMSL_Items');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let filename = this.authService.user.client_name;
        filename = 'StockFinder '+filename+'_non MSL Items.xlsx';
        XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
        this.showR = true;
        this.isLoading= false;
      },1000)
    }
  }


  addNewStockModel(item: ModelStockTyres)
  {
    let supp_name = (document.getElementById("new_supplier") as HTMLInputElement).value;
    let msl = (document.getElementById("new_msl") as HTMLInputElement).value;

    if(msl == ''){this.notification.showWarning("Model stock needs to be populated")}

    let  new_model = new ModelStock;
    new_model.msfId = item.msfid;
    new_model.code = item.stock_code;
    new_model.supplier_name = supp_name;
    let msl_int: number = +msl;
    new_model.msl = msl_int;
    new_model.description = item.description;
    for(let x= 0; x < this.suppliersTemp.length;x++){
      if(this.suppliersTemp[x].supplier_name == new_model.supplier_name){
        new_model.supplierId = this.suppliersTemp[x].supplierId;
      }
    }
    this.StockModelsService.addModel(new_model).subscribe(
      (res:any) =>
      {
        this.isLoading = true;
        this.notification.showSuccess(res.messsage as string);
        // this.ngOnInit();
        // this.altStock = false;
        for(let n = 0; n < this.size_inquiry.length;n++){
          if(this.item_to_change.msfid == this.size_inquiry[n].msfid){
            this.size_inquiry[n].modelStock = msl_int;
            this.size_inquiry[n].best_supplier = supp_name;
            this.size_inquiry[n].listed_supplier = supp_name;
            this.size_inquiry[n].stockReplenishmentId = res.repId;
            this.size_inquiry = this.size_inquiry.slice()
            // this.item_to_change.stockReplenishmentId = res.repId;
          }
        }
        this.actionView = false;
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);
        this.isLoading = false;
      })

  }

holdItem(modelId: number)
{
  
  this.StockModelsService.holdItem(modelId).subscribe(res=>
    {
      this.isLoading_ALT = true;
      this.notification.showSuccess(res as string);
      // this.altStock = false;
      this.actionView = false;
      this.isLoading_ALT = false;
      for(let x =0; x< this.baseModel.length;x++){
        if(this.baseModel[x].modelId == modelId){
          if(this.baseModel[x].holding_status == 1){this.baseModel[x].holding_status = 0; break}
          if(this.baseModel[x].holding_status == 0){this.baseModel[x].holding_status = 1; break}
        }
      }
      if(this.currTable == 1){
        for(let n =0; n < this.replenishableItems.length; n++)
        {
          if(this.replenishableItems[n].modelId == modelId){
            this.replenishableItems[n].holding_status = 1;
            if(this.holdingSet == false){this.setHolding();}

            this.itemsHolding.push(this.replenishableItems[n]);
            this.itemsHolding = this.itemsHolding.slice();
            this.itemsHolding_backup = this.itemsHolding.slice();

            this.replenishableItems.splice(n,1);
            this.replenishableItems = this.replenishableItems.slice();
            this.replenishables_backup = this.replenishableItems.slice();
          
          }
        }
      }

      if(this.currTable == 2){
        for(let n =0; n < this.itemsOutOfStockAtSupplier.length; n++)
        {
          if(this.itemsOutOfStockAtSupplier[n].modelId == modelId){
            this.itemsOutOfStockAtSupplier[n].holding_status = 1;
            if(this.holdingSet == false){this.setHolding();}

            this.itemsHolding.push(this.itemsOutOfStockAtSupplier[n]);
            this.itemsHolding = this.itemsHolding.slice()
            this.itemsHolding_backup = this.itemsHolding.slice();

            this.itemsOutOfStockAtSupplier.splice(n,1);
            this.itemsOutOfStockAtSupplier = this.itemsOutOfStockAtSupplier.slice();
            this.IOS_backup = this.itemsOutOfStockAtSupplier.slice();
          
          }
        }
      }

      this.isLoading=false;
      this.altStock = false;
    },err=>{
      this.notification.handleError(err);
      this.altStock = false;
      this.actionView = false;
      this.isLoading_ALT = false;
    });
}

rfc_visible: boolean = false;
monthStart: number = 0;
yearStart: number =0;
monthList: number[] = [];
yearList: number[] = [];
monthR: number = 3;

slowStock_mode: string = "sale";
RFC_Backup: rfc_summary[] = []
now:any = new Date();
slowStock_ModeChange(){
  let today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  if(this.slowStock_mode == "sale"){this.slowStock_mode = "purchase";}
  else{this.slowStock_mode = "sale";}
  let tempSummarry: rfc_summary[] = []
  this.RFC_SUMMARY = this.RFC_Backup;
  this.RFC_SUMMARY = this.RFC_SUMMARY.slice();
  for(let x = 0; x < this.RFC_SUMMARY.length;x++){
    let soldD = "2000-01-01";
    let boughtD = "2000-01-01";
    if(this.RFC_SUMMARY[x].lastSold.includes("No") == false){soldD =  formatDate(new Date(this.RFC_SUMMARY[x].lastSold), 'yyyy-MM-dd', 'en');}
    if(this.RFC_SUMMARY[x].lastPurchased.includes("No") == false){boughtD =  formatDate(new Date(this.RFC_SUMMARY[x].lastPurchased), 'yyyy-MM-dd', 'en');}
    var diffS = (new Date(today).getTime()-new Date(soldD).getTime());
    let daysPast_S = diffS / (1000 * 3600 * 24);
    var diffP = (new Date(today).getTime()-new Date(boughtD).getTime());
    let daysPast_P = diffP / (1000 * 3600 * 24); 
    if(this.slowStock_mode == "purchase" && daysPast_P > (this.monthR*31)){
      let tmpItem =  new rfc_summary();
      tmpItem.description = this.RFC_SUMMARY[x].description;
      tmpItem.code =  this.RFC_SUMMARY[x].code;
      tmpItem.msfid =  this.RFC_SUMMARY[x].msfid;
      tmpItem.soh =  this.RFC_SUMMARY[x].soh;
      tmpItem.price =  this.RFC_SUMMARY[x].price;
      tmpItem.supllierId =  this.RFC_SUMMARY[x].supllierId;
      tmpItem.supplier_name =  this.RFC_SUMMARY[x].supplier_name;
      tmpItem.lastSale =  this.RFC_SUMMARY[x].lastSale;
      tmpItem.lastPurchase = this.RFC_SUMMARY[x].lastPurchase;
      tmpItem.msl =  this.RFC_SUMMARY[x].msl;
      tmpItem.classification =  this.RFC_SUMMARY[x].classification;
      tmpItem.logo =  this.RFC_SUMMARY[x].logo;
      tmpItem.lastSold =  this.RFC_SUMMARY[x].lastSold;
      tmpItem.lastPurchased =  this.RFC_SUMMARY[x].lastPurchased;
      tmpItem.sales  = this.RFC_SUMMARY[x].sales;
      tmpItem.purchases =  this.RFC_SUMMARY[x].purchases;
      tmpItem.lastSale_status = this.RFC_SUMMARY[x].lastSale_status;
      tmpItem.lastPurchase_status = this.RFC_SUMMARY[x].lastPurchase_status;
      tempSummarry.push(tmpItem);
    
    }
    else if(this.slowStock_mode == "sale" && daysPast_S > (this.monthR*31)){
      let tmpItem =  new rfc_summary();
      tmpItem.description = this.RFC_SUMMARY[x].description;
      tmpItem.code =  this.RFC_SUMMARY[x].code;
      tmpItem.msfid =  this.RFC_SUMMARY[x].msfid;
      tmpItem.soh =  this.RFC_SUMMARY[x].soh;
      tmpItem.price =  this.RFC_SUMMARY[x].price;
      tmpItem.supllierId =  this.RFC_SUMMARY[x].supllierId;
      tmpItem.supplier_name =  this.RFC_SUMMARY[x].supplier_name;
      tmpItem.lastSale =  this.RFC_SUMMARY[x].lastSale;
      tmpItem.lastPurchase = this.RFC_SUMMARY[x].lastPurchase;
      tmpItem.msl =  this.RFC_SUMMARY[x].msl;
      tmpItem.classification =  this.RFC_SUMMARY[x].classification;
      tmpItem.logo =  this.RFC_SUMMARY[x].logo;
      tmpItem.lastSold =  this.RFC_SUMMARY[x].lastSold;
      tmpItem.lastPurchased =  this.RFC_SUMMARY[x].lastPurchased;
      tmpItem.sales  = this.RFC_SUMMARY[x].sales;
      tmpItem.purchases =  this.RFC_SUMMARY[x].purchases;
      tmpItem.lastSale_status = this.RFC_SUMMARY[x].lastSale_status;
      tmpItem.lastPurchase_status = this.RFC_SUMMARY[x].lastPurchase_status;
      tempSummarry.push(tmpItem);
    
    }
   
  }

  this.RFC_SUMMARY = tempSummarry;
  this.RFC_SUMMARY = this.RFC_SUMMARY.slice();
}
RFC_RangeChange()
{
  let mr = (document.getElementById("month_entry") as HTMLInputElement).value;
  let mrInt: number = +mr;
  if(mrInt > 6){this.notification.showWarning("Month Range can not be greater than 6 months")}
  else{this.RFC_Check(mrInt); this.monthR = mrInt;}
}
rfcMonth_Range = 0;
RFC_Check(monthRange: number){
  this.rfcMonth_Range = monthRange;
  this.rfcLoading = true;
  this.nextJoke();
  this.baseBackup = this.ModelStockInformation.all_items.slice();
  if (localStorage.getItem("darkTheme") && localStorage.getItem("darkTheme") === "true"){this.logo_load = "s-logo-white.png";}
  if (localStorage.getItem("darkTheme") && localStorage.getItem("darkTheme") === "false"){this.logo_load = "fav.png";}
  this.StockModelsService.RFC_Check(this.baseBackup,monthRange).subscribe(resp=>{
    this.rfc_visible = true;
    this.RFC_SUMMARY = resp as rfc_summary[];
    this.monthStart = this.RFC_SUMMARY[0].sales[0].month
    this.yearStart = this.RFC_SUMMARY[0].sales[0].year
    this.monthList.push(this.monthStart)
    for(let n =1; n < 7;n++){
      let x = this.monthStart - n;
      let y = this.yearStart;
      if(x<0){y = y-1;}
      if(x<=0){x = x +12;}
      this.monthList.push(x);
      this.yearList.push(y);
    }
    this.RFC_Backup = this.RFC_SUMMARY;
    this.rfcLoading = false;
  },err=>{
    this.rfcLoading = false;
    this.notification.showError(err);
  })
  this.slowStock_mode = 'purchase';
  this.slowStock_ModeChange();
}

surplus_visible = false;
monthStart_MS: number = 0;
monthStart_NonMS: number = 0;
yearStart_MS:number = 0;
yearStart_NonMS:number = 0;
monthList_MS: number[] = [];
monthList_NonMS: number[] = [];
yearList_MS: number[] = [];
yearList_NonMS: number[] = [];
Surplus_Check(){
  this.rfcLoading = true;
  this.nextJoke();
  if (localStorage.getItem("darkTheme") && localStorage.getItem("darkTheme") === "true"){this.logo_load = "s-logo-white.png";}
  if (localStorage.getItem("darkTheme") && localStorage.getItem("darkTheme") === "false"){this.logo_load = "fav.png";}
  this.StockModelsService.surplusStock().subscribe((resp:any)=>{
    this.OVER_MSL = resp.moreThanModelStock as surplusStock[];
    this.NON_MSL = resp.notOnModelStock as surplusStock[];
    this.monthStart_MS = this.OVER_MSL[0].sales[0].month
    this.yearStart_MS = this.OVER_MSL[0].sales[0].year
    this.monthStart_NonMS = this.NON_MSL[0].sales[0].month
    this.yearStart_NonMS = this.NON_MSL[0].sales[0].year

    this.monthList_MS.push(this.monthStart_MS)
    for(let n =1; n < 7;n++){
      let x = this.monthStart_MS - n;
      let y = this.yearStart_MS;
      if(x<0){y = y-1;}
      if(x<=0){x = x +12;}
      this.monthList_MS.push(x);
      this.yearList_MS.push(y);
    }

    this.monthList_NonMS.push(this.monthStart_NonMS)
    for(let n =1; n < 7;n++){
      let x = this.monthStart_NonMS - n;
      let y = this.yearStart_NonMS;
      if(x<0){y = y-1;}
      if(x<=0){x = x +12;}
      this.monthList_NonMS.push(x);
      this.yearList_NonMS.push(y);
    }
    this.surplus_visible = true;
    this.rfcLoading = false;
  },err=>{
    this.notification.showError(err);
    this.rfcLoading = false;
  });
}

// update_dt(stockreplenishmentId: number){
//   this.ModelStockAnalysisService.update_date(stockreplenishmentId).subscribe(res => {
//     let dt = res as string
//   },err=>{
//     this.notification.showError(err);
//   })

// }



  //#region 6 MONTH SALES DATA

//variables
public isLoadingSalesPerMsfid = false;
public isMsfidSalesModalVisible = false;
public tyreSalesTrend: SixMonthSalesTrend;

public isLoadingSalesPerGencode = false;
public isGencodeSalesModalVisible = false;
public gencodeSalesTrend: SixMonthSalesTrend;
//Functions

viewSalesTrendTyre(msfid: number) {
  this.isLoading_ALT = true;
  this.isLoading = true;
  this.ModelStockAnalysisService.salesTrendForTyre(msfid).subscribe(
    val => {
      this.tyreSalesTrend = val;
      this.isLoadingSalesPerMsfid = false;
      this.isMsfidSalesModalVisible = true;
      this.isLoading_ALT =false;
      this.isLoading = false;
    },
    err => {
      this.notification.handleError(err);
      this.isMsfidSalesModalVisible = false;
      this.isLoading_ALT = false;
      this.isLoading = false;
    }
  )
}

viewSalesTrendGencode(gencode: string) {
  this.isGencodeSalesModalVisible = true;
  this.isLoadingSalesPerGencode = true;
  this.ModelStockAnalysisService.salesTrendForGencode(gencode).subscribe(
    val => {
      this.gencodeSalesTrend = val;
      this.isLoadingSalesPerGencode = false;
    },
    err => {
      this.notification.handleError(err);
      this.isLoadingSalesPerGencode = false;
      this.isGencodeSalesModalVisible = false;
    }
  )
  
}

getMonthName(m: number) {
  const date = new Date();
  date.setMonth(m - 1);

  return date.toLocaleString('en-US', { month: 'short' });
}

private getTrendPercentage(a: number, b: number) : string {
  if (a === 0  && b === 0) return "0 units (0%)"
  if (a === 0) return "+" + b.toString() + ' units (+100%)';
  if (b === 0) return "-" + a.toString() + ' units (-100%)';
  let sign: string = b-a > 0 ? "+" : "";
  const percent = Math.round(((b * 100 / a) - 100) * 10) / 10;
  return sign + (b-a).toString() + " units (" + sign + (percent).toString() + "%)";
}

getMonthTrend(item: SixMonthSalesTrend): string {
  return this.getTrendPercentage(item.sales[2].qty, item.sales[1].qty)
  
}

getQuarterTrend(item: SixMonthSalesTrend): string {
  return this.getTrendPercentage(
    item.sales[4].qty + item.sales[5].qty + item.sales[6].qty, 
    item.sales[1].qty + item.sales[2].qty + item.sales[3].qty)
}

getMonthRange(item: SixMonthSalesTrend) : string {
  return this.getMonthName(item.sales[1].month) + " vs " + this.getMonthName(item.sales[2].month)
}

getQuarterRange(item: SixMonthSalesTrend) : string {
  return this.getMonthName(item.sales[3].month) + " - " + this.getMonthName(item.sales[1].month) + " vs " + this.getMonthName(item.sales[6].month) + " - " + this.getMonthName(item.sales[4].month)
}

//#endregion
logo_load = "fav.png"

logoAnimate(){
  if(this.logo_load == "fav.png"){this.logo_load = "s-logo-white.png"}
  if(this.logo_load == "s-logo-white.png"){this.logo_load = "fav.png"}

}
funnyArray = ["Nothing ruins a Friday more than realizing it’s Tuesday",
  "Time flies like an arrow; fruit flies like a banana. – Anthony G",
  "The last four letters in 'queue' are not silent,\nThey're just waiting their turn",
  "My wife told me to stop impersonating a flamingo. I had to put my foot down.",
  "Most people are shocked when they find out how bad I am as an electrician.",
  "Never trust atoms; they make up everything.",
  "I feel bad for lions at zoos. How would you feel if a bunch of pizzas came to your house, took your picture, and couldn’t even eat them? ",
  "Despite the high cost of living, it remains popular.",
  "A blind man walked into a bar… and a table… and a chair…",
  "I went to a seafood disco last week, but ended up pulling a mussel.",
  "Who built King Arthur’s round table? Sir Cumference",
  "I threw a boomerang a couple years ago; I now live in constant fear.",
  "The man who survived both mustard gas and pepper spray is a seasoned veteran now.",
  "Before you marry a person, you should first make them use a computer with a slow Internet connection to see who they really are.",
  "Light travels faster than sound. This is why some people appear bright until they open their mouths.",
  "One day YouTube, Twitter, and Facebook will join together and be called: YouTwitFace",
  "The past, present, and future walked into a bar. Things got a little tense",
  "I once survived the fallout from moving an image 1 cm to the right in Word"]
waiting_caption: string = ''
lastJoke: number = 0;
jokesmentioned:number[] = []
nextJoke(){
  
  let captionIndex = Math.floor(Math.random() * (this.funnyArray.length));
  if (this.jokesmentioned.includes(captionIndex)){
    captionIndex = Math.floor(Math.random() * (this.funnyArray.length)); 
    
  }
  this.jokesmentioned.push(captionIndex);
  this.waiting_caption = this.funnyArray[captionIndex];
  
  
  
}


replenishmentId = 0;
modify_msl(repId:number){
  this.editMode = true;
  this.editId = repId;
}


//  filter disabled items  from Items out of stock
items_OSBackup: ModelStock[] = []
deepCopy_IntoBackup(){
  for(let x =0; x < this.itemsOutOfStockAtSupplier.length;x++){
    let l = new ModelStock
    l.modelId = this.itemsOutOfStockAtSupplier[x].modelId;
    l.supplierId = this.itemsOutOfStockAtSupplier[x].supplierId;
    l.supplier_name = this.itemsOutOfStockAtSupplier[x].supplier_name;
    l.replenish_active = this.itemsOutOfStockAtSupplier[x].replenish_active;
    l.description = this.itemsOutOfStockAtSupplier[x].description;
    l.code = this.itemsOutOfStockAtSupplier[x].code;
    l.msfId = this.itemsOutOfStockAtSupplier[x].msfId;
    l.brand = this.itemsOutOfStockAtSupplier[x].brand;
    l.brandLogo = this.itemsOutOfStockAtSupplier[x].brandLogo;
    l.msl = this.itemsOutOfStockAtSupplier[x].msl;
    l.soh = this.itemsOutOfStockAtSupplier[x].soh;
    l.sohSupplier = this.itemsOutOfStockAtSupplier[x].sohSupplier;
    l.supplierPrice = this.itemsOutOfStockAtSupplier[x].supplierPrice;
    l.diff = this.itemsOutOfStockAtSupplier[x].diff;
    l.work_in_progress = this.itemsOutOfStockAtSupplier[x].work_in_progress;
    l.live = this.itemsOutOfStockAtSupplier[x].live;
    l.supplier_live = this.itemsOutOfStockAtSupplier[x].supplier_live;
    l.gen_code = this.itemsOutOfStockAtSupplier[x].gen_code;
    l.searches = this.itemsOutOfStockAtSupplier[x].searches;
    l.sales = this.itemsOutOfStockAtSupplier[x].sales;
    l.stock_type = this.itemsOutOfStockAtSupplier[x].stock_type;
    l.holding_status = this.itemsOutOfStockAtSupplier[x].holding_status;
    l.sales_tier = this.itemsOutOfStockAtSupplier[x].sales_tier;
    
    this.items_OSBackup.push(l)
  

  }
  this.itemsOutOfStockAtSupplier=this.itemsOutOfStockAtSupplier.slice()
}

deepCopyIntoStandard_IOS(){

  for(let z =0; z= this.itemsOutOfStockAtSupplier.length;z++){
    this.itemsOutOfStockAtSupplier.pop()
  }
  for(let x =0; x < this.items_OSBackup.length;x++){
    let l = new ModelStock
    l.modelId = this.items_OSBackup[x].modelId;
    l.supplierId = this.items_OSBackup[x].supplierId;
    l.supplier_name = this.items_OSBackup[x].supplier_name;
    l.replenish_active = this.items_OSBackup[x].replenish_active;
    l.description = this.items_OSBackup[x].description;
    l.code = this.items_OSBackup[x].code;
    l.msfId = this.items_OSBackup[x].msfId;
    l.brand = this.items_OSBackup[x].brand;
    l.brandLogo = this.items_OSBackup[x].brandLogo;
    l.msl = this.items_OSBackup[x].msl;
    l.soh = this.items_OSBackup[x].soh;
    l.sohSupplier = this.items_OSBackup[x].sohSupplier;
    l.supplierPrice = this.items_OSBackup[x].supplierPrice;
    l.diff = this.items_OSBackup[x].diff;
    l.work_in_progress = this.items_OSBackup[x].work_in_progress;
    l.live = this.items_OSBackup[x].live;
    l.supplier_live = this.items_OSBackup[x].supplier_live;
    l.gen_code = this.items_OSBackup[x].gen_code;
    l.searches = this.items_OSBackup[x].searches;
    l.sales = this.items_OSBackup[x].sales;
    l.stock_type = this.items_OSBackup[x].stock_type;
    l.holding_status = this.items_OSBackup[x].holding_status;
    l.sales_tier = this.items_OSBackup[x].sales_tier;

    this.itemsOutOfStockAtSupplier.push(l)
  
  }
  this.itemsOutOfStockAtSupplier=this.itemsOutOfStockAtSupplier.slice()
}
excDeacItems_ios(){
  if(this.items_OSBackup.length == 0){this.deepCopy_IntoBackup()}
  for (let n = 0; n < this.itemsOutOfStockAtSupplier.length; n++){
    if(this.itemsOutOfStockAtSupplier[n].holding_status == 2){
      this.itemsOutOfStockAtSupplier.splice(n,1);
      this.itemsOutOfStockAtSupplier = this.itemsOutOfStockAtSupplier.slice()
      n =0;
    }
  }

}

//  filter holdingitems from Items out of stock
excHoldItems_ios(){
  if(this.items_OSBackup.length == 0){this.deepCopy_IntoBackup()}
  for (let n = 0; n < this.itemsOutOfStockAtSupplier.length; n++){
    if(this.itemsOutOfStockAtSupplier[n].holding_status == 1){
      this.itemsOutOfStockAtSupplier.splice(n,1);
      this.itemsOutOfStockAtSupplier = this.itemsOutOfStockAtSupplier.slice()
      n=0;
    }
  }
}

reset_IOSitems(){
  if(this.items_OSBackup.length != 0){
    this.deepCopyIntoStandard_IOS()
    this.itemsOutOfStockAtSupplier =  this.items_OSBackup.slice()
    this.items_OSBackup = []
  }
  
}


escalationVis: boolean = false;
addToEscelations(item:ModelStock){
  let idx = this.attentionHeadOffice.indexOf(item);
  this.isLoading = true;
  if(idx == -1){
    this.StockModelsService.getLastCarryDate(item).subscribe((res:any)=>{
      this.attentionHeadOffice.push(res as ModelStock)
      this.attentionHeadOffice = this.attentionHeadOffice.slice();

      let idx = -1;
      for(let j =0; j < this.itemsOutOfStockAtSupplier.length;j++){
        if(this.itemsOutOfStockAtSupplier[j].code == item.code){
          idx = j;
          break
        }
      }

      let idx2 =-1;
      for(let k =0; k < this.replenishableItems.length;k++){
        if(this.replenishableItems[k].code == item.code){
          idx2 = k;
          break
        }
      }
      // let idx = this.itemsOutOfStockAtSupplier.indexOf(item);
      // let idx2 = this.replenishableItems.indexOf(item);
      this.notification.showSuccessMinimal( item.description+' added to items for escalation');
      this.isLoading = false;

      this.itemsOutOfStockAtSupplier[idx].lastCarry_dt = res.lastCarry_dt
      this.itemsOutOfStockAtSupplier.splice(idx,1)
      this.itemsOutOfStockAtSupplier = this.itemsOutOfStockAtSupplier.slice()

    
      // this.replenishableItems[idx2].lastCarry_dt = res.lastCarry_dt
      // this.replenishableItems.splice(idx2,1)
      // this.replenishableItems = this.replenishableItems.slice()
      this.StockModelsService.setEscalationStatus(item).subscribe((res:any)=>{})
    },err =>{
      this.notification.showError('Failed to add item');
    })
  }else{
    this.notification.showInfo('Item is already grouped with escalation items');
    this.isLoading = false;
  }

 
}

removeFromEscelation(item:ModelStock){
  
  let idx = this.attentionHeadOffice.indexOf(item);
  this.attentionHeadOffice.splice(idx,1);
  this.attentionHeadOffice = this.attentionHeadOffice.slice()
  this.StockModelsService.revokeEscalationStatus(item).subscribe((res:any)=>{
    item.lastCarry_dt = ''
    this.itemsOutOfStockAtSupplier.push(item)
  },err=>{
    this.notification.showError('modification malfunction')
  })
  this.notification.showInfo(item.description+' removed from items to escalate')
  
  for(let n =0; n< this.itemsOutOfStockAtSupplier.length;n++){
    if(this.itemsOutOfStockAtSupplier[n].msfId == item.msfId){
      this.itemsOutOfStockAtSupplier[n].lastCarry_dt = ''
      break
    }
  }
}



 
}







