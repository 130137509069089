<link href="https://fonts.googleapis.com/css?family=Karla:400,700&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://cdn.materialdesignicons.com/4.8.95/css/materialdesignicons.min.css">
<main @fade class="d-flex justify-content-center align-items-center min-vh-100 py-3 py-md-0 ">
    <div class="container">
        <div nz-row nzJustify="center" nzAlign="middle">
            <div nz-col nzXs="24" nzSm="20" nzMd="16" nzLg="12" nzXl="12" nzXXl="12">
                <nz-card nzHoverable class="login-card">
                    <!-- <div class="row no-gutters"> -->
                    <!-- <div class="col-md-5">
                            <img src="assets/Images/login.jpg" alt="login" class="login-card-img">
                        </div> -->
                    <div class="card-body">
                        <div class="brand-wrapper">
                            <img src="assets/Images/dealers/sfb_logo.png" alt="logo" class="logo">
                        </div>
                        <p class="login-card-description">Welcome Back !</p>
                        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onLoginClick()">
                            <nz-spin [nzSpinning]="isLoading">
                                <div class="form-group">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-input-group nzPrefixIcon="user">
                                                <input nz-input name="username" formControlName="username" id="username" placeholder="Username / Email">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="form-group mb-4">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                                                <input nz-input name="password" formControlName="password" placeholder="***********" [type]="passwordVisible ? 'text' : 'password'">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </nz-spin>
                            <div class="form-group mb-4">
                                <button nz-button nzType="primary" name="login" id="login" type="submit" value="Login" class="login-btn">Login</button>
                            </div>
                        </form>
                        <div class="login-card-footer-nav" style="margin-top: 50px; margin-bottom: 0px; padding-bottom: 0px;">
                            <a class="forgot-password-link" (click)="forgotPasswordModalVisible = true">Forgot password?</a>
                            <a style="float:right" (click)="ContactusModalVisible = true">Contact Us</a>
                        </div>
                    </div>
                    <ng-template #suffixTemplate>
                        <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                    </ng-template>
                    <!-- </div>  -->
                </nz-card>
            </div>
        </div>
    </div>
</main>


<!--Status Update Modal-->
<nz-modal [(nzVisible)]="ContactusModalVisible" nzTitle="Contact Us" (nzOnCancel)="ContactusModalVisible=false" [nzFooter]="null" [nzClassName]="'popup-light'">
    <ng-container *nzModalContent>
        <div class="text-center">
            <div style="color: black;">
                <!-- Customer Support <span class="text-style">066 453 2826</span> <br> -->
                Customer Support: <span class="text-style">066 453 2826</span> <br>
                <hr> Leroux <span class="text-style">073 341 1633 </span> <br> Cody <span class="text-style">072 977 9360</span> / <span class="text-style">065 836 8830</span>
                <!--<br> Leroux <span class="text-style">073 341 1633</span> | Ruben <span class="text-style">083 497 2181</span>-->
            </div>
        </div>
    </ng-container>
</nz-modal>



<nz-modal [(nzVisible)]=" forgotPasswordModalVisible " nzTitle="Forgot Password" (nzOnCancel)="forgotPasswordModalVisible=false" [nzFooter]="null" [nzClassName]="'popup-light'">
    <ng-container *nzModalContent>
        <form [formGroup]="forgotPasswordForm">
            <nz-form-item>
                <nz-form-control nzErrorTip="Valid email address required">
                    <nz-input-group nzPrefixIcon="user">
                        <input nz-input placeholder="Email" name="username" formControlName="username" id="username">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <div style="text-align: center;">
                <button nz-button (click)="onForgotPasswordOkClick()" [nzLoading]="isSendingPasswordEmail">
                    <i nz-icon nzType="mail" nzTheme="outline"></i>
                    Send Password
                </button>
            </div>
            <br>
        </form>
    </ng-container>
</nz-modal>


<nz-modal [(nzVisible)]=" SelectUserResetModalVisible " nzTitle="Duplicates Emails Found" (nzOnCancel)=" SelectUserResetModalVisible =false" [nzFooter]="null" [nzClassName]="'popup-light'">
    <ng-container *nzModalContent>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h4 class="text-dark">Select User to Reset Password </h4>
                    <h6 class="text-dark">{{email}} </h6>
                </div>
            </div>
        </div>
        <div class="container bg-1">
            <div class="row">
                <div class="col-lg-12 text-left" *ngFor="let user of this.duplicateUsers">
                    <button nz-button (click)="onUserSelected(user.email,user.userId)" [nzLoading]="isSendingPasswordEmail">
                        <i nz-icon nzType="mail" nzTheme="outline"></i>
                        {{user.name}}  | <small> {{user.client}}</small><br>
                    </button>
                </div><br>
            </div>
        </div>
    </ng-container>
</nz-modal>